/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import {
  getAlgorithm,
  addAlgorithmData,
  deleteAlgorithm,
  getAlgorithmById,
  updateAlgorithm,
  getAlgorithmScript,
  addAlgorithmScript,
} from "../../service/api";
import { toast } from "react-toastify";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Button,
  Card,
  CardBody,
  Dialog,
  DialogBody,
  Spinner,
} from "@material-tailwind/react";

/* ICONS */
import { IoMdAdd, IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { LuUsers } from "react-icons/lu";
import { IoClose } from "react-icons/io5";

/* IMAGES */
import Logo from "../../assets/images/logo.webp";

/* INDEX OPTIONS */
const marketIndices = [
  { value: "BANKNIFTY", label: "BankNifty" },
  { value: "NIFTY50", label: "Nifty50" },
  { value: "GIFTNIFTY", label: "GiftNifty" },
  { value: "FINNIFTY", label: "FinNifty" },
];

const visible = [
  { value: true, label: "YES" },
  { value: false, label: "NO" },
];

const derivatives = [
  { value: "NSE", label: "NSE" },
  { value: "F&O", label: "F&O" },
  { value: "INTRADAY", label: "INTRADAY" },
];

const statusOption = [
  { value: "ON", label: "ON" },
  { value: "OFF", label: "OFF" },
];

const strikeParameter = [
  { value: "1", label: "ATM" },
  { value: "2", label: "Round off 5 and ATM" },
  { value: "3", label: "Round off 10 and ATM" },
  { value: "4", label: "Round off 50 and ATM" },
  { value: "5", label: "Round off 100 and ATM" },
  { value: "6", label: "Nearest to Premium" },
  {
    value: "7",
    label: "Greater than & Nearest to Premium",
  },
  {
    value: "8",
    label: "Less than & Nearest to Premium",
  },
  {
    value: "9",
    label: "Round off 5 & Nearest to Premium",
  },
  {
    value: "10",
    label: "Round off 5 & greater than & Nearest to Premium",
  },
  {
    value: "11",
    label: "Round off 5 & less than & Nearest to Premium",
  },
  {
    value: "12",
    label: "Round off 10 & Nearest to Premium",
  },
  {
    value: "13",
    label: "Round off 10 & greater than & Nearest to Premium",
  },
  {
    value: "14",
    label: "Round off 10 & less than & Nearest to Premium",
  },
  {
    value: "15",
    label: "Round off 50 & Nearest to Premium",
  },
  {
    value: "16",
    label: "Round off 50 & greater than & Nearest to Premium",
  },
  {
    value: "17",
    label: "Round off 50 & less than & Nearest to Premium",
  },
  {
    value: "18",
    label: "Round off 100 & Nearest to Premium",
  },
  {
    value: "19",
    label: "Round Off 100 and Greater than & Nearest to Premium",
  },
  {
    value: "20",
    label: "Round Off 100 and Less than & Nearest to Premium",
  },
];

/* DERIVATIVES BADGE */
const DerivativeBadge = (props) => {
  return (
    <div className="bg-header px-2 py-1 text-[1.2vh] rounded font-nunito-semibold text-white leading-none">
      {props.derivative}
    </div>
  );
};

/* EXPIRY */
const expiryOptions = [{ label: "Next Week", value: "Next Week" }];

const AlgoSettings = () => {
  const [addAlgorithm, setAddAlgorithm] = useState(false);
  const [editAlgorithm, setEditAlgorithm] = useState(false);
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [strategyNameSelected, setStrategyNameSelected] = useState("");

  let init = {
    enable: { value: true, label: "Enable" },
    script: { value: "BANKNIFTY", label: "BankNifty" },
    type: { value: "BUY", label: "BUY" },
    side: { value: "CALL", label: "CALL" },
    noOfLots: "",
    strikeParams: { value: "1", label: "ATM" },
  };

  const [script, setScript] = useState([init]);
  const [scriptdata, setScriptData] = useState(null);

  const [input, setInput] = useState({
    name: "",
    index: "",
    derivative: "",
    capital: "",
    stopLoss: "",
    revisedSl: "",
    trailSl: "",
    trailSlActive: "",
  });

  const [inputScript, setInputScript] = useState({
    algo: "",
    capitalRequire: "",
    isVisible: { value: true, label: "YES" },
    startTime: "",
    endTime: "",
    noOfStock: "",
    lastEntry: "",
    target: "",
    stopLoss: "",
    maximumMovement: "",
    minimumMovement: "",
    noOfLots: "",
    maxTrade: "",
    isDisable: "",
    tradeValue: "",
  });

  const [scriptInput, setScriptInput] = useState({
    enable: { value: true, label: "Enable" },
    script: { value: "BANKNIFTY", label: "BankNifty" },
    type: { value: "BUY", label: "BUY" },
    side: { value: "CALL", label: "CALL" },
    noOfLots: "",
    strikeParams: { value: "1", label: "ATM" },
  });

  const [editInput, setEditInput] = useState({
    status: "",
    name: "",
    index: "",
    derivative: "",
    capital: "",
    stopLoss: "",
    revisedSl: "",
    trailSl: "",
    trailSlActive: "",
    strategy: "",
  });

  const [id, setId] = useState();

  /* GET ALGORITHM  */
  const getAlgo = () => {
    let params = { page: 1, sizePerPage: 100 };
    getAlgorithm(params)
      .then((res) => {
        console.log(" getAlgo==>", res);
        if (res?.data?.status) {
          setData(res?.data?.data?.docs);
        }
      })
      .catch((e) => {
        console.log("e==>", e);
      });
  };

  /* GET ALGORITHM BY ID */
  const getAlgoById = (id) => {
    getAlgorithmById(id)
      .then((res) => {
        console.log(" getAlgo==>", res);
        if (res?.status) {
          let index = {
            value: res?.data?.data?.marketIndex,
            label: res?.data?.data?.marketIndex,
          };
          let derivative = {
            value: res?.data?.data?.derivatives,
            label: res?.data?.data?.derivatives,
          };
          let status = {
            value: res?.data?.data?.status === 1 ? "ON" : "OFF",
            label: res?.data?.data?.status === 1 ? "ON" : "OFF",
          };
          setEditInput({
            ...editInput,
            status: status,
            name: res?.data?.data?.name,
            index: index,
            derivative: derivative,
            capital: res?.data?.data?.minimumCapital,
            stopLoss: res?.data?.data?.stopLoss,
            revisedSl: res?.data?.data?.revisesStopLoss,
            trailSl: res?.data?.data?.trailingStopLoss,
            trailSlActive: res?.data?.data?.trailingStopLossActivation,
            strategy: res?.data?.data?.strategy,
          });
          setInputScript({
            ...inputScript,
            isDisable: disable
              ? !res?.data?.data?.isDisable
              : res?.data?.data?.isDisable,
            algo: res?.data?.data?.strategy ? res?.data?.data?.strategy : "1",
            capitalRequire: res?.data?.data?.capitalRequire
              ? res?.data?.data?.capitalRequire
              : "",
            startTime: res?.data?.data?.startTime
              ? res?.data?.data?.startTime
              : "",
            maximumMovement: res?.data?.data?.maximumMovement
              ? res?.data?.data?.maximumMovement
              : "",
            minimumMovement: res?.data?.data?.minimumMovement
              ? res?.data?.data?.minimumMovement
              : "",
            maxTrade: res?.data?.data?.maxTrade
              ? res?.data?.data?.maxTrade
              : "",
            tradeValue: res?.data?.data?.tradeValue
              ? res?.data?.data?.tradeValue
              : "",
            target: res?.data?.data?.target ? res?.data?.data?.target : "",
            stopLoss: res?.data?.data?.stopLoss
              ? res?.data?.data?.stopLoss
              : "",
            noOfStock: res?.data?.data?.noOfStokes
              ? res?.data?.data?.noOfStokes
              : "",
            noOfLots: res?.data?.data?.noOfStokes
              ? res?.data?.data?.noOfStokes
              : "",
            lastEntry: res?.data?.data?.lastEntry
              ? res?.data?.data?.lastEntry
              : "",
            endTime: res?.data?.data?.endTime ? res?.data?.data?.endTime : "",
            stockPerDay: res?.data?.data?.stockPerDay
              ? res?.data?.data?.stockPerDay
              : "",
            expiry: res?.data?.data?.expiry
              ? {
                  value: res?.data?.data?.expiry,
                  label: res?.data?.data?.expiry,
                }
              : { value: "NextWeek", label: "NextWeek" },
            isVisible: res?.data?.data?.isVisible
              ? {
                  value: res?.data?.data?.isVisible,
                  label: res?.data?.data?.isVisible === true ? "YES" : "NO",
                }
              : { value: true, label: "YES" },
          });
        }
      })
      .catch((e) => {
        console.log("e==>", e);
      });
  };

  /* VALIDATION ALGORITHM  */
  const validateInput = () => {
    for (const key in input) {
      if (!input[key]) {
        return `${key} is required`;
      }
    }
    return null;
  };

  // GET SCRIPT
  const getScriptData = (strategy) => {
    getAlgorithmScript({ algo: strategy }).then((res) => {
      console.log("res: ", res);
      if (res?.data?.data?.length > 0) {
        let arr = [];
        res?.data?.data?.map((obj) => {
          arr.push({
            ...obj,
            enable:
              obj.enable === true
                ? { value: true, label: "Enable" }
                : { value: false, label: "Disable" },
            type: obj.type
              ? { value: obj.type, label: obj.type }
              : { value: "BUY", label: "BUY" },
            side: obj.side
              ? { value: obj.side, label: obj.side }
              : { value: "BUY", label: "BUY" },
            script: obj?.script
              ? marketIndices?.filter(
                  (market) => market.value === obj?.script
                )[0]
              : { value: "BANKNIFTY", label: "BankNifty" },
          });
        });
        setScript(arr);
        setScriptData(res?.data?.data);
      }
    });
  };

  /* ADD ALGORITHM  */
  const addAlgo = () => {
    const error = validateInput();

    if (error) {
      console.log(`Error: ${error}`);
      return toast.error(error);
    }

    let body = {
      name: input?.name,
      marketIndex: input?.index?.value,
      derivatives: input?.derivative?.value,
      minimumCapital: input?.capital,
      stopLoss: input?.stopLoss,
      revisesStopLoss: input?.revisedSl,
      trailingStopLoss: input?.trailSl,
      trailingStopLossActivation: input?.trailSlActive,
      strategy: Number(input?.strategy),
    };
    setLoading(true);
    addAlgorithmData(body)
      .then((res) => {
        console.log(" getAlgo==>", res);
        if (res.status) {
          setLoading(false);
          toast.success(res.message);
          setAddAlgorithm(false);
          getAlgo();
          setInput({
            name: "",
            index: "",
            derivative: "",
            capital: "",
            stopLoss: "",
            revisedSl: "",
            trailSl: "",
            trailSlActive: "",
            strategy: "",
          });
        } else {
          setLoading(false);
          toast.error(res.message);
        }
      })
      .catch((e) => {
        console.log("e==>", e);
        toast.error(e.response.data.message);
        setLoading(false);
      });
  };

  /* EDIT ALGORITHM  */
  const editAlgo = () => {
    let body = {
      capitalRequire: inputScript?.capitalRequire,
      isVisible: inputScript?.isVisible?.value,
      startTime: inputScript?.startTime,
      endTime: inputScript?.endTime,
      maximumMovement: inputScript?.maximumMovement,
      minimumMovement: inputScript?.minimumMovement,
      stopLoss: inputScript?.stopLoss,
      target: inputScript?.target,
      isDisable: inputScript?.isDisable,
    };
    if (strategyNameSelected !== "PCR Strategy") {
      body.lastEntry = inputScript?.lastEntry;
      body.noOfStokes = Number(inputScript?.noOfStock);
    } else {
      body.maxTrade = inputScript?.maxTrade;
      body.noOfStokes = inputScript?.noOfLots;
    }
    if (strategyNameSelected === "Intraday Stock Buy") {
      body.tradeValue = inputScript?.tradeValue;
    }
    setLoading(true);
    updateAlgorithm(id, body)
      .then((res) => {
        console.log(" getAlgo==>", res);
        if (res.status) {
          setLoading(false);
          toast.success(res.message);
          setEditAlgorithm(false);
          getAlgo();
          setId();
          setDisable(false);
          setEditInput({
            status: "",
            name: "",
            index: "",
            derivative: "",
            capital: "",
            stopLoss: "",
            revisedSl: "",
            trailSl: "",
            trailSlActive: "",
          });
          setInputScript({
            algo: "",
            capitalRequire: "",
            isVisible: { value: true, label: "YES" },
            startTime: "",
            endTime: "",
            noOfStock: "",
            lastEntry: "",
            target: "",
            stopLoss: "",
            maximumMovement: "",
            minimumMovement: "",
            noOfLots: "",
            maxTrade: "",
            tradeValue: "",
            isDisable: "",
            // stockPerDay: "",
            // expiry: { value: "NextWeek", label: "NextWeek" },
          });
        } else {
          setLoading(false);
          toast.error(res.message);
        }
      })
      .catch((e) => {
        console.log("e==>", e);
        toast.error(e.response.data.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAlgo();
  }, []);

  useEffect(() => {
    if (id) {
      getAlgoById(id);
    }
  }, [id]);

  /* ALGORITHM TILE */
  const AlgorithmTile = (props) => {
    return (
      <Card className="bg-white border border-cardBorder rounded-md mt-5 first:mt-0">
        <CardBody className="p-5">
          <div className="grid grid-cols-1 xl:grid-cols-6 lg:grid-cols-6 items-center">
            <div className="col-span-2">
              <div className="flex items-center gap-5">
                <div>
                  <h5 className="text-xl font-nunito-bold text-textBlack tracking-tight capitalize">
                    {props.name}
                  </h5>
                  <div className="flex items-center gap-1 mt-1">
                    {props.derivatives.map((derivative) => (
                      <DerivativeBadge derivative={derivative.name} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-1">
              {props.data.isDisable ? (
                <div className="bg-orange-500 bg-opacity-10 rounded-md px-4 py-1 w-fit">
                  <p className="text-orange-500 font-nunito-semibold text-sm">
                    Paused
                  </p>
                </div>
              ) : (
                <div className="bg-green-500 bg-opacity-10 rounded-md px-4 py-1 w-fit">
                  <p className="text-green-500 font-nunito-semibold text-sm">
                    Active
                  </p>
                </div>
              )}

              <div className="bg-red-500 bg-opacity-10 rounded-md px-4 py-1 w-fit hidden">
                <p className="text-red-500 font-nunito-semibold text-sm">
                  Closed
                </p>
              </div>
            </div>
            <div className="col-span-1 mt-5 xl:mt-0 lg:mt-0 flex items-center justify-center">
              <div>
                <h5 className="text-sm font-nunito-regular text-greyText tracking-tight">
                  Min. capital required
                </h5>
                <p className="text-lg font-nunito-bold tracking-tight text-black mt-1">
                  ₹ {props.minCapital}
                </p>
              </div>
            </div>
            <div className="col-span-2 mt-5 xl:mt-0 lg:mt-0">
              <div className="flex items-center justify-end gap-2">
                <Button
                  className="bg-white hover:bg-black hover:bg-opacity-5 border border-cardBorder gap-2 shadow-none hover:shadow-none text-textBlack text-sm font-nunito-medium tracking-tight normal-case px-4 py-2 font-medium transition-all duration-300 ease-in-out rounded flex items-center justify-center"
                  onClick={() => {
                    setScript([init]);
                    getScriptData(props?.data?.strategy);
                    setEditAlgorithm(!editAlgorithm);
                    setId(props?.data?._id);
                    setStrategyNameSelected(props.name);
                  }}
                >
                  Edit details
                </Button>
                {props.data.isDisable ? (
                  <Button
                    className="shadow-none hover:shadow-none border border-green-500 border-opacity-20 bg-green-500 bg-opacity-10 text-green-500 normal-case text-sm font-nunito-semibold tracking-tight hover:bg-green-50 px-4 py-2 font-medium transition-all duration-300 ease-in-out rounded"
                    onClick={() => {
                      setId(props?.data?._id);
                      setStrategyNameSelected(props.name);
                      setDisable(!disable);
                    }}
                  >
                    Enable
                  </Button>
                ) : (
                  <Button
                    className="shadow-none hover:shadow-none border border-red-500 border-opacity-20 bg-red-500 bg-opacity-10 text-red-500 normal-case text-sm font-nunito-semibold tracking-tight hover:bg-red-50 px-4 py-2 font-medium transition-all duration-300 ease-in-out rounded"
                    onClick={() => {
                      setId(props?.data?._id);
                      setStrategyNameSelected(props.name);
                      setDisable(!disable);
                    }}
                  >
                    Disable
                  </Button>
                )}
                <Button className="bg-white hover:bg-black hover:bg-opacity-5 border border-cardBorder gap-2 shadow-none hover:shadow-none text-textBlack text-sm font-nunito-medium tracking-tight normal-case px-4 py-2 font-medium transition-all duration-300 ease-in-out rounded flex items-center justify-center">
                  Close
                </Button>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  };

  /* STRATEGY OPTIONS */
  const [pcrStrategy, setPCRStrategy] = useState(false);
  const [intradayOptionSell, setIntradayOptionSell] = useState(false);
  const [intradayOptionBuy, setIntradayOptionBuy] = useState(false);
  const [intradayStockBuy, setIntradayStockBuy] = useState(false);

  return (
    <section className="py-12">
      <div className="container">
        {/* GREETING */}
        <div className="block xl:flex lg:flex items-center justify-between">
          <div>
            <h1 className="text-3xl font-nunito-bold text-textBlack tracking-tight">
              Algorithm Management
            </h1>
            <p className="text-base font-nunito-regular tracking-tight text-greyText">
              Edit and manage the algorithms operating on your platform.
            </p>
          </div>
        </div>

        {/* ALGORITHMS LIST */}
        <div className="pt-12">
          {data &&
            data?.length !== 0 &&
            data?.map((obj) => {
              return (
                <AlgorithmTile
                  name={obj?.name}
                  activeUsers="30"
                  stockName={obj?.marketIndex}
                  minCapital={obj?.minimumCapital}
                  derivatives={[{ name: obj?.derivatives }]}
                  data={obj}
                  key={obj?._id}
                />
              );
            })}
        </div>
      </div>

      {/* ADD ALGORITHM DIALOG */}
      <Dialog
        open={addAlgorithm}
        handler={() => setAddAlgorithm(!addAlgorithm)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="md"
      >
        <DialogBody className="p-0">
          <div className="flex items-center justify-between px-5 py-3 border-b border-cardBorder">
            <h5 className="text-textBlack font-nunito-bold text-lg tracking-tight">
              Add details
            </h5>
            <Button
              className="p-2 rounded-full shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5 bg-transparent"
              onClick={() => setAddAlgorithm(!addAlgorithm)}
            >
              <IoClose className="w-5 h-5 text-greyText" />
            </Button>
          </div>
          <div className="p-5">
            <form>
              <div className="grid grid-cols-2 gap-5">
                <div className="col-span-1">
                  <div className="form-group">
                    <label
                      htmlFor="algoImage"
                      className="text-sm font-nunito-medium tracking-tight"
                    >
                      Logo
                    </label>
                    <div className="flex items-center gap-5 mt-1">
                      <div className="w-14 h-14 rounded-full bg-header bg-opacity-5 flex items-center justify-center flex-shrink-0">
                        <img
                          src={Logo}
                          className="w-9 h-9 object-cover rounded-full"
                          alt="Algoquant"
                        />
                      </div>
                      <input
                        type="file"
                        name="algoImage"
                        id="algoImage"
                        placeholder="Select image"
                        className="w-full border border-cardBorder rounded-md text-sm tracking-tight font-nunito-regular p-2"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group mt-5">
                <label
                  htmlFor="algoName"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  Algorithm Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="algoName"
                  id="algoName"
                  className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="Eg: Trendmaster Pro, Tactical TurnTracker"
                  value={input?.name}
                  onChange={(e) => setInput({ ...input, name: e.target.value })}
                />
              </div>
              <div className="grid grid-cols-2 gap-5 mt-5">
                <div className="col-span-1">
                  <div className="form-group">
                    <label
                      htmlFor="stockIndex"
                      className="text-sm font-nunito-medium tracking-tight"
                    >
                      Market Index <span className="text-red-500">*</span>
                    </label>
                    <Select
                      options={marketIndices}
                      className="react-select"
                      classNamePrefix="dropdown"
                      placeholder="Market index"
                      value={input?.index}
                      onChange={(e) => setInput({ ...input, index: e })}
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group">
                    <label
                      htmlFor="stockIndex"
                      className="text-sm font-nunito-medium tracking-tight"
                    >
                      Derivatives <span className="text-red-500">*</span>
                    </label>
                    <Select
                      options={derivatives}
                      className="react-select"
                      classNamePrefix="dropdown"
                      placeholder="Derivatives"
                      // isMulti
                      value={input?.derivative}
                      onChange={(e) => setInput({ ...input, derivative: e })}
                    />
                  </div>
                </div>
              </div>
              <div className="form-group mt-5">
                <label
                  htmlFor="minCapital"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  Minimum Capital required{" "}
                  <span className="text-red-500">*</span>
                </label>
                <input
                  type="number"
                  name="minCapital"
                  id="minCapital"
                  className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="₹ 50,000"
                  value={input?.capital}
                  onChange={(e) =>
                    setInput({ ...input, capital: e.target.value })
                  }
                />
              </div>
              <div className="grid grid-cols-2 gap-5 mt-5">
                <div className="col-span-1">
                  <div className="form-group">
                    <label
                      htmlFor="stopLoss"
                      className="text-sm font-nunito-medium tracking-tight"
                    >
                      Stop Loss <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="number"
                      name="stopLoss"
                      id="stopLoss"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="Stop loss"
                      value={input?.stopLoss}
                      onChange={(e) =>
                        setInput({ ...input, stopLoss: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group">
                    <label
                      htmlFor="revisedStopLoss"
                      className="text-sm font-nunito-medium tracking-tight"
                    >
                      Revised Stop Loss <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="number"
                      name="revisedStopLoss"
                      id="revisedStopLoss"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="Revised stop loss"
                      value={input?.revisedSl}
                      onChange={(e) =>
                        setInput({ ...input, revisedSl: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-5 mt-5">
                <div className="col-span-1">
                  <div className="form-group">
                    <label
                      htmlFor="trailingStopLoss"
                      className="text-sm font-nunito-medium tracking-tight"
                    >
                      Trailing Stop Loss <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="number"
                      name="trailingStopLoss"
                      id="trailingStopLoss"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="Trailing Stop loss"
                      value={input?.trailSl}
                      onChange={(e) =>
                        setInput({ ...input, trailSl: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group">
                    <label
                      htmlFor="stopLossActivation"
                      className="text-sm font-nunito-medium tracking-tight"
                    >
                      Trailing Stop Loss Activation{" "}
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="number"
                      name="stopLossActivation"
                      id="stopLossActivation"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="Stop loss activation"
                      value={input?.trailSlActive}
                      onChange={(e) =>
                        setInput({ ...input, trailSlActive: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group">
                    <label
                      htmlFor="stopLossActivation"
                      className="text-sm font-nunito-medium tracking-tight"
                    >
                      Strategy <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="number"
                      name="stopLossActivation"
                      id="stopLossActivation"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="1"
                      value={input?.strategy}
                      onChange={(e) =>
                        setInput({ ...input, strategy: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="border-t border-cardBorder px-5 py-3 flex items-center justify-end">
            <Button
              onClick={addAlgo}
              disabled={loading}
              className="bg-header hover:bg-opacity-80 border border-header flex items-center gap-2 shadow-none hover:shadow-none text-white text-sm font-nunito-medium tracking-tight normal-case px-4 py-2 font-medium transition-all duration-300 ease-in-out rounded"
            >
              {loading ? (
                <div className="flex center justify-center">
                  <Spinner className="h-full " />
                </div>
              ) : (
                "  Save details"
              )}
            </Button>
          </div>
        </DialogBody>
      </Dialog>

      {/* EDIT ALGORITHM TILE */}
      <Dialog
        open={editAlgorithm}
        handler={() => setEditAlgorithm(!editAlgorithm)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="lg"
      >
        <DialogBody className="p-0">
          <div className="flex items-center justify-between px-5 py-3 border-b border-cardBorder">
            <h5 className="text-textBlack font-nunito-bold text-lg tracking-tight">
              {strategyNameSelected}
            </h5>
            <Button
              className="p-2 rounded-full shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5 bg-transparent"
              onClick={() => {
                setEditAlgorithm(!editAlgorithm);
              }}
            >
              <IoClose className="w-5 h-5 text-greyText" />
            </Button>
          </div>
          <div className="p-5">
            <form>
              {/* INTRADAY OPTION BUY */}
              <div>
                <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5">
                  <div className="form-group">
                    <label
                      htmlFor="minCapital"
                      className="text-sm font-nunito-medium tracking-tight"
                    >
                      Capital required
                    </label>
                    <input
                      type="number"
                      name="minCapital"
                      id="minCapital"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="₹ 50,000"
                      value={inputScript?.capitalRequire}
                      onChange={(e) =>
                        setInputScript({
                          ...inputScript,
                          capitalRequire: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="status"
                      className="text-sm font-nunito-medium tracking-tight"
                    >
                      Visible on user panel
                    </label>
                    <Select
                      options={visible}
                      value={inputScript?.isVisible}
                      onChange={(e) =>
                        setInputScript({ ...inputScript, isVisible: e })
                      }
                      className="react-select mt-1"
                      classNamePrefix="dropdown"
                      placeholder="Visible on user panel"
                    />
                  </div>
                  <div
                    className={`form-group ${
                      strategyNameSelected === "PCR Strategy"
                        ? "hidden"
                        : "block"
                    }`}
                  >
                    <label
                      htmlFor="stocks"
                      className="text-sm font-nunito-medium tracking-tight"
                    >
                      No of Stocks
                    </label>
                    <input
                      type="number"
                      name="stocks"
                      id="stocks"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="No. of stocks"
                      value={inputScript?.noOfStock}
                      onChange={(e) =>
                        setInputScript({
                          ...inputScript,
                          noOfStock: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="startTime"
                      className="text-sm font-nunito-medium tracking-tight"
                    >
                      Start time
                    </label>
                    <input
                      type="time"
                      name="startTime"
                      id="startTime"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="Start Time"
                      value={inputScript?.startTime}
                      onChange={(e) =>
                        setInputScript({
                          ...inputScript,
                          startTime: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label
                      htmlFor="endTime"
                      className="text-sm font-nunito-medium tracking-tight"
                    >
                      End time
                    </label>
                    <input
                      type="time"
                      name="endTime"
                      id="endTime"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="End Time"
                      value={inputScript?.endTime}
                      onChange={(e) =>
                        setInputScript({
                          ...inputScript,
                          endTime: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div
                    className={`form-group ${
                      strategyNameSelected === "PCR Strategy"
                        ? "hidden"
                        : "block"
                    }`}
                  >
                    <label
                      htmlFor="lastEntryTime"
                      className="text-sm font-nunito-medium tracking-tight"
                    >
                      Last Entry Time
                    </label>
                    <input
                      type="time"
                      name="lastEntryTime"
                      id="lastEntryTime"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="Last Entry Time"
                      value={inputScript?.lastEntry}
                      onChange={(e) =>
                        setInputScript({
                          ...inputScript,
                          lastEntry: e.target.value,
                        })
                      }
                    />
                  </div>

                  {/* ONLY SHOW THS WHEN STRATEGY IS PCR STRATEGY */}
                  {strategyNameSelected === "PCR Strategy" && (
                    <div className="form-group">
                      <label
                        htmlFor="lots"
                        className="text-sm font-nunito-medium tracking-tight"
                      >
                        No of Lots
                      </label>
                      <input
                        type="number"
                        name="lots"
                        id="lots"
                        className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                        placeholder="No. of lots"
                        value={inputScript?.noOfLots}
                        onChange={(e) =>
                          setInputScript({
                            ...inputScript,
                            noOfLots: e.target.value,
                          })
                        }
                      />
                    </div>
                  )}

                  <div
                    className={`form-group ${
                      strategyNameSelected === "PCR Strategy"
                        ? "hidden"
                        : "block"
                    }`}
                  >
                    <label
                      htmlFor="targetPercentage"
                      className="text-sm font-nunito-medium tracking-tight"
                    >
                      Target %
                    </label>
                    <input
                      type="number"
                      name="targetPercentage"
                      id="targetPercentage"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="Target Percentage"
                      value={inputScript?.target}
                      onChange={(e) =>
                        setInputScript({
                          ...inputScript,
                          target: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div
                    className={`form-group ${
                      strategyNameSelected === "PCR Strategy"
                        ? "hidden"
                        : "block"
                    }`}
                  >
                    <label
                      htmlFor="slPercentage"
                      className="text-sm font-nunito-medium tracking-tight"
                    >
                      Stop Loss %
                    </label>
                    <input
                      type="number"
                      name="slPercentage"
                      id="slPercentage"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="Stop Loss Percentage"
                      value={inputScript?.stopLoss}
                      onChange={(e) =>
                        setInputScript({
                          ...inputScript,
                          stopLoss: e.target.value,
                        })
                      }
                    />
                  </div>

                  {/* FOR INTRADAY STOCK BUY (ALL THE ABOVE FIELD PLUS THIS ONE) */}
                  {strategyNameSelected === "Intraday Stock Buy" && (
                    <div className="form-group">
                      <label
                        htmlFor="tradeValue"
                        className="text-sm font-nunito-medium tracking-tight"
                      >
                        Trade value
                      </label>
                      <input
                        type="number"
                        name="tradeValue"
                        id="tradeValue"
                        className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                        placeholder="₹ 50,000"
                        value={inputScript?.tradeValue}
                        onChange={(e) =>
                          setInputScript({
                            ...inputScript,
                            tradeValue: e.target.value,
                          })
                        }
                      />
                    </div>
                  )}

                  <div
                    className={`form-group ${
                      strategyNameSelected === "PCR Strategy"
                        ? "hidden"
                        : "block"
                    }`}
                  >
                    <label
                      htmlFor="maxMovement"
                      className="text-sm font-nunito-medium tracking-tight"
                    >
                      Maximum Movement %
                    </label>
                    <input
                      type="number"
                      name="maxMovement"
                      id="maxMovement"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="Maximum Movement"
                      value={inputScript?.maximumMovement}
                      onChange={(e) =>
                        setInputScript({
                          ...inputScript,
                          maximumMovement: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div
                    className={`form-group ${
                      strategyNameSelected === "PCR Strategy"
                        ? "hidden"
                        : "block"
                    }`}
                  >
                    <label
                      htmlFor="minMovement"
                      className="text-sm font-nunito-medium tracking-tight"
                    >
                      Minimum Movement %
                    </label>
                    <input
                      type="number"
                      name="minMovement"
                      id="minMovement"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="Minimum Movement"
                      value={inputScript?.minimumMovement}
                      onChange={(e) =>
                        setInputScript({
                          ...inputScript,
                          minimumMovement: e.target.value,
                        })
                      }
                    />
                  </div>

                  {strategyNameSelected === "PCR Strategy" && (
                    <div className="form-group">
                      <label
                        htmlFor="entryPerStock"
                        className="text-sm font-nunito-medium tracking-tight"
                      >
                        Max Entries per stock
                      </label>
                      <input
                        type="number"
                        name="entryPerStock"
                        id="entryPerStock"
                        className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                        placeholder="Max entries per stock"
                        value={inputScript?.maxTrade}
                        onChange={(e) =>
                          setInputScript({
                            ...inputScript,
                            maxTrade: e.target.value,
                          })
                        }
                      />
                    </div>
                  )}
                </div>
              </div>

              {/* HIDE THIS SECTION FOR INTRADAY STOCK BUY */}
              <div className="hidden">
                <div className="flex items-center justify-between">
                  <h5 className="text-lg font-nunito-bold text-black tracking-tight">
                    Scripts for this strategy
                  </h5>
                </div>

                {/* SCRIPT ACCORDION */}
                <div className="w-full bg-[#F5F5F5] bg-opacity-50 border border-[#E5E5E5] rounded-md px-5 py-3 mt-3">
                  <div className="grid grid-cols-4 gap-5 items-center">
                    <div className="col-span-1">
                      <h5 className="text-base font-nunito-semibold text-black">
                        Script 1
                      </h5>
                    </div>
                    <div className="col-span-1">
                      <div className="form-group">
                        <Select
                          options={[
                            { value: true, label: "Enable" },
                            { value: false, label: "Disable" },
                          ]}
                          className="react-select"
                          classNamePrefix="dropdown"
                          placeholder="Script status"
                          value={scriptInput?.enable}
                          onChange={(e) =>
                            setScriptInput({
                              ...scriptInput,
                              enable: e,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div className="form-group w-full">
                        <input
                          type="number"
                          name="lots"
                          id="lots"
                          defaultValue="1"
                          className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="No. of lots"
                          value={scriptInput?.noOfLots}
                          onChange={(e) =>
                            setScriptInput({
                              ...scriptInput,
                              noOfLots: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div className="form-group w-full">
                        <Select
                          options={strikeParameter}
                          className="react-select mt-1"
                          classNamePrefix="dropdown"
                          placeholder="Strike Selection"
                          value={scriptInput?.strikeParams}
                          onChange={(e) =>
                            setScriptInput({
                              ...scriptInput,
                              strikeParams: e,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* {script &&
                  script?.length > 0 &&
                  script.map((obj, i) => {
                    return (
                      <>
                        <Accordion
                          open={scriptAccordion === i}
                          icon={
                            scriptAccordion === i ? (
                              <IoIosArrowUp className="w-4 h-4 text-black" />
                            ) : (
                              <IoIosArrowDown className="w-4 h-4 text-black" />
                            )
                          }
                          className="mt-3 border border-[#E5E5E5] rounded-lg script-accordion"
                        >
                          <AccordionHeader
                            className={`text-sm font-nunito-semibold text-black bg-[#F5F5F5] bg-opacity-50 border-[#E5E5E5] px-5 py-[1vh] ${
                              scriptAccordion === i
                                ? "rounded-t-lg border-b"
                                : "rounded-lg border-b-0"
                            }`}
                            onClick={() => {
                              if (scriptAccordion === i) {
                                script[scriptAccordion] = {
                                  ...script[scriptAccordion],
                                  ...scriptInput,
                                };
                               
                                setScript(script);
                                console.log("obj: ", obj);
                                setScriptInput(init);
                                setScript(script);
                              } else {
                                
                              }
                              if (scriptAccordion !== i) {
                                setScriptInput(obj);
                              }
                              if (
                                scriptAccordion >= 0 &&
                                scriptAccordion !== i
                              ) {
                                script[scriptAccordion] = {
                                  ...script[scriptAccordion],
                                  ...scriptInput,
                                };
                                setScript(script);
                              }
                              setScriptAccordion(
                                scriptAccordion === i ? -1 : i
                              );
                            }}
                          >
                            Script {i + 1}
                          </AccordionHeader>
                          <AccordionBody
                            className={`bg-[#F5F5F5] bg-opacity-50 p-5 ${
                              scriptAccordion !== i && "invisible"
                            }`}
                          >
                            <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5">
                              <div className="col-span-1">
                                <div className="form-group">
                                  <label
                                    htmlFor="status"
                                    className="text-sm font-nunito-medium tracking-tight"
                                  >
                                    Enable/Disable
                                  </label>
                                  <Select
                                    options={[
                                      { value: true, label: "Enable" },
                                      { value: false, label: "Disable" },
                                    ]}
                                    className="react-select mt-1"
                                    classNamePrefix="dropdown"
                                    placeholder="Script status"
                                    value={scriptInput?.enable}
                                    onChange={(e) =>
                                      setScriptInput({
                                        ...scriptInput,
                                        enable: e,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-span-1">
                                <div className="form-group">
                                  <label
                                    htmlFor="status"
                                    className="text-sm font-nunito-medium tracking-tight"
                                  >
                                    Type
                                  </label>
                                  <Select
                                    options={[
                                      { value: "BUY", label: "BUY" },

                                      { value: "SELL", label: "SELL" },
                                    ]}
                                    className="react-select mt-1"
                                    classNamePrefix="dropdown"
                                    placeholder="Script status"
                                    value={scriptInput?.type}
                                    onChange={(e) =>
                                      setScriptInput({
                                        ...scriptInput,
                                        type: e,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-span-1">
                                <div className="form-group">
                                  <label
                                    htmlFor="status"
                                    className="text-sm font-nunito-medium tracking-tight"
                                  >
                                    Side
                                  </label>
                                  <Select
                                    options={[
                                      { value: "CALL", label: "CALL" },
                                      { value: "PUT", label: "PUT" },
                                    ]}
                                    className="react-select mt-1"
                                    classNamePrefix="dropdown"
                                    placeholder="Script status"
                                    value={scriptInput?.side}
                                    onChange={(e) =>
                                      setScriptInput({
                                        ...scriptInput,
                                        side: e,
                                      })
                                    }
                                  />
                                </div>
                              </div>

                            </div>
                            <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5 mt-5">
                              
                              <div className="col-span-1">
                                <div className="form-group">
                                  <label
                                    htmlFor="script"
                                    className="text-sm font-nunito-medium tracking-tight"
                                  >
                                    Script
                                  </label>
                                  <Select
                                    options={marketIndices}
                                    className="react-select mt-1"
                                    classNamePrefix="dropdown"
                                    placeholder="Script"
                                    value={scriptInput?.script}
                                    onChange={(e) =>
                                      setScriptInput({
                                        ...scriptInput,
                                        script: e,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-span-1">
                                <div className="form-group">
                                  <label
                                    htmlFor="lots"
                                    className="text-sm font-nunito-medium tracking-tight"
                                  >
                                    No. of lots (Testing account)
                                  </label>
                                  <input
                                    type="number"
                                    name="lots"
                                    id="lots"
                                    defaultValue="1"
                                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                                    placeholder="1"
                                    value={scriptInput?.noOfLots}
                                    onChange={(e) =>
                                      setScriptInput({
                                        ...scriptInput,
                                        noOfLots: e.target.value,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-span-1">
                                <div className="form-group">
                                  <label
                                    htmlFor="strikeSelection"
                                    className="text-sm font-nunito-medium tracking-tight"
                                  >
                                    Strike Method
                                  </label>
                                  <Select
                                    options={strikeParameter}
                                    className="react-select mt-1"
                                    classNamePrefix="dropdown"
                                    placeholder="Strike Selection"
                                    value={scriptInput?.strikeParams}
                                    onChange={(e) =>
                                      setScriptInput({
                                        ...scriptInput,
                                        strikeParams: e,
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </AccordionBody>
                        </Accordion>
                      </>
                    );
                  })} */}
              </div>
            </form>
          </div>
          <div className="border-t border-cardBorder px-5 py-3 flex items-center justify-end">
            <Button
              onClick={editAlgo}
              disabled={loading}
              className="bg-header hover:bg-opacity-80 border border-header flex items-center gap-2 shadow-none hover:shadow-none text-white text-sm font-nunito-medium tracking-tight normal-case px-4 py-2 font-medium transition-all duration-300 ease-in-out rounded"
            >
              {loading ? (
                <div className="flex center justify-center">
                  <Spinner className="h-full " />
                </div>
              ) : (
                "  Save details"
              )}
            </Button>
          </div>
        </DialogBody>
      </Dialog>

      {/* CONFIRM DISABLE TILE */}
      <Dialog
        open={disable}
        handler={() => setDisable(!disable)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="lg"
      >
        <DialogBody className="p-0">
          <div className="flex items-center justify-between px-5 py-3 border-b border-cardBorder">
            <h5 className="text-textBlack font-nunito-bold text-lg tracking-tight">
              {strategyNameSelected}
            </h5>
            <Button
              className="p-2 rounded-full shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5 bg-transparent"
              onClick={() => {
                setDisable(!disable);
              }}
            >
              <IoClose className="w-5 h-5 text-greyText" />
            </Button>
          </div>
          <div className="p-5">
            <form>
              {/* INTRADAY OPTION BUY */}
              <div>
                <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5">
                  <div className="form-group">
                    <label
                      htmlFor="minCapital"
                      className="text-sm font-nunito-medium tracking-tight"
                    >
                      Are You Sure to {input?.isDisable ? "Enable" : "Disable"}
                    </label>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="border-t border-cardBorder px-5 py-3 flex items-center justify-end">
            <Button
              onClick={() => {
                // setInputScript({
                //   ...inputScript,
                //   isDisable: !inputScript?.isDisable,
                // })
                editAlgo();
              }}
              disabled={loading}
              className="bg-header hover:bg-opacity-80 border border-header flex items-center gap-2 shadow-none hover:shadow-none text-white text-sm font-nunito-medium tracking-tight normal-case px-4 py-2 font-medium transition-all duration-300 ease-in-out rounded"
            >
              {loading ? (
                <div className="flex center justify-center">
                  <Spinner className="h-full " />
                </div>
              ) : (
                " Save"
              )}
            </Button>
          </div>
        </DialogBody>
      </Dialog>

      {/* PCR STRATEGY */}
      <Dialog
        open={pcrStrategy}
        handler={() => setPCRStrategy(!pcrStrategy)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="lg"
      >
        <DialogBody className="p-0">
          <div className="flex items-center justify-between px-5 py-3 border-b border-cardBorder">
            <h5 className="text-textBlack font-nunito-bold text-lg tracking-tight">
              PCR Strategy
            </h5>
            <Button
              className="p-2 rounded-full shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5 bg-transparent"
              onClick={() => {
                setPCRStrategy(!pcrStrategy);
              }}
            >
              <IoClose className="w-5 h-5 text-greyText" />
            </Button>
          </div>
          <div className="p-5">
            <div className="grid grid-cols-3 gap-5">
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="minCapital"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Capital required
                  </label>
                  <input
                    type="number"
                    name="minCapital"
                    id="minCapital"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="₹ 50,000"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="status"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Visible on user panel
                  </label>
                  <Select
                    options={visible}
                    className="react-select mt-1"
                    classNamePrefix="dropdown"
                    placeholder="Visible on user panel"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="entryPerStock"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Max Entries per stock
                  </label>
                  <input
                    type="number"
                    name="entryPerStock"
                    id="entryPerStock"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Max entries per stock"
                    defaultValue="20"
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-4 gap-5 mt-5">
              <div className="form-group">
                <label
                  htmlFor="startTime"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  Start time
                </label>
                <input
                  type="time"
                  name="startTime"
                  id="startTime"
                  className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="Start Time"
                  defaultValue="10:15"
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="endTime"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  End time
                </label>
                <input
                  type="time"
                  name="endTime"
                  id="endTime"
                  className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="End Time"
                  defaultValue="14:55"
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="lastEntryTime"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  Last Entry Time
                </label>
                <input
                  type="time"
                  name="lastEntryTime"
                  id="lastEntryTime"
                  className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="Last Entry Time"
                  defaultValue="14:45"
                />
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="status"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Select Expiry
                  </label>
                  <Select
                    options={expiryOptions}
                    className="react-select mt-1"
                    classNamePrefix="dropdown"
                    placeholder="Select expiry"
                  />
                </div>
              </div>
            </div>

            {/* SCRIPT 1 */}
            <div className="w-full bg-[#F5F5F5] bg-opacity-50 border border-[#E5E5E5] rounded-md px-5 py-3 mt-5 first:mt-0">
              <div className="grid grid-cols-5 gap-5 items-center">
                <div className="col-span-1">
                  <h5 className="text-base font-nunito-bold text-black">
                    BankNifty
                  </h5>
                </div>
                <div className="col-span-1">
                  <div className="form-group">
                    <Select
                      options={[
                        { value: true, label: "Enable" },
                        { value: false, label: "Disable" },
                      ]}
                      className="react-select"
                      classNamePrefix="dropdown"
                      placeholder="Script status"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="bnf-lots"
                      id="bnf-lots"
                      defaultValue="1"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="No. of lots"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <Select
                      options={strikeParameter}
                      className="react-select mt-1"
                      classNamePrefix="dropdown"
                      placeholder="Strike Selection"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="bnf-value"
                      id="bnf-value"
                      defaultValue="400"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="Value"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* SCRIPT 2 */}
            <div className="w-full bg-[#F5F5F5] bg-opacity-50 border border-[#E5E5E5] rounded-md px-5 py-3 mt-5 first:mt-0">
              <div className="grid grid-cols-5 gap-5 items-center">
                <div className="col-span-1">
                  <h5 className="text-base font-nunito-bold text-black">
                    Nifty
                  </h5>
                </div>
                <div className="col-span-1">
                  <div className="form-group">
                    <Select
                      options={[
                        { value: true, label: "Enable" },
                        { value: false, label: "Disable" },
                      ]}
                      className="react-select"
                      classNamePrefix="dropdown"
                      placeholder="Script status"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="nifty-lots"
                      id="nifty-lots"
                      defaultValue="1"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="No. of lots"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <Select
                      options={strikeParameter}
                      className="react-select mt-1"
                      classNamePrefix="dropdown"
                      placeholder="Strike Selection"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="nifty-value"
                      id="nifty-value"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="Value"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* SCRIPT 3 */}
            <div className="w-full bg-[#F5F5F5] bg-opacity-50 border border-[#E5E5E5] rounded-md px-5 py-3 mt-5 first:mt-0">
              <div className="grid grid-cols-5 gap-5 items-center">
                <div className="col-span-1">
                  <h5 className="text-base font-nunito-bold text-black">
                    FinNifty
                  </h5>
                </div>
                <div className="col-span-1">
                  <div className="form-group">
                    <Select
                      options={[
                        { value: true, label: "Enable" },
                        { value: false, label: "Disable" },
                      ]}
                      className="react-select"
                      classNamePrefix="dropdown"
                      placeholder="Script status"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="fin-lots"
                      id="fin-lots"
                      defaultValue="1"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="No. of lots"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <Select
                      options={strikeParameter}
                      className="react-select mt-1"
                      classNamePrefix="dropdown"
                      placeholder="Strike Selection"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="fin-value"
                      id="fin-value"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="Value"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* SCRIPT 4 */}
            <div className="w-full bg-[#F5F5F5] bg-opacity-50 border border-[#E5E5E5] rounded-md px-5 py-3 mt-5 first:mt-0">
              <div className="grid grid-cols-5 gap-5 items-center">
                <div className="col-span-1">
                  <h5 className="text-base font-nunito-bold text-black">
                    Midcap Nifty
                  </h5>
                </div>
                <div className="col-span-1">
                  <div className="form-group">
                    <Select
                      options={[
                        { value: true, label: "Enable" },
                        { value: false, label: "Disable" },
                      ]}
                      className="react-select"
                      classNamePrefix="dropdown"
                      placeholder="Script status"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="midcap-lots"
                      id="midcap-lots"
                      defaultValue="1"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="No. of lots"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <Select
                      options={strikeParameter}
                      className="react-select mt-1"
                      classNamePrefix="dropdown"
                      placeholder="Strike Selection"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="midcap-value"
                      id="midcap-value"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="Value"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-t border-cardBorder px-5 py-3 flex items-center justify-end">
            <Button className="bg-header hover:bg-opacity-80 border border-header flex items-center gap-2 shadow-none hover:shadow-none text-white text-sm font-nunito-medium tracking-tight normal-case px-4 py-2 font-medium transition-all duration-300 ease-in-out rounded">
              Save details
            </Button>
          </div>
        </DialogBody>
      </Dialog>

      {/* INTRADAY OPTION SELL */}
      <Dialog
        open={intradayOptionSell}
        handler={() => setIntradayOptionSell(!intradayOptionSell)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="lg"
      >
        <DialogBody className="p-0">
          <div className="flex items-center justify-between px-5 py-3 border-b border-cardBorder">
            <h5 className="text-textBlack font-nunito-bold text-lg tracking-tight">
              Intraday Option Sell Strategy
            </h5>
            <Button
              className="p-2 rounded-full shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5 bg-transparent"
              onClick={() => {
                setIntradayOptionSell(!intradayOptionSell);
              }}
            >
              <IoClose className="w-5 h-5 text-greyText" />
            </Button>
          </div>
          <div className="p-5">
            <div className="grid grid-cols-3 gap-5">
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="minCapital"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Capital required
                  </label>
                  <input
                    type="number"
                    name="minCapital"
                    id="minCapital"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="₹ 50,000"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="status"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Visible on user panel
                  </label>
                  <Select
                    options={visible}
                    className="react-select mt-1"
                    classNamePrefix="dropdown"
                    placeholder="Visible on user panel"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="status"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Select Expiry
                  </label>
                  <Select
                    options={expiryOptions}
                    className="react-select mt-1"
                    classNamePrefix="dropdown"
                    placeholder="Select expiry"
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-5 mt-5">
              <div className="form-group">
                <label
                  htmlFor="startTime"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  Start time
                </label>
                <input
                  type="time"
                  name="startTime"
                  id="startTime"
                  className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="Start Time"
                  defaultValue="09:25"
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="endTime"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  End time
                </label>
                <input
                  type="time"
                  name="endTime"
                  id="endTime"
                  className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="End Time"
                  defaultValue="14:40"
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="lastEntryTime"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  Last Entry Time
                </label>
                <input
                  type="time"
                  name="lastEntryTime"
                  id="lastEntryTime"
                  className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="Last Entry Time"
                  defaultValue="14:35"
                />
              </div>
            </div>

            {/* SCRIPT 1 */}
            <div className="w-full bg-[#F5F5F5] bg-opacity-50 border border-[#E5E5E5] rounded-md px-5 py-3 mt-5 first:mt-0">
              <div className="grid grid-cols-5 gap-5 items-center">
                <div className="col-span-1">
                  <h5 className="text-base font-nunito-bold text-black">
                    BankNifty
                  </h5>
                </div>
                <div className="col-span-1">
                  <div className="form-group">
                    <Select
                      options={[
                        { value: true, label: "Enable" },
                        { value: false, label: "Disable" },
                      ]}
                      className="react-select"
                      classNamePrefix="dropdown"
                      placeholder="Script status"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="bnf-lots"
                      id="bnf-lots"
                      defaultValue="1"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="No. of lots"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <Select
                      options={strikeParameter}
                      className="react-select mt-1"
                      classNamePrefix="dropdown"
                      placeholder="Strike Selection"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="bnf-value"
                      id="bnf-value"
                      defaultValue="400"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="Value"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* SCRIPT 2 */}
            <div className="w-full bg-[#F5F5F5] bg-opacity-50 border border-[#E5E5E5] rounded-md px-5 py-3 mt-5 first:mt-0">
              <div className="grid grid-cols-5 gap-5 items-center">
                <div className="col-span-1">
                  <h5 className="text-base font-nunito-bold text-black">
                    Nifty
                  </h5>
                </div>
                <div className="col-span-1">
                  <div className="form-group">
                    <Select
                      options={[
                        { value: true, label: "Enable" },
                        { value: false, label: "Disable" },
                      ]}
                      className="react-select"
                      classNamePrefix="dropdown"
                      placeholder="Script status"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="nifty-lots"
                      id="nifty-lots"
                      defaultValue="1"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="No. of lots"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <Select
                      options={strikeParameter}
                      className="react-select mt-1"
                      classNamePrefix="dropdown"
                      placeholder="Strike Selection"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="nifty-value"
                      id="nifty-value"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="Value"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* SCRIPT 3 */}
            <div className="w-full bg-[#F5F5F5] bg-opacity-50 border border-[#E5E5E5] rounded-md px-5 py-3 mt-5 first:mt-0">
              <div className="grid grid-cols-5 gap-5 items-center">
                <div className="col-span-1">
                  <h5 className="text-base font-nunito-bold text-black">
                    FinNifty
                  </h5>
                </div>
                <div className="col-span-1">
                  <div className="form-group">
                    <Select
                      options={[
                        { value: true, label: "Enable" },
                        { value: false, label: "Disable" },
                      ]}
                      className="react-select"
                      classNamePrefix="dropdown"
                      placeholder="Script status"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="fin-lots"
                      id="fin-lots"
                      defaultValue="1"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="No. of lots"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <Select
                      options={strikeParameter}
                      className="react-select mt-1"
                      classNamePrefix="dropdown"
                      placeholder="Strike Selection"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="fin-value"
                      id="fin-value"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="Value"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* SCRIPT 4 */}
            <div className="w-full bg-[#F5F5F5] bg-opacity-50 border border-[#E5E5E5] rounded-md px-5 py-3 mt-5 first:mt-0">
              <div className="grid grid-cols-5 gap-5 items-center">
                <div className="col-span-1">
                  <h5 className="text-base font-nunito-bold text-black">
                    Midcap Nifty
                  </h5>
                </div>
                <div className="col-span-1">
                  <div className="form-group">
                    <Select
                      options={[
                        { value: true, label: "Enable" },
                        { value: false, label: "Disable" },
                      ]}
                      className="react-select"
                      classNamePrefix="dropdown"
                      placeholder="Script status"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="midcap-lots"
                      id="midcap-lots"
                      defaultValue="1"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="No. of lots"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <Select
                      options={strikeParameter}
                      className="react-select mt-1"
                      classNamePrefix="dropdown"
                      placeholder="Strike Selection"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="midcap-value"
                      id="midcap-value"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="Value"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-t border-cardBorder px-5 py-3 flex items-center justify-end">
            <Button className="bg-header hover:bg-opacity-80 border border-header flex items-center gap-2 shadow-none hover:shadow-none text-white text-sm font-nunito-medium tracking-tight normal-case px-4 py-2 font-medium transition-all duration-300 ease-in-out rounded">
              Save details
            </Button>
          </div>
        </DialogBody>
      </Dialog>

      {/* INTRADAY OPTION BUY */}
      <Dialog
        open={intradayOptionBuy}
        handler={() => setIntradayOptionBuy(!intradayOptionBuy)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="lg"
      >
        <DialogBody className="p-0">
          <div className="flex items-center justify-between px-5 py-3 border-b border-cardBorder">
            <h5 className="text-textBlack font-nunito-bold text-lg tracking-tight">
              Intraday Option Buy Strategy
            </h5>
            <Button
              className="p-2 rounded-full shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5 bg-transparent"
              onClick={() => {
                setIntradayOptionBuy(!intradayOptionBuy);
              }}
            >
              <IoClose className="w-5 h-5 text-greyText" />
            </Button>
          </div>
          <div className="p-5">
            <div className="grid grid-cols-3 gap-5">
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="minCapital"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Capital required
                  </label>
                  <input
                    type="number"
                    name="minCapital"
                    id="minCapital"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="₹ 50,000"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="status"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Visible on user panel
                  </label>
                  <Select
                    options={visible}
                    className="react-select mt-1"
                    classNamePrefix="dropdown"
                    placeholder="Visible on user panel"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="entryPerStock"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Maximum No. of entries per stock
                  </label>
                  <input
                    type="number"
                    name="entryPerStock"
                    id="entryPerStock"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Max entries per stock"
                    defaultValue="5"
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-5 mt-5">
              <div className="form-group">
                <label
                  htmlFor="startTime"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  Start time
                </label>
                <input
                  type="time"
                  name="startTime"
                  id="startTime"
                  className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="Start Time"
                  defaultValue="10:03"
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="endTime"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  End time
                </label>
                <input
                  type="time"
                  name="endTime"
                  id="endTime"
                  className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="End Time"
                  defaultValue="14:55"
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="lastEntryTime"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  Last Entry Time
                </label>
                <input
                  type="time"
                  name="lastEntryTime"
                  id="lastEntryTime"
                  className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="Last Entry Time"
                  defaultValue="14:45"
                />
              </div>
            </div>
            <div className="grid grid-cols-3 gap-5 mt-5">
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="target"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Target
                  </label>
                  <input
                    type="number"
                    name="target"
                    id="target"
                    defaultValue="1"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Target"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="stopLoss"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Stop Loss
                  </label>
                  <input
                    type="number"
                    name="stopLoss"
                    id="stopLoss"
                    defaultValue="1"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Stop Loss"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="lastEntryDate"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Last Entry Date
                  </label>
                  <input
                    type="date"
                    name="lastEntryDate"
                    id="lastEntryDate"
                    defaultValue="1"
                    className="mt-1 w-full block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Stop Loss"
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-5 mt-5">
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="maxMovement"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Maximum Movement(%)
                  </label>
                  <input
                    type="number"
                    name="maxMovement"
                    id="maxMovement"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Maximum Movement"
                    defaultValue="3.65"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="minMovement"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Minimum Movement(%)
                  </label>
                  <input
                    type="number"
                    name="minMovement"
                    id="minMovement"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Minimum Movement"
                    defaultValue="2"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="status"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Select Expiry
                  </label>
                  <Select
                    options={expiryOptions}
                    className="react-select mt-1"
                    classNamePrefix="dropdown"
                    placeholder="Select expiry"
                  />
                </div>
              </div>
            </div>

            {/* SCRIPT 1 */}
            <div className="w-full bg-[#F5F5F5] bg-opacity-50 border border-[#E5E5E5] rounded-md px-5 py-3 mt-5 first:mt-0">
              <div className="grid grid-cols-5 gap-5 items-center">
                <div className="col-span-1">
                  <h5 className="text-base font-nunito-bold text-black">
                    Script 1
                  </h5>
                </div>
                <div className="col-span-1">
                  <div className="form-group">
                    <Select
                      options={[
                        { value: true, label: "Enable" },
                        { value: false, label: "Disable" },
                      ]}
                      className="react-select"
                      classNamePrefix="dropdown"
                      placeholder="Script status"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="bnf-lots"
                      id="bnf-lots"
                      defaultValue="1"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="No. of lots"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <Select
                      options={strikeParameter}
                      className="react-select mt-1"
                      classNamePrefix="dropdown"
                      placeholder="Strike Selection"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="bnf-value"
                      id="bnf-value"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="Value"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* SCRIPT 2 */}
            <div className="w-full bg-[#F5F5F5] bg-opacity-50 border border-[#E5E5E5] rounded-md px-5 py-3 mt-5 first:mt-0">
              <div className="grid grid-cols-5 gap-5 items-center">
                <div className="col-span-1">
                  <h5 className="text-base font-nunito-bold text-black">
                    Script 2
                  </h5>
                </div>
                <div className="col-span-1">
                  <div className="form-group">
                    <Select
                      options={[
                        { value: true, label: "Enable" },
                        { value: false, label: "Disable" },
                      ]}
                      className="react-select"
                      classNamePrefix="dropdown"
                      placeholder="Script status"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="nifty-lots"
                      id="nifty-lots"
                      defaultValue="1"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="No. of lots"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <Select
                      options={strikeParameter}
                      className="react-select mt-1"
                      classNamePrefix="dropdown"
                      placeholder="Strike Selection"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="nifty-value"
                      id="nifty-value"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="Value"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogBody>
      </Dialog>

      {/* INTRADAY STOCK BUY */}
      <Dialog
        open={intradayStockBuy}
        handler={() => setIntradayStockBuy(!intradayStockBuy)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="lg"
      >
        <DialogBody className="p-0">
          <div className="flex items-center justify-between px-5 py-3 border-b border-cardBorder">
            <h5 className="text-textBlack font-nunito-bold text-lg tracking-tight">
              Intraday Stock Buy
            </h5>
            <Button
              className="p-2 rounded-full shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5 bg-transparent"
              onClick={() => {
                setIntradayStockBuy(!intradayStockBuy);
              }}
            >
              <IoClose className="w-5 h-5 text-greyText" />
            </Button>
          </div>

          <div className="p-5">
            <div className="grid grid-cols-3 gap-5">
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="minCapital"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Capital required
                  </label>
                  <input
                    type="number"
                    name="minCapital"
                    id="minCapital"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="₹ 50,000"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="status"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Visible on user panel
                  </label>
                  <Select
                    options={visible}
                    className="react-select mt-1"
                    classNamePrefix="dropdown"
                    placeholder="Visible on user panel"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="entryPerStock"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Maximum No. of entries per stock
                  </label>
                  <input
                    type="number"
                    name="entryPerStock"
                    id="entryPerStock"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Max entries per stock"
                    defaultValue="5"
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-5 mt-5">
              <div className="form-group">
                <label
                  htmlFor="startTime"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  Start time
                </label>
                <input
                  type="time"
                  name="startTime"
                  id="startTime"
                  className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="Start Time"
                  defaultValue="10:03"
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="endTime"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  End time
                </label>
                <input
                  type="time"
                  name="endTime"
                  id="endTime"
                  className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="End Time"
                  defaultValue="14:55"
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="lastEntryTime"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  Last Entry Time
                </label>
                <input
                  type="time"
                  name="lastEntryTime"
                  id="lastEntryTime"
                  className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="Last Entry Time"
                  defaultValue="14:45"
                />
              </div>
            </div>
            <div className="grid grid-cols-3 gap-5 mt-5">
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="target"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Target
                  </label>
                  <input
                    type="number"
                    name="target"
                    id="target"
                    defaultValue="1"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Target"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="stopLoss"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Stop Loss
                  </label>
                  <input
                    type="number"
                    name="stopLoss"
                    id="stopLoss"
                    defaultValue="1"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Stop Loss"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="tradedValue"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Traded value
                  </label>
                  <input
                    type="number"
                    name="tradedValue"
                    id="tradedValue"
                    defaultValue="15000"
                    className="mt-1 w-full block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Traded Value"
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-5 mt-5">
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="maxMovement"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Maximum Movement(%)
                  </label>
                  <input
                    type="number"
                    name="maxMovement"
                    id="maxMovement"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Maximum Movement"
                    defaultValue="7"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="minMovement"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Minimum Movement(%)
                  </label>
                  <input
                    type="number"
                    name="minMovement"
                    id="minMovement"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Minimum Movement"
                    defaultValue="3.5"
                  />
                </div>
              </div>
            </div>
          </div>
        </DialogBody>
      </Dialog>
    </section>
  );
};

export default AlgoSettings;
