import request from "./request"

// AUTH
export const login = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/auth/login", data)
      .then((res) => {
        resolve(res.data)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const register = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/auth/register", data)
      .then((res) => {
        resolve(res.data)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const getUser = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/user", { params })
      .then((res) => {
        resolve(res)
      })
      .catch((e) => {
        reject(e)
      })
  })
}
export const updatePassword = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("/auth/password", data)
      .then((res) => {
        resolve(res)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

// algorithm
export const getAlgorithm = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/algorithm", { params })
      .then((res) => {
        resolve(res)
      })
      .catch((e) => {
        reject(e)
      })
  })
}
export const getAlgorithmById = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/algorithm/" + id)
      .then((res) => {
        resolve(res)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const addAlgorithmData = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/algorithm", data)
      .then((res) => {
        resolve(res)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const updateAlgorithm = (id, data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("/algorithm/" + id, data)
      .then((res) => {
        resolve(res)
      })
      .catch((e) => {
        reject(e)
      })
  })
}
export const deleteAlgorithm = (id) => {
  return new Promise(async (resolve, reject) => {
    await request
      .delete("/algorithm/" + id)
      .then((res) => {
        resolve(res)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const addAlgorithmScript = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/algorithmscript", data)
      .then((res) => {
        resolve(res)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const getAlgorithmScript = (params) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/algorithmscript", { params })
      .then((res) => {
        resolve(res)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const userInvite = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .post("/user/invite", data)
      .then((res) => {
        resolve(res)
      })
      .catch((e) => {
        reject(e)
      })
  })
}
export const userUpdateInvited = (id, data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("/user/invite/" + id, data)
      .then((res) => {
        resolve(res)
      })
      .catch((e) => {
        reject(e)
      })
  })
}
export const userUpdate = (id, data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .put("/user/" + id, data)
      .then((res) => {
        resolve(res)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const getTransactionsAPI = (data) => {
  return new Promise(async (resolve, reject) => {
    await request.get(`/transaction`, { params: data }).then((res) => resolve(res)).catch((e) => reject(e));
  })
}

export const getUserTransactionGraph = (data) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get("/transaction/graph", { params: data })
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};


export const getSquareOffSettingsApi = (id, userId) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`algorithm/squareOff/${id}`, {})
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getSquareOffProfileSettingsApi = (id, userId) => {
  return new Promise(async (resolve, reject) => {
    await request
      .get(`algorithm/squareOff/${id}/${userId}`, {})
      .then((res) => {
        resolve(res.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};