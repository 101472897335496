import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import actions from "../redux/user/actions";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Tooltip, Collapse } from "@material-tailwind/react";

/* ICONS */
import { FiLogOut } from "react-icons/fi";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

/* IMAGES */
import Logo from "../assets/images/logo.webp";

const Header = () => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  var pathname = window.location.pathname;
  const [mobileNavbar, setMobileNavbar] = useState(false);

  const handleLogout = () => {
    localStorage.clear();
    dispatch(actions.setUser(null));
    dispatch(actions.setLoggedIn(false));
    dispatch(actions.setToken(null));
    navigate("/");
  };

  return (
    <header className="bg-header border-b border-white border-opacity-10 py-2">
      <div className="container">
        <div className="flex items-center justify-between">
          {/* LEFT NAVIGATION */}
          <div className="flex items-center justify-between gap-16">
            {/* LOGO */}
            <img src={Logo} alt="Algosquant" className="h-[5vh] w-auto" />

            {/* NAVIGATION OPTIONS */}
            <div className="hidden xl:flex lg:flex items-center gap-8">
              <Link
                to="/dashboard"
                className={`block ${
                  pathname === "/dashboard" && "!text-white text-opacity-100"
                } text-white text-opacity-60 text-sm font-nunito-semibold hover:text-white tracking-tight transition-all duration-300 ease-in-out custom-underline`}
              >
                Users
              </Link>
              <Link
                to="/algo-settings"
                className={`block ${
                  pathname === "/algo-settings" &&
                  "!text-white text-opacity-100"
                } text-white text-opacity-60 text-sm font-nunito-regular hover:text-white tracking-tight transition-all duration-300 ease-in-out custom-underline`}
              >
                Algo Settings
              </Link>
            </div>
          </div>

          {/* RIGHT NAVIGATION */}
          <div className="hidden xl:flex lg:flex items-center gap-10">
            <div className="flex items-center gap-3 hover:outline-none cursor-pointer">
              <div className="w-10 h-10 bg-white border border-[#E5E5E5] rounded-full flex items-center justify-center">
                <img
                  src={Logo}
                  className="w-7 h-7 object-contain rounded-full"
                  alt="User profile"
                />
              </div>
              <div>
                <h2 className="text-sm font-nunito-semibold text-white leading-none tracking-tight">
                  {user?.firstName}&nbsp;{user?.lastName}
                </h2>
                <p className="text-[1.3vh] font-nunito-regular text-white text-opacity-50 leading-none mt-1">
                  {user?.role}
                </p>
              </div>
            </div>

            <Tooltip
              content="Logout"
              placement="bottom"
              className="text-sm font-nunito-regular tracking-tight"
            >
              <Button
                onClick={handleLogout}
                className="p-3 rounded-full bg-transparent shadow-none hover:shadow-none hover:bg-white hover:bg-opacity-10"
              >
                <FiLogOut className="w-5 h-5 text-white" />
              </Button>
            </Tooltip>
          </div>

          {/* MOBILE MENU BUTTON */}
          <Button
            className="p-0 shadow-none hover:shadow-none bg-transparent block xl:hidden lg:hidden md:hidden mr-4"
            onClick={() => setMobileNavbar(!mobileNavbar)}
          >
            {mobileNavbar ? (
              <XMarkIcon className="h-6 w-6 text-[#808080]" strokeWidth={2} />
            ) : (
              <Bars3Icon className="h-6 w-6 text-[#808080]" strokeWidth={2} />
            )}
          </Button>
        </div>
      </div>

      {/* MOBILE NAVBAR */}
      <Collapse open={mobileNavbar}>
        <div className="p-5 bg-white border-b border-b-bright-gray">
          <Link
            href="/my-story"
            className="text-base font-nunito-medium custom-underline text-black block w-fit mb-2"
          >
            Users
          </Link>
          <Link
            href="/classical-dance"
            className="text-base font-nunito-medium custom-underline text-black block w-fit mb-2"
          >
            Algo Settings
          </Link>
          <Link
            href="/"
            onClick={handleLogout}
            className="text-base font-nunito-medium custom-underline text-black block w-fit mb-2"
          >
            Logout
          </Link>
        </div>
      </Collapse>
    </header>
  );
};

export default Header;
