/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import Select from "react-select";

import {
  getAlgorithm,
  addAlgorithmData,
  deleteAlgorithm,
  getAlgorithmById,
  updateAlgorithm,
  getAlgorithmScript,
  getSquareOffApi,
  getSquareOffSettingsApi,
} from "../../service/api";
import { toast } from "react-toastify";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Button,
  Card,
  CardBody,
  Dialog,
  DialogBody,
  Spinner,
} from "@material-tailwind/react";

/* FORM CONTROL */
import { useFormik } from "formik";
import * as yup from "yup";

/* ICONS */
import { IoMdAdd, IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { LuUsers } from "react-icons/lu";
import { IoClose } from "react-icons/io5";

/* IMAGES */
import Logo from "../../assets/images/logo.webp";

/* INDEX OPTIONS */
const marketIndices = [
  { value: "BANKNIFTY", label: "BankNifty" },
  { value: "NIFTY50", label: "Nifty50" },
  { value: "GIFTNIFTY", label: "GiftNifty" },
  { value: "FINNIFTY", label: "FinNifty" },
];

const visible = [
  { value: true, label: "YES" },
  { value: false, label: "NO" },
];

const derivatives = [
  { value: "NSE", label: "NSE" },
  { value: "F&O", label: "F&O" },
  { value: "INTRADAY", label: "INTRADAY" },
];

const statusOption = [
  { value: "ON", label: "ON" },
  { value: "OFF", label: "OFF" },
];

const strikeParameter = [
  { value: "1", label: "ATM" },
  { value: "2", label: "Round off 5 and ATM" },
  { value: "3", label: "Round off 10 and ATM" },
  { value: "4", label: "Round off 50 and ATM" },
  { value: "5", label: "Round off 100 and ATM" },
  { value: "6", label: "Nearest to Premium" },
  {
    value: "7",
    label: "Greater than & Nearest to Premium",
  },
  {
    value: "8",
    label: "Less than & Nearest to Premium",
  },
  {
    value: "9",
    label: "Round off 5 & Nearest to Premium",
  },
  {
    value: "10",
    label: "Round off 5 & greater than & Nearest to Premium",
  },
  {
    value: "11",
    label: "Round off 5 & less than & Nearest to Premium",
  },
  {
    value: "12",
    label: "Round off 10 & Nearest to Premium",
  },
  {
    value: "13",
    label: "Round off 10 & greater than & Nearest to Premium",
  },
  {
    value: "14",
    label: "Round off 10 & less than & Nearest to Premium",
  },
  {
    value: "15",
    label: "Round off 50 & Nearest to Premium",
  },
  {
    value: "16",
    label: "Round off 50 & greater than & Nearest to Premium",
  },
  {
    value: "17",
    label: "Round off 50 & less than & Nearest to Premium",
  },
  {
    value: "18",
    label: "Round off 100 & Nearest to Premium",
  },
  {
    value: "19",
    label: "Round Off 100 and Greater than & Nearest to Premium",
  },
  {
    value: "20",
    label: "Round Off 100 and Less than & Nearest to Premium",
  },
];

/* DERIVATIVES BADGE */
const DerivativeBadge = (props) => {
  return (
    <div className="bg-header px-2 py-1 text-[1.2vh] rounded font-nunito-semibold text-white leading-none">
      {props.derivative}
    </div>
  );
};

/* Validation Schema */
const validationSchema = yup.object().shape({
  name: yup.string().optional(),
  marketIndex: yup.string().optional(),
  derivatives: yup.string().optional(),
  minimumCapital: yup.string().optional(),
  stopLoss: yup.string().optional(),
  revisesStopLoss: yup.string().optional(),
  trailingStopLoss: yup.string().optional(),
  trailingStopLossActivation: yup.string().optional(),
  capitalRequire: yup.string().optional(),
  isVisible: yup.boolean().optional(),
  startTime: yup.string().optional(),
  endTime: yup.string().optional(),
  exitDate: yup.string().optional(),
  exitTime: yup.string().optional(),
  expiry: yup.string().optional(),
  target: yup.string().optional(),
  lastEntry: yup.string().optional(),
  maxTrade: yup.number().optional(),
  maximumMovement: yup.string().optional(),
  minimumMovement: yup.string().optional(),
  noOfStokes: yup.number().optional(),
  isDisable: yup.boolean().optional(),
  tradeValue: yup.number().optional(),
  maximumEntriesPerStockPerDay: yup.number(),
  maximumEntriesAtMoment: yup.number(),
  lastEntryTime: yup.string(),
  lastEntryDate: yup.string(),
  interval: yup.string(),
  scripts: yup.array(),
});

/* EXPIRY */
const expiryOptions = [
  { label: "Next Week", value: "Next Week" },
  { label: "Current Week", value: "Current Week" },
  { label: "Current Month", value: "Current Month" },
];

const AlgoSettings = () => {
  /* STATE VARIABLES */
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [formLoading, setFormLoading] = useState(false);

  const [disable, setDisable] = useState(false);
  const [strategyNameSelected, setStrategyNameSelected] = useState("");

  const [strategyDetails, setStrategyDetails] = useState(null);

  const [selectedEditAlgo, setSelectedEditAlgo] = useState(null);
  const [selectedEditAlgoScriptsData, setSelectedEditAlgoScriptsData] =
    useState(null);

  /* GET ALGORITHM  */
  const getAlgo = () => {
    let params = { page: 1, sizePerPage: 100 };
    getAlgorithm(params)
      .then((res) => {
        console.log(" getAlgo==>", res);
        if (res?.data?.status) {
          let sortedArray = res?.data?.data?.docs?.sort(function (a, b) {
            return a?.order - b?.order;
          });
          setData(sortedArray);
        }
      })
      .catch((e) => {
        console.log("e==>", e);
      });
  };

  const handleStatusChange = (data) => {
    console.log("Data", data);
    const obj = {};

    if (data?.data?.name) {
      obj.name = data?.data?.name;
    }

    if (data?.data?.marketIndex) {
      obj.marketIndex = data?.data?.marketIndex;
    }

    if (data?.data?.derivatives) {
      obj.derivatives = data?.data?.derivatives;
    }

    if (data?.data?.minimumCapital) {
      obj.minimumCapital = data?.data?.minimumCapital;
    }

    if (data?.data?.stopLoss) {
      obj.stopLoss = data?.data?.stopLoss;
    }

    if (data?.data?.revisesStopLoss) {
      obj.revisesStopLoss = data?.data?.revisesStopLoss;
    }

    if (data?.data?.trailingStopLoss) {
      obj.trailingStopLoss = data?.data?.trailingStopLoss;
    }

    if (data?.data?.trailingStopLossActivation) {
      obj.trailingStopLossActivation = data?.data?.trailingStopLossActivation;
    }

    if (data?.data?.capitalRequire) {
      obj.capitalRequire = data?.data?.capitalRequire;
    }

    obj.isVisible = data?.data?.isVisible;

    if (data?.data?.startTime) {
      obj.startTime = data?.data?.startTime;
    }

    if (data?.data?.interval) {
      obj.interval = data?.data?.interval;
    }

    if (data?.data?.endTime) {
      obj.endTime = data?.data?.endTime;
    }

    if (data?.data?.exitDate) {
      obj.exitDate = data?.data?.exitDate;
    }

    if (data?.data?.expiry) {
      obj.expiry = data?.data?.expiry;
    }

    if (data?.data?.target) {
      obj.target = data?.data?.target;
    }

    if (data?.data?.lastEntry) {
      obj.lastEntry = data?.data?.lastEntry;
    }

    if (data?.data?.maxTrade) {
      obj.maxTrade = data?.data?.maxTrade;
    }

    if (data?.data?.maximumMovement) {
      obj.maximumMovement = data?.data?.maximumMovement;
    }

    if (data?.data?.minimumMovement) {
      obj.minimumMovement = data?.data?.minimumMovement;
    }

    if (data?.data?.noOfStokes) {
      obj.noOfStokes = data?.data?.noOfStokes;
    }

    obj.isDisable = data?.data?.isDisable;

    if (data?.data?.tradeValue) {
      obj.tradeValue = data?.data?.tradeValue;
    }

    if (data?.data?.maximumEntriesPerStockPerDay) {
      obj.maximumEntriesPerStockPerDay =
        data?.data?.maximumEntriesPerStockPerDay;
    }

    if (data?.data?.maximumEntriesAtMoment) {
      obj.maximumEntriesAtMoment = data?.data?.maximumEntriesAtMoment;
    }

    if (data?.data?.lastEntryTime) {
      obj.lastEntryTime = data?.data?.lastEntryTime;
    }

    if (data?.data?.lastEntryDate) {
      obj.lastEntryDate = data?.data?.lastEntryDate;
    }

    if (data?.data?.scripts) {
      obj.scripts = data?.data?.scripts;
    }

    obj.isDisable = data?.isDisable;
    updateAlgorithm(data?.data?._id, obj)
      .then((res) => {
        if (res?.data?.status) {
          console.log(res);
          toast.success(res?.data?.message);
          setStrategyDetails(null);
          setStrategyNameSelected(null);
          setDisable(!disable);
        } else {
          console.log(res?.message);
          toast.error(res?.message);
        }
      })
      .catch((e) => {
        setFormLoading(false);
        toast.error(e?.response?.data?.message);
      })
      .finally((res) => {
        getAlgo();
        setFormLoading(false);
      });
  };

  const handleClose = (id) => {
    getSquareOffSettingsApi(id?.strategy)
      .then((res) => {
        if (res?.data?.status) {
          console.log(res);
          toast.success(res?.data?.message);
        } else {
          console.log(res?.message);
          toast.error(res?.message);
        }
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      })
      .finally((res) => {
        getAlgo();
      });
  };

  /* ALGORITHM TILE */
  const AlgorithmTile = (props) => {
    return (
      <Card className="bg-white border border-cardBorder rounded-md mt-5 first:mt-0">
        <CardBody className="p-5">
          <div className="grid grid-cols-1 xl:grid-cols-6 lg:grid-cols-6 items-center">
            <div className="col-span-2">
              <div className="flex items-center gap-5">
                <div>
                  <h5 className="text-xl font-nunito-bold text-textBlack tracking-tight capitalize">
                    {props.name}
                  </h5>
                  <div className="flex items-center gap-1 mt-1">
                    {props.derivatives.map((derivative) => (
                      <DerivativeBadge derivative={derivative.name} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-1">
              {props.data.isDisable ? (
                <div className="bg-orange-500 bg-opacity-10 rounded-md px-4 py-1 w-fit">
                  <p className="text-orange-500 font-nunito-semibold text-sm">
                    Paused
                  </p>
                </div>
              ) : (
                <div className="bg-green-500 bg-opacity-10 rounded-md px-4 py-1 w-fit">
                  <p className="text-green-500 font-nunito-semibold text-sm">
                    Active
                  </p>
                </div>
              )}

              <div className="bg-red-500 bg-opacity-10 rounded-md px-4 py-1 w-fit hidden">
                <p className="text-red-500 font-nunito-semibold text-sm">
                  Closed
                </p>
              </div>
            </div>
            <div className="col-span-1 mt-5 xl:mt-0 lg:mt-0 flex items-center justify-center">
              <div>
                <h5 className="text-sm font-nunito-regular text-greyText tracking-tight">
                  Min. capital required
                </h5>
                <p className="text-lg font-nunito-bold tracking-tight text-black mt-1">
                  ₹ {props.minCapital}
                </p>
              </div>
            </div>
            <div className="col-span-2 mt-5 xl:mt-0 lg:mt-0">
              <div className="flex items-center justify-end gap-2">
                <Button
                  className="bg-white hover:bg-black hover:bg-opacity-5 border border-cardBorder gap-2 shadow-none hover:shadow-none text-textBlack text-sm font-nunito-medium tracking-tight normal-case px-4 py-2 font-medium transition-all duration-300 ease-in-out rounded flex items-center justify-center"
                  onClick={() => {
                    setSelectedEditAlgo(props?.data);
                    setUpdateStrategy(!updateStrategy);
                    setStrategyNameSelected(props.name);
                  }}
                >
                  Edit details
                </Button>
                {!props.data.isDisable ? (
                  <Button
                    className="shadow-none hover:shadow-none border border-green-500 border-opacity-20 bg-green-500 bg-opacity-10 text-green-500 normal-case text-sm font-nunito-semibold tracking-tight hover:bg-green-50 px-4 py-2 font-medium transition-all duration-300 ease-in-out rounded"
                    onClick={() => {
                      // setId(props?.data?._id);
                      setStrategyNameSelected(props.name);
                      setStrategyDetails(props?.data);
                      setDisable(!disable);
                    }}
                  >
                    Enabled
                  </Button>
                ) : (
                  <Button
                    className="shadow-none hover:shadow-none border border-red-500 border-opacity-20 bg-red-500 bg-opacity-10 text-red-500 normal-case text-sm font-nunito-semibold tracking-tight hover:bg-red-50 px-4 py-2 font-medium transition-all duration-300 ease-in-out rounded"
                    onClick={() => {
                      // setId(props?.data?._id);
                      setStrategyNameSelected(props.name);
                      setStrategyDetails(props?.data);
                      setDisable(!disable);
                    }}
                  >
                    Disabled
                  </Button>
                )}
                <Button
                  onClick={() => {
                    handleClose(props?.data);
                  }}
                  className="bg-white hover:bg-black hover:bg-opacity-5 border border-cardBorder gap-2 shadow-none hover:shadow-none text-textBlack text-sm font-nunito-medium tracking-tight normal-case px-4 py-2 font-medium transition-all duration-300 ease-in-out rounded flex items-center justify-center"
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  };

  /* STRATEGY OPTIONS */
  const [updateStrategy, setUpdateStrategy] = useState(false);

  const [pcrStrategy, setPCRStrategy] = useState(false);
  const [intradayOptionSell, setIntradayOptionSell] = useState(false);
  const [intradayOptionBuy, setIntradayOptionBuy] = useState(false);
  const [intradayStockBuy, setIntradayStockBuy] = useState(false);
  const [stockBuy, setStockBuy] = useState(false);
  const [positionalOptionSell, setPositionalOptionSell] = useState(false);

  // Formik Function For Add Tag
  const formik = useFormik({
    initialValues: {
      name: "",
      marketIndex: "",
      derivatives: "",
      minimumCapital: "",
      stopLoss: "",
      revisesStopLoss: "",
      trailingStopLoss: "",
      trailingStopLossActivation: "",
      capitalRequire: "",
      isVisible: "",
      startTime: "",
      endTime: "",
      exitDate: "",
      expiry: "",
      target: "",
      lastEntry: "",
      maxTrade: "",
      maximumMovement: "",
      minimumMovement: "",
      noOfStokes: "",
      isDisable: "",
      tradeValue: "",
      maximumEntriesPerStockPerDay: "",
      maximumEntriesAtMoment: "",
      lastEntryTime: "",
      lastEntryDate: "",
      exitTime: "",
      scripts: "",
      interval: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      const obj = {};

      if (values?.name) {
        obj.name = values?.name;
      }

      if (values?.marketIndex) {
        obj.marketIndex = values?.marketIndex;
      }

      if (values?.derivatives) {
        obj.derivatives = values?.derivatives;
      }

      if (values?.minimumCapital) {
        obj.minimumCapital = values?.minimumCapital;
      }

      if (values?.stopLoss) {
        obj.stopLoss = String(values?.stopLoss);
      }

      if (values?.revisesStopLoss) {
        obj.revisesStopLoss = values?.revisesStopLoss;
      }

      if (values?.trailingStopLoss) {
        obj.trailingStopLoss = values?.trailingStopLoss;
      }

      if (values?.trailingStopLossActivation) {
        obj.trailingStopLossActivation = values?.trailingStopLossActivation;
      }

      if (values?.capitalRequire) {
        obj.capitalRequire = values?.capitalRequire;
      }

      obj.isVisible = values?.isVisible;

      if (values?.startTime) {
        obj.startTime = values?.startTime;
      }

      if (values?.interval) {
        obj.interval = values?.interval;
      }

      if (values?.endTime) {
        obj.endTime = values?.endTime;
      }

      if (values?.expiry) {
        obj.expiry = values?.expiry;
      }

      if (values?.target) {
        obj.target = String(values?.target);
      }

      if (values?.lastEntry) {
        obj.lastEntry = values?.lastEntry;
      }

      if (values?.maxTrade) {
        obj.maxTrade = values?.maxTrade;
      }

      if (values?.maximumMovement) {
        obj.maximumMovement = values?.maximumMovement;
      }

      if (values?.minimumMovement) {
        obj.minimumMovement = values?.minimumMovement;
      }

      if (values?.noOfStokes) {
        obj.noOfStokes = values?.noOfStokes;
      }

      if (values?.exitTime) {
        obj.exitTime = values?.exitTime;
      }

      obj.isDisable = values?.isDisable;

      if (values?.tradeValue) {
        obj.tradeValue = values?.tradeValue;
      }

      if (values?.maximumEntriesPerStockPerDay) {
        obj.maximumEntriesPerStockPerDay = values?.maximumEntriesPerStockPerDay;
      }

      if (values?.maximumEntriesAtMoment) {
        obj.maximumEntriesAtMoment = values?.maximumEntriesAtMoment;
      }

      if (values?.lastEntryTime) {
        obj.lastEntryTime = values?.lastEntryTime;
      }

      if (values?.lastEntryDate) {
        obj.lastEntryDate = values?.lastEntryDate;
      }

      if (values?.exitDate) {
        obj.exitDate = values?.exitDate;
      }

      if (values?.scripts) {
        obj.scripts = values?.scripts;
      }

      // const formData = new FormData();
      // Object.entries(values).forEach((entry) => {
      //     const [key, value] = entry;
      //     if (validationSchema?.fields?.hasOwnProperty(key)) {
      //         if (value) {

      //             if (key === "scripts") {
      //                 if (value?.length > 0) {
      //                     value?.forEach((element) => {
      //                         console.log("elemetn",element)
      //                         formData.append(key, element);
      //                     })
      //                 }
      //             } else {
      //                 formData.append(key, value);
      //             }

      //         }

      //     }
      // });
      setFormLoading(true);
      updateAlgorithm(selectedEditAlgo?._id, obj)
        .then((res) => {
          if (res?.data?.status) {
            console.log(res);
            toast.success(res?.data?.message);
            formik.handleReset();
            setSelectedEditAlgoScriptsData(null);
            setUpdateStrategy(!updateStrategy);
          } else {
            console.log(res?.message);
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          setFormLoading(false);
          toast.error(e?.response?.data?.message);
        })
        .finally((res) => {
          getAlgo();
          setFormLoading(false);
        });
    },
  });

  useEffect(() => {
    getAlgo();
  }, []);

  useEffect(() => {
    if (selectedEditAlgo !== null) {
      console.log("el", selectedEditAlgo);
      Object.entries(selectedEditAlgo).forEach((entry) => {
        const [key, value] = entry;
        if (validationSchema?.fields?.hasOwnProperty(key)) {
          formik.setFieldValue(key, value);
          if (key === "scripts") {
            setSelectedEditAlgoScriptsData(value);
          }
        }
      });
    }
  }, [selectedEditAlgo]);

  return (
    <section className="py-12">
      <div className="container">
        {/* GREETING */}
        <div className="block xl:flex lg:flex items-center justify-between">
          <div>
            <h1 className="text-3xl font-nunito-bold text-textBlack tracking-tight">
              Algorithm Management
            </h1>
            <p className="text-base font-nunito-regular tracking-tight text-greyText">
              Edit and manage the algorithms operating on your platform.
            </p>
          </div>
        </div>

        {/* ALGORITHMS LIST */}
        {data && data?.length !== 0 ? (
          <div className="pt-12">
            {data?.map((obj) => {
              return (
                <AlgorithmTile
                  name={obj?.name}
                  activeUsers="30"
                  stockName={obj?.marketIndex}
                  minCapital={obj?.capitalRequire}
                  derivatives={[{ name: obj?.derivatives }]}
                  data={obj}
                  key={obj?._id}
                />
              );
            })}
          </div>
        ) : (
          <p className="mt-3 text-base font-nunito-regular tracking-tight text-greyText">
            We were not able to find any algo settings.
          </p>
        )}
      </div>

      {/* CONFIRM DISABLE TILE */}
      <Dialog
        open={disable}
        handler={() => setDisable(!disable)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="xs"
      >
        <DialogBody className="p-0">
          <div className="flex items-center justify-between px-5 py-3 border-b border-cardBorder">
            <h5 className="text-textBlack font-nunito-bold text-lg tracking-tight">
              {strategyNameSelected}
            </h5>
            <Button
              className="p-2 rounded-full shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5 bg-transparent"
              onClick={() => {
                setDisable(!disable);
              }}
            >
              <IoClose className="w-5 h-5 text-greyText" />
            </Button>
          </div>
          <div className="p-5">
            <form>
              {/* INTRADAY OPTION BUY */}
              <div>
                <p className="text-sm font-nunito-medium tracking-tight">
                  Are you you want to{" "}
                  {!strategyDetails?.isDisable ? "disable" : "enable"} this
                  strategy ?
                </p>
              </div>
            </form>
          </div>
          <div className="border-t border-cardBorder px-5 py-3 flex items-center justify-end">
            <Button
              onClick={() => {
                handleStatusChange({
                  data: strategyDetails,
                  isDisable: !strategyDetails?.isDisable,
                });
              }}
              disabled={loading}
              className="bg-header hover:bg-opacity-80 border border-header flex items-center gap-2 shadow-none hover:shadow-none text-white text-sm font-nunito-medium tracking-tight normal-case px-4 py-2 font-medium transition-all duration-300 ease-in-out rounded"
            >
              {loading ? (
                <div className="flex center justify-center">
                  <Spinner className="h-full " />
                </div>
              ) : (
                " Save details"
              )}
            </Button>
          </div>
        </DialogBody>
      </Dialog>

      {/* FINAL MODAL POPUP */}
      <Dialog
        open={updateStrategy}
        handler={() => {
          setUpdateStrategy(!updateStrategy);
          setSelectedEditAlgo(null);
          formik.resetForm();
        }}
        dismiss={{ enabled: false }}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size={formik?.values?.name.includes("1") ? "lg" : "md"}
      >
        <DialogBody className="p-0">
          <div className="flex items-center justify-between px-5 py-3 border-b border-cardBorder">
            <h5 className="text-textBlack font-nunito-bold text-lg tracking-tight">
              {formik?.values?.name}
            </h5>
            <Button
              className="p-2 rounded-full shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5 bg-transparent"
              onClick={() => {
                setUpdateStrategy(!updateStrategy);
                setSelectedEditAlgo(null);
                formik.resetForm();
              }}
            >
              <IoClose className="w-5 h-5 text-greyText" />
            </Button>
          </div>
          <form onSubmit={formik.handleSubmit}>
            {/* FORM DETAILS */}
            <div className="p-5">
              {/* ALGO 5 - POSITIONAL OPTION SELL */}
              {formik?.values?.name.includes("5") && (
                <>
                  {/* FIRST ROW */}
                  <div className="grid grid-cols-2 gap-5">
                    {/* CAPITAL REQUIRED */}
                    <div className="col-span-1">
                      <div className="form-group w-full">
                        <label
                          htmlFor="capitalRequire"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          Capital required
                        </label>
                        <input
                          type="number"
                          className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="₹ 50,000"
                          name="capitalRequire"
                          id="capitalRequire"
                          onChange={formik.handleChange}
                          value={formik?.values?.capitalRequire}
                          defaultValue={formik?.values?.capitalRequire}
                        />
                      </div>
                    </div>

                    {/* VISIBLE ON USER PANEL */}
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          htmlFor="status"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          Visible on user panel
                        </label>
                        <Select
                          options={visible}
                          className="react-select mt-1"
                          classNamePrefix="dropdown"
                          placeholder="Visible on user panel"
                          onChange={(e) => {
                            formik.setFieldValue("isVisible", e?.value);
                          }}
                          value={
                            formik.values.isVisible
                              ? { value: true, label: "YES" }
                              : { value: false, label: "NO" }
                          }
                        />
                      </div>
                    </div>

                    {/* <div className="col-span-1">
                      <div className="form-group">
                        <label
                          htmlFor="interval"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          Interval (in minutes)
                        </label>
                        <input
                          type="number"
                          className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="1"
                          name="interval"
                          id="interval"
                          onChange={formik.handleChange}
                          value={formik?.values?.interval}
                        />
                      </div>
                    </div> */}
                  </div>

                  {/* SECOND ROW */}
                  <div className="grid grid-cols-3 gap-5 mt-5">
                    {/* LAST ENTRY DATE */}
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          htmlFor="lastEntryDate"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          Last Entry Date
                        </label>
                        <input
                          type="date"
                          name="lastEntryDate"
                          id="lastEntryDate"
                          className="mt-1 w-full block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="Last Entry Date"
                          value={formik.values.lastEntryDate}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>

                    {/* EXIT DATE */}
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          htmlFor="exitDate"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          Exit Date
                        </label>
                        <input
                          type="date"
                          name="exitDate"
                          id="exitDate"
                          className="mt-1 w-full block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="Exit"
                          value={formik.values.exitDate}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>

                    {/* EXIT TIME */}
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          htmlFor="exitTime"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          Exit Time
                        </label>
                        <input
                          type="time"
                          name="exitTime"
                          id="exitTime"
                          className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="Exit Time"
                          defaultValue="10:45"
                          value={formik.values.exitTime}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* ALGO 4 - INTRADAY STOCK BUY */}
              {formik?.values?.name.includes("4") && (
                <>
                  {/* FIRST ROW */}
                  <div className="grid grid-cols-3 gap-5">
                    {/* CAPITAL REQUIRED */}
                    <div className="col-span-1">
                      <div className="form-group w-full">
                        <label
                          htmlFor="capitalRequire"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          Capital required
                        </label>
                        <input
                          type="number"
                          className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="₹ 50,000"
                          name="capitalRequire"
                          id="capitalRequire"
                          onChange={formik.handleChange}
                          value={formik?.values?.capitalRequire}
                          defaultValue={formik?.values?.capitalRequire}
                        />
                      </div>
                    </div>

                    {/* VISIBLE ON USER PANEL */}
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          htmlFor="status"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          Visible on user panel
                        </label>
                        <Select
                          options={visible}
                          className="react-select mt-1"
                          classNamePrefix="dropdown"
                          placeholder="Visible on user panel"
                          onChange={(e) => {
                            formik.setFieldValue("isVisible", e?.value);
                          }}
                          value={
                            formik.values.isVisible
                              ? { value: true, label: "YES" }
                              : { value: false, label: "NO" }
                          }
                        />
                      </div>
                    </div>

                    {/* MAXIMUM NO OF ENTRIES AT THE MOMENT */}
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          htmlFor="maximumEntriesAtMoment"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          Max. no. of entries at a moment
                        </label>
                        <input
                          type="number"
                          name="maximumEntriesAtMoment"
                          id="maximumEntriesAtMoment"
                          className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="Max entries per stock"
                          defaultValue="20"
                          value={formik.values?.maximumEntriesAtMoment}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  {/* SECOND ROW */}
                  <div className="grid grid-cols-3 gap-5 mt-5">
                    {/* TARGET */}
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          htmlFor="target"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          Target (%)
                        </label>
                        <input
                          type="number"
                          className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="1"
                          name="target"
                          id="target"
                          onChange={formik.handleChange}
                          value={formik?.values?.target}
                          defaultValue={formik?.values?.target}
                        />
                      </div>
                    </div>

                    {/* STOP LOSS */}
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          htmlFor="stopLoss"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          Stop Loss (%)
                        </label>
                        <input
                          type="number"
                          className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="1"
                          name="stopLoss"
                          id="stopLoss"
                          onChange={formik.handleChange}
                          value={formik?.values?.stopLoss}
                          defaultValue={formik?.values?.stopLoss}
                        />
                      </div>
                    </div>

                    {/* TRADED VALUE */}
                    <div className="col-span-1">
                      <div className="col-span-1">
                        <div className="form-group w-full">
                          <label
                            htmlFor="tradeValue"
                            className="text-sm font-nunito-medium tracking-tight"
                          >
                            Traded Value
                          </label>
                          <input
                            type="number"
                            className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                            placeholder="15"
                            name="tradeValue"
                            id="tradeValue"
                            onChange={formik.handleChange}
                            value={formik?.values?.tradeValue}
                            defaultValue="15000"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          htmlFor="interval"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          Interval (in minutes)
                        </label>
                        <input
                          type="number"
                          className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="1"
                          name="interval"
                          id="interval"
                          onChange={formik.handleChange}
                          value={formik?.values?.interval}
                          /* defaultValue={formik?.values?.interval} */
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* ALGO 6 - STOCK BUY */}
              {formik?.values?.name.includes("6") && (
                <>
                  {/* FIRST ROW */}
                  <div className="grid grid-cols-3 gap-5">
                    {/* CAPITAL REQUIRED */}
                    <div className="col-span-1">
                      <div className="form-group w-full">
                        <label
                          htmlFor="capitalRequire"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          Capital required
                        </label>
                        <input
                          type="number"
                          className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="₹ 50,000"
                          name="capitalRequire"
                          id="capitalRequire"
                          onChange={formik.handleChange}
                          value={formik?.values?.capitalRequire}
                          defaultValue={formik?.values?.capitalRequire}
                        />
                      </div>
                    </div>

                    {/* VISIBLE ON USER PANEL */}
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          htmlFor="status"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          Visible on user panel
                        </label>
                        <Select
                          options={visible}
                          className="react-select mt-1"
                          classNamePrefix="dropdown"
                          placeholder="Visible on user panel"
                          onChange={(e) => {
                            formik.setFieldValue("isVisible", e?.value);
                          }}
                          value={
                            formik.values.isVisible
                              ? { value: true, label: "YES" }
                              : { value: false, label: "NO" }
                          }
                        />
                      </div>
                    </div>

                    {/* MAXIMUM NO OF ENTRIES AT THE MOMENT */}
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          htmlFor="maximumEntriesAtMoment"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          Max. no. of entries at a moment
                        </label>
                        <input
                          type="number"
                          name="maximumEntriesAtMoment"
                          id="maximumEntriesAtMoment"
                          className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="Max entries per stock"
                          defaultValue="20"
                          value={formik.values?.maximumEntriesAtMoment}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  {/* THIRD ROW */}
                  <div className="grid grid-cols-3 gap-5 mt-5">
                    {/* TARGET */}
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          htmlFor="target"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          Target (%)
                        </label>
                        <input
                          type="number"
                          className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="1"
                          name="target"
                          id="target"
                          onChange={formik.handleChange}
                          value={formik?.values?.target}
                          defaultValue={formik?.values?.target}
                        />
                      </div>
                    </div>

                    {/* STOP LOSS */}
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          htmlFor="stopLoss"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          Stop Loss (%)
                        </label>
                        <input
                          type="number"
                          className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="1"
                          name="stopLoss"
                          id="stopLoss"
                          onChange={formik.handleChange}
                          value={formik?.values?.stopLoss}
                          defaultValue={formik?.values?.stopLoss}
                        />
                      </div>
                    </div>

                    {/* TRADED VALUE */}
                    <div className="col-span-1">
                      <div className="col-span-1">
                        <div className="form-group w-full">
                          <label
                            htmlFor="tradeValue"
                            className="text-sm font-nunito-medium tracking-tight"
                          >
                            Traded Value
                          </label>
                          <input
                            type="number"
                            className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                            placeholder="15"
                            name="tradeValue"
                            id="tradeValue"
                            onChange={formik.handleChange}
                            value={formik?.values?.tradeValue}
                            defaultValue="15000"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          htmlFor="interval"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          Interval (in minutes)
                        </label>
                        <input
                          type="number"
                          className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="1"
                          name="interval"
                          id="interval"
                          onChange={formik.handleChange}
                          value={formik?.values?.interval}
                          /* defaultValue={formik?.values?.interval} */
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* ALGO 3 - INTRADAY OPTIONS BUY */}
              {formik?.values?.name.includes("3") && (
                <>
                  {/* FIRST ROW */}
                  <div className="grid grid-cols-3 gap-5">
                    {/* CAPITAL REQUIRED */}
                    <div className="col-span-1">
                      <div className="form-group w-full">
                        <label
                          htmlFor="capitalRequire"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          Capital required
                        </label>
                        <input
                          type="number"
                          className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="₹ 50,000"
                          name="capitalRequire"
                          id="capitalRequire"
                          onChange={formik.handleChange}
                          value={formik?.values?.capitalRequire}
                          defaultValue={formik?.values?.capitalRequire}
                        />
                      </div>
                    </div>

                    {/* VISIBLE ON USER PANEL */}
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          htmlFor="status"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          Visible on user panel
                        </label>
                        <Select
                          options={visible}
                          className="react-select mt-1"
                          classNamePrefix="dropdown"
                          placeholder="Visible on user panel"
                          onChange={(e) => {
                            formik.setFieldValue("isVisible", e?.value);
                          }}
                          value={
                            formik.values.isVisible
                              ? { value: true, label: "YES" }
                              : { value: false, label: "NO" }
                          }
                        />
                      </div>
                    </div>

                    {/* MAXIMUM NO OF ENTRIES AT THE MOMENT */}
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          htmlFor="maximumEntriesAtMoment"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          Max. no. of entries at a moment
                        </label>
                        <input
                          type="number"
                          name="maximumEntriesAtMoment"
                          id="maximumEntriesAtMoment"
                          className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="Max entries per stock"
                          defaultValue="20"
                          value={formik.values?.maximumEntriesAtMoment}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  {/* SECOND ROW */}
                  <div className="grid grid-cols-2 gap-5 mt-5">
                    {/* START TIME */}
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          htmlFor="startTime"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          Start time
                        </label>
                        <input
                          type="time"
                          name="startTime"
                          id="startTime"
                          className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="Start Time"
                          defaultValue="10:03"
                          value={formik.values.startTime}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>

                    {/* END TIME */}
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          htmlFor="endTime"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          End time
                        </label>
                        <input
                          type="time"
                          name="endTime"
                          id="endTime"
                          className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="End Time"
                          defaultValue="14:55"
                          value={formik.values.endTime}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  {/* THIRD ROW */}
                  <div className="grid grid-cols-3 gap-5 mt-5">
                    {/* LAST ENTRY DATE */}
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          htmlFor="lastEntryDate"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          Last Entry Date
                        </label>
                        <input
                          type="date"
                          name="lastEntryDate"
                          id="lastEntryDate"
                          className="mt-1 w-full block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="Last Entry Date"
                          value={formik.values.lastEntryDate}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>

                    {/* LAST ENTRY TIME */}
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          htmlFor="lastEntryTime"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          Last Entry time
                        </label>
                        <input
                          type="time"
                          name="lastEntryTime"
                          id="lastEntryTime"
                          className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="Last Entry Time"
                          defaultValue="14:45"
                          value={formik.values.lastEntryTime}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>

                    {/* STOP LOSS */}
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          htmlFor="interval"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          Interval (in minutes)
                        </label>
                        <input
                          type="number"
                          className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="1"
                          name="interval"
                          id="interval"
                          onChange={formik.handleChange}
                          value={formik?.values?.interval}
                          /* defaultValue={formik?.values?.interval} */
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* ALGO 2 - INTRADAY OPTION SELL */}
              {formik?.values?.name.includes("2") && (
                <>
                  {/* FIRST ROW */}
                  <div className="grid grid-cols-2 gap-5">
                    {/* CAPITAL REQUIRED */}
                    <div className="col-span-1">
                      <div className="form-group w-full">
                        <label
                          htmlFor="capitalRequire"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          Capital required
                        </label>
                        <input
                          type="number"
                          className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="₹ 50,000"
                          name="capitalRequire"
                          id="capitalRequire"
                          onChange={formik.handleChange}
                          value={formik?.values?.capitalRequire}
                          defaultValue={formik?.values?.capitalRequire}
                        />
                      </div>
                    </div>

                    {/* VISIBLE ON USER PANEL */}
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          htmlFor="status"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          Visible on user panel
                        </label>
                        <Select
                          options={visible}
                          className="react-select mt-1"
                          classNamePrefix="dropdown"
                          placeholder="Visible on user panel"
                          onChange={(e) => {
                            formik.setFieldValue("isVisible", e?.value);
                          }}
                          value={
                            formik.values.isVisible
                              ? { value: true, label: "YES" }
                              : { value: false, label: "NO" }
                          }
                        />
                      </div>
                    </div>
                  </div>

                  {/* SECOND ROW */}
                  <div className="grid grid-cols-3 gap-5 mt-5">
                    {/* START TIME */}
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          htmlFor="startTime"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          Start time
                        </label>
                        <input
                          type="time"
                          name="startTime"
                          id="startTime"
                          className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="Start Time"
                          defaultValue="10:03"
                          value={formik.values.startTime}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>

                    {/* END TIME */}
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          htmlFor="endTime"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          End time
                        </label>
                        <input
                          type="time"
                          name="endTime"
                          id="endTime"
                          className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="End Time"
                          defaultValue="14:40"
                          value={formik.values.endTime}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>

                    {/* LAST ENTRY TIME */}
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          htmlFor="lastEntryTime"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          Last Entry time
                        </label>
                        <input
                          type="time"
                          name="lastEntryTime"
                          id="lastEntryTime"
                          className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="Last Entry Time"
                          defaultValue="14:45"
                          value={formik.values.lastEntryTime}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {/* ALGO 1 - PCR STRATEGY */}
              {formik?.values?.name.includes("1") && (
                <>
                  {/* FIRST ROW */}
                  <div className="grid grid-cols-3 gap-5">
                    {/* CAPITAL REQUIRED */}
                    <div className="col-span-1">
                      <div className="form-group w-full">
                        <label
                          htmlFor="capitalRequire"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          Capital required
                        </label>
                        <input
                          type="number"
                          className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="₹ 50,000"
                          name="capitalRequire"
                          id="capitalRequire"
                          onChange={formik.handleChange}
                          value={formik?.values?.capitalRequire}
                          defaultValue={formik?.values?.capitalRequire}
                        />
                      </div>
                    </div>

                    {/* VISIBLE ON USER PANEL */}
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          htmlFor="status"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          Visible on user panel
                        </label>
                        <Select
                          options={visible}
                          className="react-select mt-1"
                          classNamePrefix="dropdown"
                          placeholder="Visible on user panel"
                          onChange={(e) => {
                            formik.setFieldValue("isVisible", e?.value);
                          }}
                          value={
                            formik.values.isVisible
                              ? { value: true, label: "YES" }
                              : { value: false, label: "NO" }
                          }
                        />
                      </div>
                    </div>

                    {/* MAXIMUM ENTRIES PER STOCK */}
                    <div className="col-span-1">
                      <div className="form-group w-full">
                        <label
                          htmlFor="capitalRequire"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          Max. Entries per stock per day
                        </label>
                        <input
                          type="number"
                          className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="₹ 50,000"
                          name="maximumEntriesPerStockPerDay"
                          id="maximumEntriesPerStockPerDay"
                          onChange={formik.handleChange}
                          value={formik?.values?.maximumEntriesPerStockPerDay}
                          defaultValue="20"
                        />
                      </div>
                    </div>
                  </div>

                  {/* SECOND ROW */}
                  <div className="grid grid-cols-3 gap-5 mt-5">
                    {/* START TIME */}
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          htmlFor="startTime"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          Start time
                        </label>
                        <input
                          type="time"
                          name="startTime"
                          id="startTime"
                          className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="Start Time"
                          defaultValue="10:03"
                          value={formik.values.startTime}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>

                    {/* END TIME */}
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          htmlFor="endTime"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          End time
                        </label>
                        <input
                          type="time"
                          name="endTime"
                          id="endTime"
                          className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="End Time"
                          defaultValue="14:55"
                          value={formik.values.endTime}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>

                    {/* LAST ENTRY TIME */}
                    <div className="col-span-1">
                      <div className="form-group">
                        <label
                          htmlFor="lastEntryTime"
                          className="text-sm font-nunito-medium tracking-tight"
                        >
                          Last Entry time
                        </label>
                        <input
                          type="time"
                          name="lastEntryTime"
                          id="lastEntryTime"
                          className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="Last Entry Time"
                          defaultValue="14:45"
                          value={formik.values.lastEntryTime}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  {/* SCRIPTS */}
                  {selectedEditAlgoScriptsData &&
                  selectedEditAlgoScriptsData?.length > 0 ? (
                    selectedEditAlgoScriptsData?.map((el, index) => {
                      return (
                        <div
                          key={index}
                          className="w-full bg-[#F5F5F5] bg-opacity-50 border border-[#E5E5E5] rounded-md px-5 py-3 mt-5 first:mt-0"
                        >
                          <div className="grid grid-cols-7 gap-5">
                            {/* NAME */}
                            <div className="col-span-1 border-r border-[#E5E5E5]">
                              <h1 className="text-lg font-nunito-bold text-textBlack tracking-tight">
                                Script {index + 1}
                              </h1>
                              <p className="text-base font-nunito-medium text-black">
                                ({el?.name})
                              </p>
                            </div>

                            <div className="col-span-6 block">
                              {el?.scriptDetails?.map(
                                (element, insideIndex) => {
                                  return (
                                    <div className="grid grid-cols-4 gap-5 pb-3 pt-1">
                                      {/* STATUS */}
                                      {(selectedEditAlgoScriptsData?.[index]
                                        ?.scriptDetails?.[insideIndex]
                                        ?.status === true ||
                                        selectedEditAlgoScriptsData?.[index]
                                          ?.scriptDetails?.[insideIndex]
                                          ?.status === false) && (
                                        <div className="form-group w-full">
                                          <label
                                            htmlFor="tradeValue"
                                            className="text-sm font-nunito-medium tracking-tight"
                                          >
                                            Status
                                          </label>
                                          <Select
                                            options={[
                                              { value: true, label: "Enable" },
                                              {
                                                value: false,
                                                label: "Disable",
                                              },
                                            ]}
                                            value={
                                              element?.status
                                                ? {
                                                    value: true,
                                                    label: "Enable",
                                                  }
                                                : {
                                                    value: false,
                                                    label: "Disable",
                                                  }
                                            }
                                            onChange={(e) => {
                                              let currentValue =
                                                formik.values.scripts;
                                              currentValue[index].scriptDetails[
                                                insideIndex
                                              ].status = e?.value;
                                              formik.setFieldValue(
                                                "scripts",
                                                currentValue
                                              );
                                            }}
                                            className="react-select"
                                            classNamePrefix="dropdown"
                                            placeholder="Script status"
                                          />
                                        </div>
                                      )}

                                      {/* NUMBER OF LOTS */}
                                      {(selectedEditAlgoScriptsData?.[index]
                                        ?.scriptDetails?.[insideIndex]
                                        ?.numberOfLots === "" ||
                                        selectedEditAlgoScriptsData?.[index]
                                          ?.scriptDetails?.[insideIndex]
                                          ?.numberOfLots !== null) && (
                                        <div className="form-group w-full">
                                          <label
                                            htmlFor="tradeValue"
                                            className="text-sm font-nunito-medium tracking-tight"
                                          >
                                            No. Of Lots
                                          </label>
                                          <input
                                            type="number"
                                            name="bnf-lots"
                                            id="bnf-lots"
                                            defaultValue="1"
                                            value={element?.numberOfLots}
                                            onChange={(e) => {
                                              let currentValue =
                                                formik.values.scripts;
                                              currentValue[index].scriptDetails[
                                                insideIndex
                                              ].numberOfLots = e?.target?.value;
                                              formik.setFieldValue(
                                                "scripts",
                                                currentValue
                                              );
                                            }}
                                            className="w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                                            placeholder="No. of lots"
                                          />
                                        </div>
                                      )}

                                      {/* STRIKE PARAMETER */}
                                      {(selectedEditAlgoScriptsData?.[index]
                                        ?.scriptDetails?.[insideIndex]
                                        ?.strikeParameter === "" ||
                                        selectedEditAlgoScriptsData?.[index]
                                          ?.scriptDetails?.[insideIndex]
                                          ?.strikeParameter !== null) && (
                                        <div className="form-group w-full">
                                          <label
                                            htmlFor="tradeValue"
                                            className="text-sm font-nunito-medium tracking-tight whitespace-nowrap overflow-hidden"
                                          >
                                            Strike Parameter
                                          </label>
                                          <Select
                                            options={strikeParameter}
                                            className="react-select"
                                            classNamePrefix="dropdown"
                                            placeholder="Strike Selection"
                                            onChange={(e) => {
                                              let currentValue =
                                                formik.values.scripts;
                                              currentValue[index].scriptDetails[
                                                insideIndex
                                              ].strikeParameter = e?.value;
                                              formik.setFieldValue(
                                                "scripts",
                                                currentValue
                                              );
                                            }}
                                            value={strikeParameter?.find(
                                              (individualElement) =>
                                                individualElement?.value ===
                                                element?.strikeParameter
                                            )}
                                          />
                                        </div>
                                      )}

                                      {/* VALUE */}
                                      {(selectedEditAlgoScriptsData?.[index]
                                        ?.scriptDetails?.[insideIndex]
                                        ?.value === "" ||
                                        selectedEditAlgoScriptsData?.[index]
                                          ?.scriptDetails?.[insideIndex]
                                          ?.value !== null) && (
                                        <div className="form-group w-full">
                                          <label
                                            htmlFor="tradeValue"
                                            className="text-sm font-nunito-medium tracking-tight"
                                          >
                                            Value
                                          </label>
                                          <input
                                            type="number"
                                            name="bnf-value"
                                            id="bnf-value"
                                            defaultValue="400"
                                            className=" w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                                            placeholder="Value"
                                            value={element?.value}
                                            onChange={(e) => {
                                              let currentValue =
                                                formik.values.scripts;
                                              currentValue[index].scriptDetails[
                                                insideIndex
                                              ].value = e?.target?.value;
                                              formik.setFieldValue(
                                                "scripts",
                                                currentValue
                                              );
                                            }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="pt-4 hidden">No Scripts found</p>
                  )}
                </>
              )}
            </div>

            {/* SAVE BUTTON */}
            <div className="border-t border-cardBorder px-5 py-3 flex items-center justify-end">
              <Button
                type="submit"
                disabled={formLoading}
                className="bg-header hover:bg-opacity-80 border border-header flex items-center gap-2 shadow-none hover:shadow-none text-white text-sm font-nunito-medium tracking-tight normal-case px-4 py-2 font-medium transition-all duration-300 ease-in-out rounded"
              >
                {formLoading ? "Please wait" : "Save details"}
              </Button>
            </div>
          </form>
        </DialogBody>
      </Dialog>

      {/* PCR STRATEGY */}
      <Dialog
        open={pcrStrategy}
        handler={() => setPCRStrategy(!pcrStrategy)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="lg"
      >
        <DialogBody className="p-0">
          <div className="flex items-center justify-between px-5 py-3 border-b border-cardBorder">
            <h5 className="text-textBlack font-nunito-bold text-lg tracking-tight">
              PCR Strategy
            </h5>
            <Button
              className="p-2 rounded-full shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5 bg-transparent"
              onClick={() => {
                setPCRStrategy(!pcrStrategy);
              }}
            >
              <IoClose className="w-5 h-5 text-greyText" />
            </Button>
          </div>
          <div className="p-5">
            <div className="grid grid-cols-3 gap-5">
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="capitalRequire"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Capital required
                  </label>
                  <input
                    type="number"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="₹ 50,000"
                    name="capitalRequire"
                    id="capitalRequire"
                    onChange={formik.handleChange}
                    value={formik?.values?.capitalRequire}
                    defaultValue={formik?.values?.capitalRequire}
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="status"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Visible on user panel
                  </label>
                  <Select
                    options={visible}
                    className="react-select mt-1"
                    classNamePrefix="dropdown"
                    placeholder="Visible on user panel"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="entryPerStock"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Max Entries per stock
                  </label>
                  <input
                    type="number"
                    name="entryPerStock"
                    id="entryPerStock"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Max entries per stock"
                    defaultValue="20"
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-4 gap-5 mt-5">
              <div className="form-group">
                <label
                  htmlFor="startTime"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  Start time
                </label>
                <input
                  type="time"
                  name="startTime"
                  id="startTime"
                  className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="Start Time"
                  defaultValue="10:15"
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="endTime"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  End time
                </label>
                <input
                  type="time"
                  name="endTime"
                  id="endTime"
                  className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="End Time"
                  defaultValue="14:55"
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="lastEntryTime"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  Last Entry Time
                </label>
                <input
                  type="time"
                  name="lastEntryTime"
                  id="lastEntryTime"
                  className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="Last Entry Time"
                  defaultValue="14:45"
                />
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="status"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Select Expiry
                  </label>
                  <Select
                    options={expiryOptions}
                    className="react-select mt-1"
                    classNamePrefix="dropdown"
                    placeholder="Select expiry"
                  />
                </div>
              </div>
            </div>

            {/* SCRIPT 1 */}
            <div className="w-full bg-[#F5F5F5] bg-opacity-50 border border-[#E5E5E5] rounded-md px-5 py-3 mt-5 first:mt-0">
              <div className="grid grid-cols-5 gap-5 items-center">
                <div className="col-span-1">
                  <h5 className="text-base font-nunito-bold text-black">
                    BankNifty
                  </h5>
                </div>
                <div className="col-span-1">
                  <div className="form-group">
                    <Select
                      options={[
                        { value: true, label: "Enable" },
                        { value: false, label: "Disable" },
                      ]}
                      className="react-select"
                      classNamePrefix="dropdown"
                      placeholder="Script status"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="bnf-lots"
                      id="bnf-lots"
                      defaultValue="1"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="No. of lots"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <Select
                      options={strikeParameter}
                      className="react-select mt-1"
                      classNamePrefix="dropdown"
                      placeholder="Strike Selection"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="bnf-value"
                      id="bnf-value"
                      defaultValue="400"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="Value"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* SCRIPT 2 */}
            <div className="w-full bg-[#F5F5F5] bg-opacity-50 border border-[#E5E5E5] rounded-md px-5 py-3 mt-5 first:mt-0">
              <div className="grid grid-cols-5 gap-5 items-center">
                <div className="col-span-1">
                  <h5 className="text-base font-nunito-bold text-black">
                    Nifty
                  </h5>
                </div>
                <div className="col-span-1">
                  <div className="form-group">
                    <Select
                      options={[
                        { value: true, label: "Enable" },
                        { value: false, label: "Disable" },
                      ]}
                      className="react-select"
                      classNamePrefix="dropdown"
                      placeholder="Script status"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="nifty-lots"
                      id="nifty-lots"
                      defaultValue="1"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="No. of lots"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <Select
                      options={strikeParameter}
                      className="react-select mt-1"
                      classNamePrefix="dropdown"
                      placeholder="Strike Selection"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="nifty-value"
                      id="nifty-value"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="Value"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* SCRIPT 3 */}
            <div className="w-full bg-[#F5F5F5] bg-opacity-50 border border-[#E5E5E5] rounded-md px-5 py-3 mt-5 first:mt-0">
              <div className="grid grid-cols-5 gap-5 items-center">
                <div className="col-span-1">
                  <h5 className="text-base font-nunito-bold text-black">
                    FinNifty
                  </h5>
                </div>
                <div className="col-span-1">
                  <div className="form-group">
                    <Select
                      options={[
                        { value: true, label: "Enable" },
                        { value: false, label: "Disable" },
                      ]}
                      className="react-select"
                      classNamePrefix="dropdown"
                      placeholder="Script status"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="fin-lots"
                      id="fin-lots"
                      defaultValue="1"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="No. of lots"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <Select
                      options={strikeParameter}
                      className="react-select mt-1"
                      classNamePrefix="dropdown"
                      placeholder="Strike Selection"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="fin-value"
                      id="fin-value"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="Value"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* SCRIPT 4 */}
            <div className="w-full bg-[#F5F5F5] bg-opacity-50 border border-[#E5E5E5] rounded-md px-5 py-3 mt-5 first:mt-0">
              <div className="grid grid-cols-5 gap-5 items-center">
                <div className="col-span-1">
                  <h5 className="text-base font-nunito-bold text-black">
                    Midcap Nifty
                  </h5>
                </div>
                <div className="col-span-1">
                  <div className="form-group">
                    <Select
                      options={[
                        { value: true, label: "Enable" },
                        { value: false, label: "Disable" },
                      ]}
                      className="react-select"
                      classNamePrefix="dropdown"
                      placeholder="Script status"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="midcap-lots"
                      id="midcap-lots"
                      defaultValue="1"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="No. of lots"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <Select
                      options={strikeParameter}
                      className="react-select mt-1"
                      classNamePrefix="dropdown"
                      placeholder="Strike Selection"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="midcap-value"
                      id="midcap-value"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="Value"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-t border-cardBorder px-5 py-3 flex items-center justify-end">
            <Button className="bg-header hover:bg-opacity-80 border border-header flex items-center gap-2 shadow-none hover:shadow-none text-white text-sm font-nunito-medium tracking-tight normal-case px-4 py-2 font-medium transition-all duration-300 ease-in-out rounded">
              Save details
            </Button>
          </div>
        </DialogBody>
      </Dialog>

      {/* INTRADAY OPTION SELL */}
      <Dialog
        open={intradayOptionSell}
        handler={() => setIntradayOptionSell(!intradayOptionSell)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="lg"
      >
        <DialogBody className="p-0">
          <div className="flex items-center justify-between px-5 py-3 border-b border-cardBorder">
            <h5 className="text-textBlack font-nunito-bold text-lg tracking-tight">
              Intraday Option Sell Strategy
            </h5>
            <Button
              className="p-2 rounded-full shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5 bg-transparent"
              onClick={() => {
                setIntradayOptionSell(!intradayOptionSell);
              }}
            >
              <IoClose className="w-5 h-5 text-greyText" />
            </Button>
          </div>
          <div className="p-5">
            <div className="grid grid-cols-3 gap-5">
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="capitalRequire"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Capital required
                  </label>
                  <input
                    type="number"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="₹ 50,000"
                    name="capitalRequire"
                    id="capitalRequire"
                    onChange={formik.handleChange}
                    value={formik?.values?.capitalRequire}
                    defaultValue={formik?.values?.capitalRequire}
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="status"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Visible on user panel
                  </label>
                  <Select
                    options={visible}
                    className="react-select mt-1"
                    classNamePrefix="dropdown"
                    placeholder="Visible on user panel"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="status"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Select Expiry
                  </label>
                  <Select
                    options={expiryOptions}
                    className="react-select mt-1"
                    classNamePrefix="dropdown"
                    placeholder="Select expiry"
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-5 mt-5">
              <div className="form-group">
                <label
                  htmlFor="startTime"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  Start time
                </label>
                <input
                  type="time"
                  name="startTime"
                  id="startTime"
                  className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="Start Time"
                  defaultValue="09:25"
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="endTime"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  End time
                </label>
                <input
                  type="time"
                  name="endTime"
                  id="endTime"
                  className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="End Time"
                  defaultValue="14:40"
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="lastEntryTime"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  Last Entry Time
                </label>
                <input
                  type="time"
                  name="lastEntryTime"
                  id="lastEntryTime"
                  className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="Last Entry Time"
                  defaultValue="14:35"
                />
              </div>
            </div>

            {/* SCRIPT 1 */}
            <div className="w-full bg-[#F5F5F5] bg-opacity-50 border border-[#E5E5E5] rounded-md px-5 py-3 mt-5 first:mt-0">
              <div className="grid grid-cols-5 gap-5 items-center">
                <div className="col-span-1">
                  <h5 className="text-base font-nunito-bold text-black">
                    BankNifty
                  </h5>
                </div>
                <div className="col-span-1">
                  <div className="form-group">
                    <Select
                      options={[
                        { value: true, label: "Enable" },
                        { value: false, label: "Disable" },
                      ]}
                      className="react-select"
                      classNamePrefix="dropdown"
                      placeholder="Script status"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="bnf-lots"
                      id="bnf-lots"
                      defaultValue="1"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="No. of lots"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <Select
                      options={strikeParameter}
                      className="react-select mt-1"
                      classNamePrefix="dropdown"
                      placeholder="Strike Selection"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="bnf-value"
                      id="bnf-value"
                      defaultValue="400"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="Value"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* SCRIPT 2 */}
            <div className="w-full bg-[#F5F5F5] bg-opacity-50 border border-[#E5E5E5] rounded-md px-5 py-3 mt-5 first:mt-0">
              <div className="grid grid-cols-5 gap-5 items-center">
                <div className="col-span-1">
                  <h5 className="text-base font-nunito-bold text-black">
                    Nifty
                  </h5>
                </div>
                <div className="col-span-1">
                  <div className="form-group">
                    <Select
                      options={[
                        { value: true, label: "Enable" },
                        { value: false, label: "Disable" },
                      ]}
                      className="react-select"
                      classNamePrefix="dropdown"
                      placeholder="Script status"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="nifty-lots"
                      id="nifty-lots"
                      defaultValue="1"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="No. of lots"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <Select
                      options={strikeParameter}
                      className="react-select mt-1"
                      classNamePrefix="dropdown"
                      placeholder="Strike Selection"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="nifty-value"
                      id="nifty-value"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="Value"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* SCRIPT 3 */}
            <div className="w-full bg-[#F5F5F5] bg-opacity-50 border border-[#E5E5E5] rounded-md px-5 py-3 mt-5 first:mt-0">
              <div className="grid grid-cols-5 gap-5 items-center">
                <div className="col-span-1">
                  <h5 className="text-base font-nunito-bold text-black">
                    FinNifty
                  </h5>
                </div>
                <div className="col-span-1">
                  <div className="form-group">
                    <Select
                      options={[
                        { value: true, label: "Enable" },
                        { value: false, label: "Disable" },
                      ]}
                      className="react-select"
                      classNamePrefix="dropdown"
                      placeholder="Script status"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="fin-lots"
                      id="fin-lots"
                      defaultValue="1"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="No. of lots"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <Select
                      options={strikeParameter}
                      className="react-select mt-1"
                      classNamePrefix="dropdown"
                      placeholder="Strike Selection"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="fin-value"
                      id="fin-value"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="Value"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* SCRIPT 4 */}
            <div className="w-full bg-[#F5F5F5] bg-opacity-50 border border-[#E5E5E5] rounded-md px-5 py-3 mt-5 first:mt-0">
              <div className="grid grid-cols-5 gap-5 items-center">
                <div className="col-span-1">
                  <h5 className="text-base font-nunito-bold text-black">
                    Midcap Nifty
                  </h5>
                </div>
                <div className="col-span-1">
                  <div className="form-group">
                    <Select
                      options={[
                        { value: true, label: "Enable" },
                        { value: false, label: "Disable" },
                      ]}
                      className="react-select"
                      classNamePrefix="dropdown"
                      placeholder="Script status"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="midcap-lots"
                      id="midcap-lots"
                      defaultValue="1"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="No. of lots"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <Select
                      options={strikeParameter}
                      className="react-select mt-1"
                      classNamePrefix="dropdown"
                      placeholder="Strike Selection"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="midcap-value"
                      id="midcap-value"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="Value"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-t border-cardBorder px-5 py-3 flex items-center justify-end">
            <Button className="bg-header hover:bg-opacity-80 border border-header flex items-center gap-2 shadow-none hover:shadow-none text-white text-sm font-nunito-medium tracking-tight normal-case px-4 py-2 font-medium transition-all duration-300 ease-in-out rounded">
              Save details
            </Button>
          </div>
        </DialogBody>
      </Dialog>

      {/* INTRADAY OPTION BUY */}
      <Dialog
        open={intradayOptionBuy}
        handler={() => setIntradayOptionBuy(!intradayOptionBuy)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="lg"
      >
        <DialogBody className="p-0">
          <div className="flex items-center justify-between px-5 py-3 border-b border-cardBorder">
            <h5 className="text-textBlack font-nunito-bold text-lg tracking-tight">
              Intraday Option Buy Strategy
            </h5>
            <Button
              className="p-2 rounded-full shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5 bg-transparent"
              onClick={() => {
                setIntradayOptionBuy(!intradayOptionBuy);
              }}
            >
              <IoClose className="w-5 h-5 text-greyText" />
            </Button>
          </div>
          <div className="p-5">
            <div className="grid grid-cols-3 gap-5">
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="capitalRequire"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Capital required
                  </label>
                  <input
                    type="number"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="₹ 50,000"
                    name="capitalRequire"
                    id="capitalRequire"
                    onChange={formik.handleChange}
                    value={formik?.values?.capitalRequire}
                    defaultValue={formik?.values?.capitalRequire}
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="status"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Visible on user panel
                  </label>
                  <Select
                    options={visible}
                    className="react-select mt-1"
                    classNamePrefix="dropdown"
                    placeholder="Visible on user panel"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="entryPerStock"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Maximum No. of entries per stock
                  </label>
                  <input
                    type="number"
                    name="entryPerStock"
                    id="entryPerStock"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Max entries per stock"
                    defaultValue="5"
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-5 mt-5">
              <div className="form-group">
                <label
                  htmlFor="startTime"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  Start time
                </label>
                <input
                  type="time"
                  name="startTime"
                  id="startTime"
                  className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="Start Time"
                  defaultValue="10:03"
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="endTime"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  End time
                </label>
                <input
                  type="time"
                  name="endTime"
                  id="endTime"
                  className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="End Time"
                  defaultValue="14:55"
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="lastEntryTime"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  Last Entry Time
                </label>
                <input
                  type="time"
                  name="lastEntryTime"
                  id="lastEntryTime"
                  className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="Last Entry Time"
                  defaultValue="14:45"
                />
              </div>
            </div>
            <div className="grid grid-cols-3 gap-5 mt-5">
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="target"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Target
                  </label>
                  <input
                    type="number"
                    name="target"
                    id="target"
                    defaultValue="1"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Target"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="stopLoss"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Stop Loss (%)
                  </label>
                  <input
                    type="number"
                    name="stopLoss"
                    id="stopLoss"
                    defaultValue="1"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Stop Loss"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="lastEntryDate"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Last Entry Date
                  </label>
                  <input
                    type="date"
                    name="lastEntryDate"
                    id="lastEntryDate"
                    defaultValue="1"
                    className="mt-1 w-full block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Stop Loss"
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-5 mt-5">
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="maxMovement"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Maximum Movement(%)
                  </label>
                  <input
                    type="number"
                    name="maxMovement"
                    id="maxMovement"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Maximum Movement"
                    defaultValue="3.65"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="minMovement"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Minimum Movement(%)
                  </label>
                  <input
                    type="number"
                    name="minMovement"
                    id="minMovement"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Minimum Movement"
                    defaultValue="2"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="status"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Select Expiry
                  </label>
                  <Select
                    options={expiryOptions}
                    className="react-select mt-1"
                    classNamePrefix="dropdown"
                    placeholder="Select expiry"
                  />
                </div>
              </div>
            </div>

            {/* SCRIPT 1 */}
            <div className="w-full bg-[#F5F5F5] bg-opacity-50 border border-[#E5E5E5] rounded-md px-5 py-3 mt-5 first:mt-0">
              <div className="grid grid-cols-5 gap-5 items-center">
                <div className="col-span-1">
                  <h5 className="text-base font-nunito-bold text-black">
                    Script 1
                  </h5>
                </div>
                <div className="col-span-1">
                  <div className="form-group">
                    <Select
                      options={[
                        { value: true, label: "Enable" },
                        { value: false, label: "Disable" },
                      ]}
                      className="react-select"
                      classNamePrefix="dropdown"
                      placeholder="Script status"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="bnf-lots"
                      id="bnf-lots"
                      defaultValue="1"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="No. of lots"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <Select
                      options={strikeParameter}
                      className="react-select mt-1"
                      classNamePrefix="dropdown"
                      placeholder="Strike Selection"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="bnf-value"
                      id="bnf-value"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="Value"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* SCRIPT 2 */}
            <div className="w-full bg-[#F5F5F5] bg-opacity-50 border border-[#E5E5E5] rounded-md px-5 py-3 mt-5 first:mt-0">
              <div className="grid grid-cols-5 gap-5 items-center">
                <div className="col-span-1">
                  <h5 className="text-base font-nunito-bold text-black">
                    Script 2
                  </h5>
                </div>
                <div className="col-span-1">
                  <div className="form-group">
                    <Select
                      options={[
                        { value: true, label: "Enable" },
                        { value: false, label: "Disable" },
                      ]}
                      className="react-select"
                      classNamePrefix="dropdown"
                      placeholder="Script status"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="nifty-lots"
                      id="nifty-lots"
                      defaultValue="1"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="No. of lots"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <Select
                      options={strikeParameter}
                      className="react-select mt-1"
                      classNamePrefix="dropdown"
                      placeholder="Strike Selection"
                    />
                  </div>
                </div>
                <div className="col-span-1">
                  <div className="form-group w-full">
                    <input
                      type="number"
                      name="nifty-value"
                      id="nifty-value"
                      className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="Value"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogBody>
      </Dialog>

      {/* INTRADAY STOCK BUY */}
      <Dialog
        open={intradayStockBuy}
        handler={() => setIntradayStockBuy(!intradayStockBuy)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size={formik?.values?.name.includes("1") ? "xl" : "md"}
      >
        <DialogBody className="p-0">
          <div className="flex items-center justify-between px-5 py-3 border-b border-cardBorder">
            <h5 className="text-textBlack font-nunito-bold text-lg tracking-tight">
              Intraday Stock Buy
            </h5>
            <Button
              className="p-2 rounded-full shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5 bg-transparent"
              onClick={() => {
                setIntradayStockBuy(!intradayStockBuy);
              }}
            >
              <IoClose className="w-5 h-5 text-greyText" />
            </Button>
          </div>

          <div className="p-5">
            <div className="grid grid-cols-3 gap-5">
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="capitalRequire"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Capital required
                  </label>
                  <input
                    type="number"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="₹ 50,000"
                    name="capitalRequire"
                    id="capitalRequire"
                    onChange={formik.handleChange}
                    value={parseFloat(
                      formik?.values?.capitalRequire
                    ).toLocaleString("en-IN")}
                    defaultValue={formik?.values?.capitalRequire}
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="status"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Visible on user panel
                  </label>
                  <Select
                    options={visible}
                    className="react-select mt-1"
                    classNamePrefix="dropdown"
                    placeholder="Visible on user panel"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="entryPerStock"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Maximum No. of entries per stock
                  </label>
                  <input
                    type="number"
                    name="entryPerStock"
                    id="entryPerStock"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Max entries per stock"
                    defaultValue="5"
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-5 mt-5">
              <div className="form-group">
                <label
                  htmlFor="startTime"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  Start time
                </label>
                <input
                  type="time"
                  name="startTime"
                  id="startTime"
                  className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="Start Time"
                  defaultValue="10:03"
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="endTime"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  End time
                </label>
                <input
                  type="time"
                  name="endTime"
                  id="endTime"
                  className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="End Time"
                  defaultValue="14:55"
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="lastEntryTime"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  Last Entry Time
                </label>
                <input
                  type="time"
                  name="lastEntryTime"
                  id="lastEntryTime"
                  className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="Last Entry Time"
                  defaultValue="14:45"
                />
              </div>
            </div>
            <div className="grid grid-cols-3 gap-5 mt-5">
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="target"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Target
                  </label>
                  <input
                    type="number"
                    name="target"
                    id="target"
                    defaultValue="1"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Target"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="stopLoss"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Stop Loss (%)
                  </label>
                  <input
                    type="number"
                    name="stopLoss"
                    id="stopLoss"
                    defaultValue="1"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Stop Loss"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="tradedValue"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Traded value
                  </label>
                  <input
                    type="number"
                    name="tradedValue"
                    id="tradedValue"
                    defaultValue="15000"
                    className="mt-1 w-full block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Traded Value"
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-5 mt-5">
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="maxMovement"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Maximum Movement(%)
                  </label>
                  <input
                    type="number"
                    name="maxMovement"
                    id="maxMovement"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Maximum Movement"
                    defaultValue="7"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="minMovement"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Minimum Movement(%)
                  </label>
                  <input
                    type="number"
                    name="minMovement"
                    id="minMovement"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Minimum Movement"
                    defaultValue="3.5"
                  />
                </div>
              </div>
            </div>
          </div>
        </DialogBody>
      </Dialog>

      {/*  STOCK BUY */}
      <Dialog
        open={stockBuy}
        handler={() => setStockBuy(!stockBuy)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size={formik?.values?.name.includes("1") ? "xl" : "md"}
      >
        <DialogBody className="p-0">
          <div className="flex items-center justify-between px-5 py-3 border-b border-cardBorder">
            <h5 className="text-textBlack font-nunito-bold text-lg tracking-tight">
              Stock Buy
            </h5>
            <Button
              className="p-2 rounded-full shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5 bg-transparent"
              onClick={() => {
                setStockBuy(!stockBuy);
              }}
            >
              <IoClose className="w-5 h-5 text-greyText" />
            </Button>
          </div>

          <div className="p-5">
            <div className="grid grid-cols-3 gap-5">
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="capitalRequire"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Capital required
                  </label>
                  <input
                    type="number"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="₹ 50,000"
                    name="capitalRequire"
                    id="capitalRequire"
                    onChange={formik.handleChange}
                    value={parseFloat(
                      formik?.values?.capitalRequire
                    ).toLocaleString("en-IN")}
                    defaultValue={formik?.values?.capitalRequire}
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="status"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Visible on user panel
                  </label>
                  <Select
                    options={visible}
                    className="react-select mt-1"
                    classNamePrefix="dropdown"
                    placeholder="Visible on user panel"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="entryPerStock"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Maximum No. of entries per stock
                  </label>
                  <input
                    type="number"
                    name="entryPerStock"
                    id="entryPerStock"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Max entries per stock"
                    defaultValue="5"
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-5 mt-5">
              <div className="form-group">
                <label
                  htmlFor="startTime"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  Start time
                </label>
                <input
                  type="time"
                  name="startTime"
                  id="startTime"
                  className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="Start Time"
                  defaultValue="10:03"
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="endTime"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  End time
                </label>
                <input
                  type="time"
                  name="endTime"
                  id="endTime"
                  className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="End Time"
                  defaultValue="14:55"
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="lastEntryTime"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  Last Entry Time
                </label>
                <input
                  type="time"
                  name="lastEntryTime"
                  id="lastEntryTime"
                  className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="Last Entry Time"
                  defaultValue="14:45"
                />
              </div>
            </div>
            <div className="grid grid-cols-3 gap-5 mt-5">
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="target"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Target
                  </label>
                  <input
                    type="number"
                    name="target"
                    id="target"
                    defaultValue="1"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Target"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="stopLoss"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Stop Loss (%)
                  </label>
                  <input
                    type="number"
                    name="stopLoss"
                    id="stopLoss"
                    defaultValue="1"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Stop Loss"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="tradedValue"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Traded value
                  </label>
                  <input
                    type="number"
                    name="tradedValue"
                    id="tradedValue"
                    defaultValue="15000"
                    className="mt-1 w-full block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Traded Value"
                  />
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-5 mt-5">
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="maxMovement"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Maximum Movement(%)
                  </label>
                  <input
                    type="number"
                    name="maxMovement"
                    id="maxMovement"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Maximum Movement"
                    defaultValue="7"
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="minMovement"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Minimum Movement(%)
                  </label>
                  <input
                    type="number"
                    name="minMovement"
                    id="minMovement"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Minimum Movement"
                    defaultValue="3.5"
                  />
                </div>
              </div>
            </div>
          </div>
        </DialogBody>
      </Dialog>
    </section>
  );
};

export default AlgoSettings;
