import { useState, useEffect } from "react";

/* MATERIAL TAILWLIND COMPONENTS */
import { Card, CardBody, Button, Spinner } from "@material-tailwind/react";

/* ASSETS */
import DynamicDataTable from "../../components/dynamicDatatable";

/* DATA */
import { getTransactionsAPI, getUserTransactionGraph, userUpdate } from "../../service/api";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import actions from "../../redux/user/actions";

/* CHARTS */
import {
  NetProfitLossGraphLabels,
  NetDailyBarGraph,
} from "../../components/chart";

const ClientPerformance = () => {
  const { invitedUser } = useSelector((state) => state.user);
  
  console.log("%c Line:57 🥃 invitedUser", "color:#3f7cff", invitedUser);

  const dispatch = useDispatch();
  const [item, setItem] = useState({
    i1: invitedUser?.strategy?.algo1Contract,
    i2: invitedUser?.strategy?.algo2Contract,
    i3: invitedUser?.strategy?.algo3Contract,
    i4: invitedUser?.strategy?.algo4Contract,
    i5: invitedUser?.strategy?.algo5Contract,
    i6: invitedUser?.strategy?.algo6Contract,
    isDisable: "",
    capitalDeployed: "",
  });
  const [loading, setLoading] = useState(false);
  const [totalPL, setTotalPL] = useState([]);
  const [totalTransactions, setTotalTransactions] = useState([]);
  const [netProfitLossGraphLabelsData, setNetProfitLossGraphLabelsData] =
    useState([]);
  const [netDailyBarGraphData, setNetDailyBarGraphData] = useState([]);

  const handleUser = () => {
    if (item?.i1 === undefined || item?.i1 === null) {
      return toast.error("Intraday Stock Buy required");
    }

    if (item?.i2 === undefined || item?.i2 === null) {
      return toast.error("Intraday Option Buy required");
    }
    if (item?.i3 === undefined || item?.i3 === null) {
      return toast.error("Positional Optional Buy required");
    }
    if (item?.i4 === undefined || item?.i4 === null) {
      return toast.error("Intraday Optional Sell required");
    }
    if (item?.i5 === undefined || item?.i5 === null) {
      return toast.error("Positional Optional Buy required");
    }
    if (item?.i6 === undefined || item?.i6 === null) {
      return toast.error("Stock Buy required");
    }
    let body = {
      algo1Contract: item?.i1,
      algo2Contract: item?.i2,
      algo3Contract: item?.i3,
      algo4Contract: item?.i4,
      algo5Contract: item?.i5,
      algo6Contract: item?.i6,

      // isDisable: item?.isDisable?.value==="Active"?true:false,
    };
    if (item?.capitalDeployed) {
      body.totalCapital = item?.capitalDeployed;
    }
    setLoading(true);
    userUpdate(invitedUser?._id, body)
      .then((res) => {
        console.log(res);
        if (res?.data?.status) {
          setLoading(false);
          toast.success(res?.data?.message);
          dispatch(actions.setInvitedUser(res?.data?.data));
        } else {
          setLoading(false);
          toast.success(res?.data?.message);
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  /* GET TRANSACTIONS FUNCTION */
  const fetchTransactionsDataFunction = () => {

    getTransactionsAPI({ pagination: false, isOpen: false, user: invitedUser?._id })
      .then((res) => {
        console.log("res==>", res?.data?.data);
        // setTransactionData(res?.data?.data?.docs)
        // setTotalDocs(res?.data?.data?.totalDocs)
        // setTotalPages(res?.data?.data?.totalPages)
        setTotalTransactions(res?.data?.data)
        let cumulativeCount = [
          { algo: 1, count: 0 },
          { algo: 2, count: 0 },
          { algo: 3, count: 0 },
          { algo: 4, count: 0 },
          { algo: 5, count: 0 },
          { algo: 6, count: 0 }
        ];
        let totalPLCombined = 0;
        res?.data?.data?.forEach((ele) => {

          let count = cumulativeCount?.find((e) => e?.algo === ele?.algo)?.count;

          if (ele.entryTime !== ele.exitTime) {
            if (ele.profit) {
              totalPLCombined = totalPLCombined + ele.profit;
              cumulativeCount.find((el) => el?.algo === ele?.algo).count = count + ele.profit;
            }
          }
        });


        cumulativeCount.totalPLCombined = totalPLCombined
        setTotalPL(cumulativeCount);
      })
      .catch((e) => {
        console.log("e==>", e);
      });

    getUserTransactionGraph({user: invitedUser?._id})
      .then((res) => {
        if (res?.status) {
          let tempArrayCumulative = []
          let tempArrayDaily = []
          let cumulativePrice = 0
          res?.data?.sort(function (a, b) { return new Date(a?._id) - new Date(b?._id) })?.forEach((element, index) => {
            tempArrayDaily.push([element?._id, element?.count])
            // tempArrayDaily.push([new Date(element?._id),tempArrayDaily[index - 1][0][1] + element?.count])

            cumulativePrice += element?.count
            tempArrayCumulative.push([element?._id, cumulativePrice])
          });
          setNetProfitLossGraphLabelsData(tempArrayCumulative)
          setNetDailyBarGraphData(tempArrayDaily)


        }
      })
      .catch((e) => {
        console.log("Error: ", e);
      });
  };


  useEffect(() => {
    if (invitedUser) {
      console.log("invitedUser?.strategy", invitedUser?.strategy);
      setItem({
        i1: invitedUser?.strategy?.algo1Contract,
        i2: invitedUser?.strategy?.algo2Contract,
        i3: invitedUser?.strategy?.algo3Contract,
        i4: invitedUser?.strategy?.algo4Contract,
        i5: invitedUser?.strategy?.algo5Contract,
        i6: invitedUser?.strategy?.algo6Contract,
        capitalDeployed: invitedUser?.totalCapital,
      });
    }
  }, [invitedUser]);

  useEffect(()=>{
    fetchTransactionsDataFunction();
  },[])
  return (
    <section className="py-12">
      <div className="container">
        {/* GREETING */}
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-3xl font-nunito-bold text-textBlack tracking-tight">
              Client Performance
            </h1>
            <p className="text-base font-nunito-regular tracking-tight text-greyText">
              Track and manage the portfolio of your client.
            </p>
          </div>
          <div
            className={`
            ${!invitedUser.isDisable ? "bg-green-500" : "bg-red-500"}
              rounded-md text-base font-nunito-bold tracking-tight text-white px-5 py-2`}
          >
            {!invitedUser.isDisable ? "Active" : "Suspend"}
          </div>
        </div>

        {/* CLIENT DETAILS */}
        <Card className="bg-white border border-cardBorder rounded-md mt-10">
          <CardBody className="px-5 py-0">
            <div className="grid grid-cols-5 items-center border-b border-cardBorder py-5">
              <div className="col-span-1">
                <h5 className="text-sm font-nunito-regular text-black tracking-tight">
                  Name
                  <span className="font-nunito-bold block mt-1 text-lg">
                    {invitedUser?.firstName + " " + invitedUser?.lastName}
                  </span>
                </h5>
              </div>
              <div className="col-span-1">
                <h5 className="text-sm font-nunito-regular text-black tracking-tight">
                  Email address
                  <span className="font-nunito-bold block mt-1 text-lg">
                    {invitedUser?.email}
                  </span>
                </h5>
              </div>
              <div className="col-span-1">
                <h5 className="text-sm font-nunito-regular text-black tracking-tight">
                  Phone Number
                  <span className="font-nunito-bold block mt-1 text-lg">
                    {invitedUser?.phone}
                  </span>
                </h5>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="strategyTwoContracts"
                    className="text-sm font-nunito-medium tracking-tight text-black"
                  >
                    Capital Deployed
                  </label>
                  <input
                    type="number"
                    name="capitalDeployed"
                    id="capitalDeployed"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Capital Deployed"
                    value={item?.capitalDeployed}
                    onChange={(e) =>
                      setItem({ ...item, capitalDeployed: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="flex items-center justify-end gap-5">
                  {/*<Select
                    options={statusOptions}
                    className="react-select"
                    classNamePrefix="dropdown"
                    placeholder="Update status"
                    value={item?.status}
                    onChange={(e) => setItem({...item, isDisable: e})}
          />*/}
                  <Button
                    onClick={handleUser}
                    disabled={loading}
                    className="bg-[#086AD8] hover:bg-opacity-80 border border-[#086AD8] flex items-center gap-2 shadow-none hover:shadow-none text-white text-sm font-nunito-medium tracking-tight normal-case px-4 py-2 font-medium transition-all duration-300 ease-in-out rounded"
                  >
                    {loading ? <Spinner /> : "  Update details"}
                  </Button>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3 items-center gap-5 py-5">
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="strategyOneContracts"
                    className="text-sm font-nunito-medium tracking-tight text-black"
                  >
                   PCR Strategy
                  </label>
                  <input
                    type="number"
                    name="strategyOneContracts"
                    id="strategyOneContracts"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="No. of contracts"
                    value={item?.i1}
                    onChange={(e) => setItem({ ...item, i1: e.target.value })}
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="strategyTwoContracts"
                    className="text-sm font-nunito-medium tracking-tight text-black"
                  >
                  Intraday Option Sell
                  </label>
                  <input
                    type="number"
                    name="strategyTwoContracts"
                    id="strategyTwoContracts"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="No. of contracts"
                    value={item?.i2}
                    onChange={(e) => setItem({ ...item, i2: e.target.value })}
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="strategyThreeContracts"
                    className="text-sm font-nunito-medium tracking-tight text-black"
                  >
                 Intraday Options Buy
                  </label>
                  <input
                    type="number"
                    name="strategyThreeContracts"
                    id="strategyThreeContracts"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="No. of contracts"
                    value={item?.i3}
                    onChange={(e) => setItem({ ...item, i3: e.target.value })}
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="strategyFourContracts"
                    className="text-sm font-nunito-medium tracking-tight text-black"
                  >
                   Intraday Stock Buy
                  </label>
                  <input
                    type="number"
                    name="strategyFourContracts"
                    id="strategyThreeContracts"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="No. of contracts"
                    value={item?.i4}
                    onChange={(e) => setItem({ ...item, i4: e.target.value })}
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="strategyFiveContracts"
                    className="text-sm font-nunito-medium tracking-tight text-black"
                  >
                    Positional Optional Buy
                  </label>
                  <input
                    type="number"
                    name="strategyFiveContracts"
                    id="strategyFiveContracts"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="No. of contracts"
                    value={item?.i5}
                    onChange={(e) => setItem({ ...item, i5: e.target.value })}
                  />
                </div>
              </div>
              <div className="col-span-1">
                <div className="form-group">
                  <label
                    htmlFor="strategyFiveContracts"
                    className="text-sm font-nunito-medium tracking-tight text-black"
                  >
                    Stock Buy
                  </label>
                  <input
                    type="number"
                    name="strategySixContracts"
                    id="strategySixContracts"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="No. of contracts"
                    value={item?.i6}
                    onChange={(e) => setItem({ ...item, i6: e.target.value })}
                  />
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
        <div className="grid grid-cols-2 gap-5  pt-5">
          <div className="col-span-1">
            <Card className="bg-white border border-cardBorder shadow-md rounded-lg">
              <CardBody className="p-0">
                <div className="border-b border-cardBorder dark:border-darkBorder p-5">
                  <h2 className="text-base font-nunito-semibold text-black tracking-tight">
                    Net daily P&L
                  </h2>
                  <p className="text-sm font-nunito-regular text-greyText tracking-tight">
                    Total net P&L for each trading day on the day it is realized
                  </p>
                </div>
                <div className="p-0">
                  {netDailyBarGraphData?.length === 0 ? (
                    <p className="text-base py-5 px-5 font-nunito-regular text-greyText tracking-tight">
                      No Data found
                    </p>
                  ) : (
                    <NetDailyBarGraph data={netDailyBarGraphData} />
                  )}
                </div>
              </CardBody>
            </Card>
          </div>
          <div className="col-span-1">
            <Card className="bg-white border border-cardBorder shadow-md rounded-lg">
              <CardBody className="p-0">
                <div className="border-b border-cardBorder dark:border-darkBorder p-5">
                  <h2 className="text-base font-nunito-semibold text-black tracking-tight">
                    Cumulative P&L
                  </h2>
                  <p className="text-sm font-nunito-regular text-greyText tracking-tight">
                    Your total account P&L accumulated over the course of each
                    trading day
                  </p>
                </div>
                <div className="p-0">
                  {netProfitLossGraphLabelsData?.length === 0 ? (
                    <p className="text-base py-5 px-5 font-nunito-regular text-greyText tracking-tight">
                      No Data found
                    </p>
                  ) : (
                    <NetProfitLossGraphLabels
                      data={netProfitLossGraphLabelsData}
                    />
                  )}
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
        {/* PORTFOLIO PERFORMANCE */}
        <Card className="bg-white border border-cardBorder shadow-md rounded-lg mt-5">
          <CardBody className="p-0">
            <div className="p-5 border-b border-cardBorder flex items-center justify-between">
              <h5 className="text-lg font-nunito-bold text-black tracking-tight leading-none">
                Portfolio Performance
              </h5>
            </div>
            <DynamicDataTable
              userDetails={invitedUser}
              user={invitedUser?._id}
              algo={"all"}
              showOption={false}
            />
          </CardBody>
        </Card>

        {/* STRATEGY PERFORMANCE */}
        <Card className="bg-white border border-cardBorder shadow-md rounded-lg mt-5">
          <CardBody className="p-0">
            <div className="p-5 border-b border-cardBorder flex items-center justify-between">
              <h5 className="text-lg font-nunito-bold text-black tracking-tight leading-none">
                Strategies Performance
              </h5>
            </div>
            <div className="p-5">
              <Card className="bg-white border border-cardBorder shadow-none rounded-lg mt-5 first:mt-0">
                <CardBody className="p-0">
                  <DynamicDataTable
                    userDetails={invitedUser}
                    user={invitedUser?._id}
                    algo={1}
                    name={"PCR Strategy"}
                    showOption={true}
                  />
                </CardBody>
              </Card>

              <Card className="bg-white border border-cardBorder shadow-none rounded-lg mt-5 first:mt-0">
                <CardBody className="p-0">
                  <DynamicDataTable
                    userDetails={invitedUser}
                    user={invitedUser?._id}
                    algo={2}
                    name={"Intraday Option Sell"}
                    showOption={true}
                  />
                </CardBody>
              </Card>

              <Card className="bg-white border border-cardBorder shadow-none rounded-lg mt-5 first:mt-0">
                <CardBody className="p-0">
                  <DynamicDataTable
                    userDetails={invitedUser}
                    user={invitedUser?._id}
                    algo={3}
                    name={"Intraday Options Buy"}
                    showOption={true}
                  />
                </CardBody>
              </Card>

              <Card className="bg-white border border-cardBorder shadow-none rounded-lg mt-5 first:mt-0">
                <CardBody className="p-0">
                  <DynamicDataTable
                    userDetails={invitedUser}
                    user={invitedUser?._id}
                    algo={4}
                    name={"Intraday Stock Buy  "}
                    showOption={true}
                  />
                </CardBody>
              </Card>

              <Card className="bg-white border border-cardBorder shadow-none rounded-lg mt-5 first:mt-0">
                <CardBody className="p-0">
                  <DynamicDataTable
                    userDetails={invitedUser}
                    user={invitedUser?._id}
                    algo={5}
                    name={"Positional Option Sell"}
                    showOption={true}
                  />
                </CardBody>
              </Card>

              <Card className="bg-white border border-cardBorder shadow-none rounded-lg mt-5 first:mt-0">
                <CardBody className="p-0">
                  <DynamicDataTable
                    userDetails={invitedUser}
                    user={invitedUser?._id}
                    algo={6}
                    name={" Stock Buy"}
                    showOption={true}
                  />
                </CardBody>
              </Card>
            </div>
          </CardBody>
        </Card>
      </div>
    </section>
  );
};

export default ClientPerformance;
