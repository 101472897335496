/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import { useState, useEffect } from "react";
import Select from "react-select";
import moment from "moment";
import DataTable from "react-data-table-component";

/* MATERIAL TAILWLIND COMPONENTS */
import { Button } from "@material-tailwind/react";
import { useDispatch } from "react-redux";

/* ICONS */
import { BsDownload } from "react-icons/bs";

/* ASSETS */
import Logo from "../assets/images/logo.webp";
import {
  getSquareOffProfileSettingsApi,
  getTransactionsAPI,
  userUpdate,
} from "../service/api";
import { toast } from "react-toastify";
import actions from "../redux/user/actions";

/* STATUS OPTIONS */
const strategyStatusOptions = [
  { value: "ACTIVE", label: "Active" },
  { value: "PAUSE", label: "Paused" },
  { value: "EXIT", label: "Exited" },
];

const DynamicDataTable = (props) => {
  const dispatch = useDispatch();

  /* USER COLUMNS */
  const columns = [
    {
      id: 1,
      width: "10vh",
      name: "Sr. No",
      selector: (row, index) => (
        <span className="text-sm tracking-tight text-textBlack text-opacity-60 font-nunito-medium">
          {index + 1}
        </span>
      ),
    },
    {
      id: 2,
      width: "25vh",
      name: "Ticker",
      selector: (row) => (
        <span className="text-sm tracking-tight text-textBlack font-nunito-bold">
          {row.tickerFullSymbol}
        </span>
      ),
    },
    {
      id: 3,
      name: "Entry",
      width: "20vh",
      selector: (row) => (
        <span className="text-sm tracking-tight text-textBlack text-opacity-60 font-nunito-medium">
          {moment(row?.entryTime).format("DD-MM-YYYY HH:mm:ss A")}
        </span>
      ),
    },
    {
      id: 4,
      name: "Exit",
      width: "20vh",
      selector: (row) => (
        <span className="text-sm tracking-tight text-textBlack text-opacity-60 font-nunito-medium">
          {row?.entryTime === row?.exitTime
            ? "-"
            : row?.exitTime === undefined
              ? "-"
              : moment(row?.exitTime).format("DD-MM-YYYY HH:mm:ss A")}
        </span>
      ),
    },
    {
      id: 5,
      name: "Quantity",
      width: "12vh",
      selector: (row) => (
        <span className={`text-sm tracking-tight  ${row?.side === "BUY" ? "text-green-700":row?.side === "SELL" ? "text-red-700" :"text-textBlack"} text-opacity-60 font-nunito-bold`}>
          {row?.side === "BUY" && "+"+row?.quantity}
          {row?.side === "SELL" && "-"+row?.quantity}
        </span>
      ),
    },
    {
      id: 6,
      name: "Entry Price",
      width: "12vh",
      selector: (row) => (
        <span className="text-sm tracking-tight text-textBlack text-opacity-60 font-nunito-medium">
          ₹{row?.price?.toFixed(2)}
        </span>
      ),
    },
    {
      id: 7,
      name: "Exit Price",
      width: "12vh",
      selector: (row) => (
        <span className="text-sm tracking-tight text-textBlack text-opacity-60 font-nunito-medium">
          {row?.exitPrice === undefined ? "-" : `₹` + row?.exitPrice}
        </span>
      ),
    },
    {
      id: 8,
      name: "Algo",
      selector: (row) => (
        <span className={`text-sm tracking-tight   font-nunito-bold`}>
          Algo {row.algo}
        </span>
      ),
    },
    {
      id: 9,
      name: "PNL",
      selector: (row) => (
        <span
          className={`text-sm tracking-tight ${parseFloat(row.profit) < 0 ? "text-red-500" : "text-green-500"
            }  font-nunito-bold`}
        >
          {row.entryTime === row.exitTime
            ? "-"
            : row?.profit === undefined
              ? "-"
              : (row?.profit < 0 ? "- " : "") +
              `₹` +
              Math.abs(row.profit).toFixed(2)}
        </span>
      ),
    },
  ];

  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [transactionData, setTransactionData] = useState([]);
  const [totalDocs, setTotalDocs] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [getLoading, setGetLoading] = useState(false);

  const [userDetails, setUserDetails] = useState(null);

  const [statusSelected, setStatusSelected] = useState({});

  const [numberOfContract, setNumberOfContract] = useState(null);

  const [totalPL, setTotalPL] = useState(0);

  const handlePerPageChange = async (e) => {
    setSizePerPage(e);
  };
  const handlePageChange = async (e) => {
    setPage(e);
  };

  const updateStatus = () => {
    const obj = {};
    obj["algo" + props?.algo + "status"] = statusSelected?.value;

    if (statusSelected?.value === "EXIT") {
      handleClose(props?.algo, userDetails?._id);
    }
    userUpdate(props?.user, obj)
      .then((res) => {
        console.log(res);
        if (res?.data?.status) {
          setLoading(false);
          toast.success(res?.data?.message);
          setUserDetails(res?.data);
          dispatch(actions.setInvitedUser(res?.data?.data));
        } else {
          setLoading(false);
          toast.success(res?.data?.message);
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };
  /* GET TRANSACTIONS FUNCTION */
  const fetchTransactionsDataFunction = () => {
    const obj = {
      page: page,
      sizePerPage: sizePerPage,
      pagination: true,
      user: props?.user,
    };
    if (props?.algo !== "all") {
      obj.algo = props?.algo;
    }

    getTransactionsAPI(obj)
      .then((res) => {
        setTransactionData(res?.data?.data?.docs);
        setTotalDocs(res?.data?.data?.totalDocs);
        setTotalPages(res?.data?.data?.totalPages);
      })
      .catch((e) => {
        console.log("e==>", e);
      });

    let newobj = { pagination: false, isOpen: false, user: props?.user };
    if (props?.algo !== "all") {
      newobj.algo = props?.algo;
    }
    getTransactionsAPI(newobj)
      .then((res) => {
        // setTransactionData(res?.data?.data?.docs)
        // setTotalDocs(res?.data?.data?.totalDocs)
        // setTotalPages(res?.data?.data?.totalPages)

        let count = 0;
        res?.data?.data?.forEach((ele) => {
          if (ele.entryTime !== ele.exitTime) {
            if (ele.profit) {
              count = count + ele.profit;
            }
          }
        });
        setTotalPL(count);
      })
      .catch((e) => {
        console.log("e==>", e);
      });
  };

  useEffect(() => {
    fetchTransactionsDataFunction();
  }, [page, sizePerPage]);

  const handleClose = (id, user) => {
    getSquareOffProfileSettingsApi(id, user)
      .then((res) => {
        if (res?.data?.status) {
          console.log(res);
          toast.success(res?.data?.message);
        } else {
          console.log(res?.message);
          toast.error(res?.message);
        }
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      });
  };

  useEffect(() => {
    setUserDetails(props?.userDetails);
  }, [props]);

  useEffect(() => {
    if (userDetails?.strategy) {
      const dataSplit = [];

      // Temporary storage for building the objects
      const tempStorage = {};

      // Process the data
      for (const key in userDetails?.strategy) {
        const match = key.match(/algo(\d+)(status|Contract)/);
        if (match) {
          const algoNumber = parseInt(match[1], 10);
          const type = match[2];

          if (!tempStorage[algoNumber]) {
            tempStorage[algoNumber] = { algo: algoNumber };
          }

          if (type === "status") {
            tempStorage[algoNumber].status = userDetails?.strategy[key];
          } else if (type === "Contract") {
            tempStorage[algoNumber].contract = userDetails?.strategy[key];
          }
        }
      }

      // Convert the temporary storage to the final array format
      for (const algoNumber in tempStorage) {
        dataSplit.push(tempStorage[algoNumber]);
      }

      setStatusSelected(
        strategyStatusOptions?.find(
          (el) =>
            el?.value ===
            dataSplit?.find((el) => el?.algo === props?.algo)?.status
        )
      );
      setNumberOfContract(dataSplit?.find((el) => el?.algo === props?.algo)?.contract)
    }
  }, [userDetails]);

  return (
    <>
      <div className="p-5 border-b border-cardBorder">
        {/* DETAILS */}
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-5">
            <div className="w-12 h-12 rounded-full bg-header bg-opacity-5 flex items-center justify-center">
              <img
                src={Logo}
                className="w-6 h-6 object-cover"
                alt="Algosquant"
              />
            </div>
            <h5 className="text-xl font-nunito-bold text-textBlack tracking-tight">
              {props?.name ? props?.name : "AlgosQuant"}
            </h5>
          </div>
          <div className="flex items-center gap-3">
            <div
              className={
                props?.showOption ? "flex items-center gap-5" : "hidden"
              }
            >
              {/* <div className="h-full py-2 px-5 bg-green-500 bg-opacity-20 rounded-md">
              <p className="text-sm font-nunito-semibold tracking-tight text-green-500">
                Active
              </p>
            </div> */}
              <div className="flex items-center justify-end gap-5">
                <Select
                  options={strategyStatusOptions}
                  className={`react-select ${statusSelected?.label === "Active"
                    ? "active"
                    : statusSelected?.label === "Paused"
                      ? "paused"
                      : "exited"
                    }`}
                  classNamePrefix="dropdown"
                  placeholder="Update status"
                  onChange={(e) => {
                    setStatusSelected(e);
                  }}
                  value={statusSelected}
                />
                <Button
                  onClick={() => {
                    updateStatus();
                  }}
                  className="bg-[#086AD8] hover:bg-opacity-80 border border-[#086AD8] flex items-center gap-2 shadow-none hover:shadow-none text-white text-sm font-nunito-medium tracking-tight normal-case px-4 py-2 font-medium transition-all duration-300 ease-in-out rounded"
                >
                  Update details
                </Button>
              </div>
            </div>
            <Button className="bg-white hover:bg-black hover:bg-opacity-5 border border-cardBorder flex items-center gap-2 shadow-none hover:shadow-none text-textBlack text-sm font-nunito-medium tracking-tight normal-case px-4 py-2 font-medium transition-all duration-300 ease-in-out rounded">
              <BsDownload className="w-4 h-4 text-black" />
              Report
            </Button>
          </div>
        </div>

        {/* DEPLOYMENT DETAILS */}
        <div className="bg-[#F5F5F5] border border-[#E5E5E5] rounded-lg px-5 py-3 mt-5">
          <div className="grid grid-cols-4 gap-5 items-center">
            <div className="col-span-3">
              <div className="grid grid-cols-4 gap-5">
                <div className="col-span-1">
                  <h5 className="text-sm font-nunito-regular text-black tracking-tight">
                    Deployed on
                    <span className="font-nunito-bold block mt-2 text-lg">
                      {/* 26 Dec, 2023 */}-
                    </span>
                  </h5>
                </div>
                <div className={!props?.showOption ? "hidden " : "col-span-1"}>
                  <h5 className="text-sm font-nunito-regular text-black tracking-tight">
                  Multiplier
                    <span className="font-nunito-bold block mt-2 text-lg">
                      {numberOfContract}
                    </span>
                  </h5>
                </div>
                <div className={props?.showOption ? "hidden " : "col-span-1"}>
                  <h5 className="text-sm font-nunito-regular text-black tracking-tight">
                    Capital Deployed
                    <span className="font-nunito-bold block mt-2 text-lg">
                      {userDetails?.totalCapital ? userDetails?.totalCapital : 0}
                    </span>
                  </h5>
                </div>
                {/* <div className="col-span-1">
                        <div className="relative leading-none">
                          <div ref={domNode} className="form-group">
                            <label className="text-sm font-nunito-regular tracking-tight text-black leading-none">
                              Filter by date
                            </label>
                            <input
                              type="text"
                              name="dateRange"
                              value={`${startDate} - ${endDate}`}
                              onClick={() => setDatepicker(!datepicker)}
                              className="mt-2 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                              placeholder="Date range"
                            />
                          </div>
                          {datepicker && (
                            <div
                              ref={domNode}
                              className="absolute top-[8vh] bottom-0 transition-all duration-300 ease-linear z-50"
                            >
                              <DateRangePicker
                                ranges={[selectionRange]}
                                dragSelectionEnabled
                                showPreview
                                onChange={(e) => {
                                  setStartDate(
                                    moment(e.selection.startDate).format(
                                      "DD/MM/YYYY"
                                    )
                                  );
                                  setEndDate(
                                    moment(e.selection.endDate).format(
                                      "DD/MM/YYYY"
                                    )
                                  );
                                }}
                                className="shadow-xl font-nunito-medium rounded-xl border border-cardBorder cursor-pointer"
                              />
                            </div>
                          )}
                        </div>
                      </div> */}
              </div>
            </div>
            <div className="col-span-1">
              <div className="w-full rounded-lg bg-white border border-cardBorder px-5 py-3">
                <h5 className="text-sm font-nunito-medium text-black tracking-tight leading-none">
                  Total PNL:{" "}
                  <span
                    className={
                      totalPL >= 0
                        ? "text-lg font-nunito-bold text-green-500 block mt-2"
                        : "text-lg font-nunito-bold text-red-500 block mt-2"
                    }
                  >
                    ₹{totalPL?.toFixed(2)}
                  </span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-0">
        {/* TRADE LOGS */}
        <DataTable
          columns={columns}
          data={totalDocs > 0 ? transactionData : []}
          className="datatable rounded-md"
          progressPending={getLoading}
          progressComponent={
            <div className="pagination-loading-screen">
              <p>Please wait!</p>
              <p>We are fetching data </p>
            </div>
          }
          pagination
          paginationServer
          paginationTotalRows={totalDocs}
          noDataComponent={
            <div className="rounded-b-md py-10">
              <p className="text-sm font-nunito-medium tracking-tight text-textBlack">
                There are no records to display.
              </p>
            </div>
          }
          onChangeRowsPerPage={handlePerPageChange}
          onChangePage={handlePageChange}
        />
      </div>
    </>
  );
};
export default DynamicDataTable;
