/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Card,
  CardBody,
  Button,
  Dialog,
  DialogBody,
  Tooltip,
  Popover,
  PopoverContent,
  PopoverHandler,
  Accordion,
  AccordionHeader,
  AccordionBody,
  Switch,
  Spinner,
} from "@material-tailwind/react";

/* ICONS */
import { FaUserEdit } from "react-icons/fa";
import { FiExternalLink } from "react-icons/fi";
import { BsDownload } from "react-icons/bs";
import { IoMdAdd, IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { IoSearch, IoFilterSharp, IoBan, IoClose } from "react-icons/io5";
import { getUser, userInvite, userUpdateInvited } from "../../service/api";
import { toast } from "react-toastify";
import actions from "../../redux/user/actions";
import { useDispatch } from "react-redux";

const findUpper = (fullName) => {
  const nameParts = fullName.trim().split(" ");
  const firstInitial = nameParts[0][0]?.toUpperCase();
  const lastInitial =
    nameParts.length > 1
      ? nameParts[nameParts.length - 1][0]?.toUpperCase()
      : "";
  return firstInitial + lastInitial;
};

const Dashboard = () => {
  const [inviteUser, setInviteUser] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [disable, setDisable] = useState(false);
  const [gearDialog, setGearDialog] = useState(false);
  const [usersData, setUsersData] = useState();
  const dispatch = useDispatch();
  /* ACCORDIONS */
  const [strategyOne, setStrategyOne] = useState(false);
  const [strategyTwo, setStrategyTwo] = useState(false);
  const [strategyThree, setStrategyThree] = useState(false);
  const [strategyFour, setStrategyFour] = useState(false);
  const [strategyFive, setStrategyFive] = useState(false);
  const [search, setSearch] = useState();
  const [invite, setInvite] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
  });
  const [statusChangeDetails, setStatusChangeDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState();
  useEffect(() => {
    if (id && usersData && usersData?.length !== 0) {
      let arr = usersData?.filter((obj) => obj?._id === id);
      setInvite({
        ...invite,
        firstName: arr[0]?.firstName,
        email: arr[0]?.email,
        lastName: arr[0]?.lastName,
        phone: arr[0]?.phone,
        isDisable: arr[0]?.isDisable,
      });
      if (disable) {
        setInvite({
          ...invite,
          firstName: arr[0]?.firstName,
          email: arr[0]?.email,
          lastName: arr[0]?.lastName,
          phone: arr[0]?.phone,
          isDisable: arr[0]?.isDisable,
        });
      }
    }
  }, [id]);

  /*INVITE USER  */
  const handleInvite = () => {
    const validateInput = () => {
      for (const key in invite) {
        if (!invite[key]) {
          return `${key} is required`;
        }
      }
      return null;
    };
    const error = validateInput();

    if (error) {
      console.log(`Error: ${error}`);
      return toast.error(error);
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(invite?.email)) {
      return toast.warning("Please enter a valid email");
    }
    let body = {
      email: invite?.email,
      firstName: invite?.firstName,
      lastName: invite?.lastName,
      phone: invite?.phone,
      password: invite?.password,
    };
    setLoading(true);
    userInvite(body)
      .then((res) => {
        console.log(" invite==>", res);
        if (res?.data?.status) {
          setLoading(false);
          toast.success(res?.data?.message);
          setInviteUser(false);
          setInvite({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            password: "",
          });
          userData();
        } else {
          setLoading(false);
          toast.error(res?.data?.message);
        }
      })
      .catch((e) => {
        console.log("e==>", e);
        toast.error(e?.response?.data?.message);
        setLoading(false);
      });
  };

  const handleUpdateUser = () => {
    if (!disable) {
      const validateInput = () => {
        for (const key in invite) {
          if (!invite[key]) {
            if (invite?.password === "") {
              return null;
            } else {
              return `${key} is required`;
            }
          }
        }
        return null;
      };
      const error = validateInput();

      if (error) {
        console.log(`Error: ${error}`);
        return toast.error(error);
      }
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(invite?.email)) {
        return toast.warning("Please enter a valid email");
      }
    }
    let body = {
      email: invite?.email,
      firstName: invite?.firstName,
      lastName: invite?.lastName,
      phone: invite?.phone,
      // password: invite?.password,
      isDisable: invite?.isDisable,
    };
    if (
      invite?.password !== "" &&
      invite?.password !== null &&
      invite?.password !== undefined
    ) {
      body.password = invite?.password;
    }
    setLoading(true);
    userUpdateInvited(id, body)
      .then((res) => {
        console.log(" invite==>", res);
        if (res?.data?.status) {
          setLoading(false);
          toast.success(res?.data?.message);
          setEditUser(false);
          setDisable(false);
          setInvite({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            password: "",
          });
          userData();
        } else {
          setLoading(false);
          toast.error(res?.data?.message);
        }
      })
      .catch((e) => {
        console.log("e==>", e);
        toast.error(e?.response?.data?.message);
        setLoading(false);
      });
  };

  const handleChangeStatus = () => {
    console.log("statusChangeDetails", statusChangeDetails);
    setLoading(true);
    const body = {
      email: statusChangeDetails?.email,
      firstName: statusChangeDetails?.firstName,
      lastName: statusChangeDetails?.lastName,
      phone: statusChangeDetails?.phone,
      isDisable: !statusChangeDetails?.isDisable,
    };
    userUpdateInvited(statusChangeDetails?._id, body)
      .then((res) => {
        console.log(" invite==>", res);
        if (res?.data?.status) {
          setLoading(false);
          toast.success(res?.data?.message);
          setDisable(false);
          setStatusChangeDetails(null);
          userData();
        } else {
          setLoading(false);
          toast.error(res?.data?.message);
        }
      })
      .catch((e) => {
        console.log("e==>", e);
        toast.error(e?.response?.data?.message);
        setLoading(false);
      });
  };

  /*GET USER  */
  const userData = () => {
    let params = { page: 1, sizePerPage: 10000 };
    if (search) {
      params.search = search;
    }
    getUser(params)
      .then((res) => {
        console.log("res==>", res);
        if (res.data.status) {
          setUsersData(res?.data?.data?.docs);
        }
      })
      .catch((e) => {
        console.log("e==>", e);
      });
  };

  useEffect(() => {
    userData();
  }, [search]);

  /* USER COLUMNS */
  const columns = [
    {
      id: 1,
      name: "Name",
      selector: (row) => (
        <div className="flex items-center gap-3">
          <div className="w-8 h-8 bg-header rounded-full flex items-center justify-center">
            <p className="text-[1.4vh] font-nunito-medium text-white tracking-tight">
              {findUpper(row?.firstName + " " + row?.lastName)}
            </p>
          </div>
          <span className="text-sm tracking-tight text-textBlack font-nunito-bold">
            {row.firstName}&nbsp;{row.lastName}
          </span>
        </div>
      ),
    },
    {
      id: 2,
      name: "Email",
      selector: (row) => (
        <span className="text-sm tracking-tight text-textBlack text-opacity-60 font-nunito-medium">
          {row.email}
        </span>
      ),
    },
    {
      id: 3,
      name: "Phone",
      selector: (row) => (
        <span className="text-sm tracking-tight text-textBlack text-opacity-60 font-nunito-medium">
          {row.phone}
        </span>
      ),
    },
    {
      id: 4,
      width: "20vh",
      name: "Status",
      selector: (row) => (
        <span
          className={`text-sm tracking-tight ${
            !row.isDisable ? "text-green-500" : "text-red-500"
          } font-nunito-bold`}
        >
          {!row.isDisable ? "Active" : "Suspend"}
        </span>
      ),
    },
    {
      id: 5,
      name: "Actions",
      selector: (row) => (
        <div className="flex items-center gap-1">
          {/* <Tooltip
            content="Strategy Settings"
            placement="top"
            className="text-sm font-nunito-medium tracking-tight"
          >
            <Button
              className="p-2 shadow-none hover:shadow-none bg-transparent rounded-full hover:bg-black hover:bg-opacity-5"
              onClick={() => setGearDialog(!gearDialog)}
            >
              <FaGear className="w-5 h-5 text-greyText" />
            </Button>
          </Tooltip> */}
          <Tooltip
            content="Edit details"
            placement="top"
            className="text-sm font-nunito-medium tracking-tight"
          >
            <Button
              className="p-2 shadow-none hover:shadow-none bg-transparent rounded-full hover:bg-black hover:bg-opacity-5"
              onClick={() => {
                setEditUser(!editUser);
                setId(row?._id);
              }}
            >
              <FaUserEdit className="w-5 h-5 text-greyText" />
            </Button>
          </Tooltip>
          <Tooltip
            content="Suspend user"
            placement="top"
            className="text-sm font-nunito-medium tracking-tight"
          >
            <Button
              onClick={() => {
                setDisable(!disable);
                console.log("row?.isDisable", row?.isDisable);
                setStatusChangeDetails(row);
              }}
              className="p-2 shadow-none hover:shadow-none bg-transparent rounded-full hover:bg-black hover:bg-opacity-5"
            >
              <IoBan className="w-5 h-5 text-greyText" />
            </Button>
          </Tooltip>
          <Tooltip
            content="View Performance"
            placement="top"
            className="text-sm font-nunito-medium tracking-tight"
          >
            <Link to="/client-performance">
              <Button
                onClick={() => dispatch(actions.setInvitedUser(row))}
                className="p-2 shadow-none hover:shadow-none bg-transparent rounded-full hover:bg-black hover:bg-opacity-5"
              >
                <FiExternalLink className="w-5 h-5 text-greyText" />
              </Button>
            </Link>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <section className="py-6 xl:py-12 lg:py-12">
      <div className="container">
        {/* GREETING */}
        {/* <div className="block xl:flex lg:flex items-center justify-between">
          <div className="flex items-center justify-end gap-3 mt-5 xl:mt-0 lg:mt-0">
            <Button className="w-full xl:w-auto lg:w-auto bg-white hover:bg-black hover:bg-opacity-5 border border-cardBorder flex items-center justify-center gap-2 shadow-none hover:shadow-none text-textBlack text-sm font-nunito-medium tracking-tight normal-case px-4 py-2 font-medium transition-all duration-300 ease-in-out rounded">
              <BsDownload className="w-4 h-4 text-black" />
              Export users CSV
            </Button>
            <Button
              className="w-full xl:w-auto lg:w-auto bg-header hover:bg-opacity-80 border border-header flex items-center justify-center gap-2 shadow-none hover:shadow-none text-white text-sm font-nunito-medium tracking-tight normal-case px-4 py-2 font-medium transition-all duration-300 ease-in-out rounded"
              onClick={() => {
                setInviteUser(!inviteUser);
                setInvite({
                  firstName: "",
                  lastName: "",
                  email: "",
                  phone: "",
                  password: "",
                });
              }}
            >
              <IoMdAdd className="w-4 h-4 text-white" />
              Invite user
            </Button>
          </div>
        </div> */}

        {/* IMPORTANT STATS */}
        {/* <div className="pt-6 xl:pt-12 lg:pt-12">
          <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-5 xl:gap-10 lg:gap-10">
            <div className="col-span-1">
              <StatsCard
                label="Total invested"
                value="₹509,850.90"
                status="normal"
              />
            </div>
            <div className="col-span-1">
              <StatsCard
                label="Total Profit"
                value="₹50,600.48"
                status="profit"
              />
            </div>
            <div className="col-span-1">
              <StatsCard label="Total Loss" value="₹10,600.48" status="loss" />
            </div>
            <div className="col-span-1">
              <StatsCard
                label="Net Profit/Loss (%)"
                value="10.6%"
                status="normal"
              />
            </div>
          </div>
        </div> */}

        {/* USERS TABLE */}
        <div className="">
          <Card className="bg-white border border-cardBorder rounded-md">
            <CardBody className="p-0">
              {/* FILTERS */}
              <div className="flex items-center justify-between border-b border-cardBorder p-5">
                <div className="flex items-center gap-3 border border-cardBorder rounded-md px-3 py-2">
                  <IoSearch className="w-5 h-5 text-greyText" />
                  <input
                    type="text"
                    name="searchUsers"
                    id="searchUsers"
                    className="outline-none focus:outline-none bg-white text-sm tracking-tight font-nunito-regular p-0 leading-none"
                    placeholder="Search users"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <div className="flex items-center justify-end gap-3 mt-5 xl:mt-0 lg:mt-0">
                  <Button
                    className="w-full xl:w-auto lg:w-auto bg-header hover:bg-opacity-80 border border-header flex items-center justify-center gap-2 shadow-none hover:shadow-none text-white text-sm font-nunito-medium tracking-tight normal-case px-4 py-2 font-medium transition-all duration-300 ease-in-out rounded"
                    onClick={() => {
                      setInviteUser(!inviteUser);
                      setInvite({
                        firstName: "",
                        lastName: "",
                        email: "",
                        phone: "",
                        password: "",
                      });
                    }}
                  >
                    <IoMdAdd className="w-4 h-4 text-white" />
                    Invite user
                  </Button>
                  <Button className="w-full xl:w-auto lg:w-auto bg-white hover:bg-black hover:bg-opacity-5 border border-cardBorder flex items-center justify-center gap-2 shadow-none hover:shadow-none text-textBlack text-sm font-nunito-medium tracking-tight normal-case px-4 py-2 font-medium transition-all duration-300 ease-in-out rounded">
                    <BsDownload className="w-4 h-4 text-black" />
                    Export users CSV
                  </Button>
                  <Popover placement="bottom-left">
                    <PopoverHandler>
                      <Button className="bg-white hover:bg-black hover:bg-opacity-5 border border-cardBorder flex items-center gap-2 shadow-none hover:shadow-none text-textBlack text-sm font-nunito-medium tracking-tight normal-case px-4 py-2 font-medium transition-all duration-300 ease-in-out rounded">
                        <IoFilterSharp className="w-4 h-4 text-black" />
                        Filters
                      </Button>
                    </PopoverHandler>
                    <PopoverContent className="w-[20vh] p-2 z-50">
                      <div className="cursor-pointer px-4 py-2 text-sm tracking-tight font-nunito-medium hover:bg-black hover:text-black hover:bg-opacity-5 rounded-md transition-all duration-300 ease-in-out">
                        Sort by A-Z
                      </div>
                      <div className="cursor-pointer px-4 py-2 text-sm tracking-tight font-nunito-medium hover:bg-black hover:text-black hover:bg-opacity-5 rounded-md transition-all duration-300 ease-in-out">
                        Sort by Z-A
                      </div>
                      <div className="cursor-pointer px-4 py-2 text-sm tracking-tight font-nunito-medium hover:bg-black hover:text-black hover:bg-opacity-5 rounded-md transition-all duration-300 ease-in-out">
                        Suspended users
                      </div>
                      <div className="cursor-pointer px-4 py-2 text-sm tracking-tight font-nunito-medium hover:bg-black hover:text-black hover:bg-opacity-5 rounded-md transition-all duration-300 ease-in-out">
                        Active users
                      </div>
                    </PopoverContent>
                  </Popover>
                </div>
              </div>

              {/* DATATABLE */}
              <DataTable
                columns={columns}
                data={usersData}
                selectableRows
                className="datatable rounded-md"
                pagination
                paginationTotalRows={userData?.length}
                noDataComponent={
                  <div className="rounded-b-md py-10">
                    <p className="text-sm font-nunito-medium tracking-tight text-textBlack">
                      There are no records to display.
                    </p>
                  </div>
                }
              />
            </CardBody>
          </Card>
        </div>
      </div>

      {/* INVITE USER */}
      <Dialog
        open={inviteUser}
        handler={() => setInviteUser(!inviteUser)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="xs"
      >
        <DialogBody className="p-0">
          <div className="flex items-center justify-between px-5 py-3 border-b border-cardBorder">
            <h5 className="text-textBlack font-nunito-bold text-lg tracking-tight">
              Invite user
            </h5>
            <Button
              className="p-2 rounded-full shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5 bg-transparent"
              onClick={() => setInviteUser(!inviteUser)}
            >
              <IoClose className="w-5 h-5 text-greyText" />
            </Button>
          </div>
          <div className="p-5">
            <form>
              <div className="flex items-center gap-5">
                <div className="form-group">
                  <label
                    htmlFor="firstName"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    First name <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="First name"
                    value={invite?.firstName}
                    onChange={(e) =>
                      setInvite({ ...invite, firstName: e.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <label
                    htmlFor="lastName"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Last name <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Last name"
                    value={invite?.lastName}
                    onChange={(e) =>
                      setInvite({ ...invite, lastName: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="form-group mt-5">
                <label
                  htmlFor="email"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  Email address <span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="mt-1 w-full block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="Email address"
                  value={invite?.email}
                  onChange={(e) =>
                    setInvite({ ...invite, email: e.target.value })
                  }
                />
              </div>
              <div className="form-group mt-5">
                <label
                  htmlFor="phone"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  Phone <span className="text-red-500">*</span>
                </label>
                <input
                  type="number"
                  name="phone"
                  id="phone"
                  maxLength={10}
                  className="mt-1 w-full block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="Phone number"
                  value={invite?.phone}
                  onChange={(e) =>
                    setInvite({ ...invite, phone: e.target.value })
                  }
                />
              </div>
              <div className="form-group mt-5">
                <label
                  htmlFor="phone"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  Password <span className="text-red-500">*</span>
                </label>
                <input
                  type="password"
                  name="password"
                  id="phone"
                  // maxLength={10}
                  className="mt-1 w-full block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="********"
                  value={invite?.password}
                  onChange={(e) =>
                    setInvite({ ...invite, password: e.target.value })
                  }
                />
              </div>
            </form>
          </div>
          <div className="border-t border-cardBorder px-5 py-3 flex items-center justify-end">
            <Button
              disabled={loading}
              onClick={handleInvite}
              className="bg-header hover:bg-opacity-80 border border-header flex items-center gap-2 shadow-none hover:shadow-none text-white text-sm font-nunito-medium tracking-tight normal-case px-4 py-2 font-medium transition-all duration-300 ease-in-out rounded"
            >
              {loading ? <Spinner /> : "Submit and invite"}
            </Button>
          </div>
        </DialogBody>
      </Dialog>

      {/* EDIT USER */}
      <Dialog
        open={editUser}
        handler={() => setEditUser(!editUser)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="xs"
      >
        <DialogBody className="p-0">
          <div className="flex items-center justify-between px-5 py-3 border-b border-cardBorder">
            <h5 className="text-textBlack font-nunito-bold text-lg tracking-tight">
              Edit user
            </h5>
            <Button
              className="p-2 rounded-full shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5 bg-transparent"
              onClick={() => setEditUser(!editUser)}
            >
              <IoClose className="w-5 h-5 text-greyText" />
            </Button>
          </div>
          <div className="p-5">
            <form>
              <div className="flex items-center gap-5">
                <div className="form-group">
                  <label
                    htmlFor="firstName"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    First name <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="First name"
                    value={invite?.firstName}
                    onChange={(e) =>
                      setInvite({ ...invite, firstName: e.target.value })
                    }
                  />
                </div>
                <div className="form-group">
                  <label
                    htmlFor="lastName"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Last name <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="Last name"
                    value={invite?.lastName}
                    onChange={(e) =>
                      setInvite({ ...invite, lastName: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="form-group mt-5">
                <label
                  htmlFor="email"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  Email address <span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="mt-1 w-full block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="Email address"
                  value={invite?.email}
                  readOnly
                  // onChange={(e) =>
                  //   setInvite({...invite, email: e.target.value})
                  // }
                />
              </div>
              <div className="form-group mt-5">
                <label
                  htmlFor="phone"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  Phone <span className="text-red-500">*</span>
                </label>
                <input
                  type="number"
                  name="phone"
                  id="phone"
                  maxLength={10}
                  className="mt-1 w-full block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="Phone number"
                  value={invite?.phone}
                  onChange={(e) =>
                    setInvite({ ...invite, phone: e.target.value })
                  }
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor="firstName"
                  className="text-sm font-nunito-medium tracking-tight"
                >
                  Password
                </label>
                <input
                  type="text"
                  name="password"
                  id="password"
                  className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                  placeholder="Update Password"
                  value={invite?.password}
                  onChange={(e) =>
                    setInvite({ ...invite, password: e.target.value })
                  }
                />
              </div>
            </form>
          </div>
          <div className="border-t border-cardBorder px-5 py-3 flex items-center justify-end">
            <Button
              onClick={handleUpdateUser}
              disabled={loading}
              className="bg-header hover:bg-opacity-80 border border-header flex items-center gap-2 shadow-none hover:shadow-none text-white text-sm font-nunito-medium tracking-tight normal-case px-4 py-2 font-medium transition-all duration-300 ease-in-out rounded"
            >
              {loading ? <Spinner /> : "Save details"}
            </Button>
          </div>
        </DialogBody>
      </Dialog>

      {/* STRATEGY SETTINGS */}
      <Dialog
        open={gearDialog}
        handler={() => setGearDialog(!gearDialog)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="xs"
      >
        <DialogBody className="p-0">
          <div className="flex items-center justify-between px-5 py-3 border-b border-cardBorder">
            <h5 className="text-textBlack font-nunito-bold text-lg tracking-tight">
              User strategy settings
            </h5>
            <Button
              className="p-2 rounded-full shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5 bg-transparent"
              onClick={() => setGearDialog(!gearDialog)}
            >
              <IoClose className="w-5 h-5 text-greyText" />
            </Button>
          </div>
          <div className="p-5">
            <Accordion
              open={strategyOne}
              icon={
                strategyOne ? (
                  <IoIosArrowUp className="w-4 h-4 text-black" />
                ) : (
                  <IoIosArrowDown className="w-4 h-4 text-black" />
                )
              }
            >
              <AccordionHeader
                className={`text-sm font-nunito-medium text-black bg-[#F5F5F5] border border-[#E5E5E5] py-3 px-5 ${
                  strategyOne ? "rounded-t-md" : "rounded-md"
                }`}
                onClick={() => setStrategyOne(!strategyOne)}
              >
                Intraday Stock Buy
              </AccordionHeader>
              <AccordionBody className="p-5 border border-[#E5E5E5] rounded-b-md border-t-0 !overflow-visible">
                <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
                  <div className="col-span-1">
                    <div className="form-group">
                      <label
                        htmlFor="status"
                        className="text-sm font-nunito-medium tracking-tight"
                      >
                        Algorithm status <span className="text-red-500">*</span>
                      </label>
                      <div className="flex items-center gap-2 mt-2">
                        <Switch
                          id="status"
                          ripple={false}
                          className="h-full w-full checked:bg-[#2ec946]"
                          containerProps={{
                            className: "w-11 h-6",
                          }}
                          circleProps={{
                            className: "before:hidden left-0.5 border-none",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="form-group">
                      <label
                        htmlFor="contracts"
                        className="text-sm font-nunito-medium tracking-tight"
                      >
                        Contracts <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="contracts"
                        id="contracts"
                        className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                        placeholder="No. of contracts"
                      />
                    </div>
                  </div>
                </div>
              </AccordionBody>
            </Accordion>
            <Accordion
              open={strategyTwo}
              className="mt-5"
              icon={
                strategyTwo ? (
                  <IoIosArrowUp className="w-4 h-4 text-black" />
                ) : (
                  <IoIosArrowDown className="w-4 h-4 text-black" />
                )
              }
            >
              <AccordionHeader
                className={`text-sm font-nunito-medium text-black bg-[#F5F5F5] border border-[#E5E5E5] py-3 px-5 ${
                  strategyTwo ? "rounded-t-md" : "rounded-md"
                }`}
                onClick={() => setStrategyTwo(!strategyTwo)}
              >
                Intraday Option Buy
              </AccordionHeader>
              <AccordionBody className="p-5 border border-[#E5E5E5] rounded-b-md border-t-0">
                <div className="grid grid-cols-2 gap-5">
                  <div className="col-span-1">
                    <div className="form-group">
                      <label
                        htmlFor="status"
                        className="text-sm font-nunito-medium tracking-tight"
                      >
                        Algorithm status <span className="text-red-500">*</span>
                      </label>
                      <div className="flex items-center gap-2 mt-2">
                        <Switch
                          id="status"
                          ripple={false}
                          className="h-full w-full checked:bg-[#2ec946]"
                          containerProps={{
                            className: "w-11 h-6",
                          }}
                          circleProps={{
                            className: "before:hidden left-0.5 border-none",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="form-group">
                      <label
                        htmlFor="contracts"
                        className="text-sm font-nunito-medium tracking-tight"
                      >
                        Contracts <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="contracts"
                        id="contracts"
                        className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                        placeholder="No. of contracts"
                      />
                    </div>
                  </div>
                </div>
              </AccordionBody>
            </Accordion>
            <Accordion
              open={strategyThree}
              className="mt-5"
              icon={
                strategyThree ? (
                  <IoIosArrowUp className="w-4 h-4 text-black" />
                ) : (
                  <IoIosArrowDown className="w-4 h-4 text-black" />
                )
              }
            >
              <AccordionHeader
                className={`text-sm font-nunito-medium text-black bg-[#F5F5F5] border border-[#E5E5E5] py-3 px-5 ${
                  strategyThree ? "rounded-t-md" : "rounded-md"
                }`}
                onClick={() => setStrategyThree(!strategyThree)}
              >
                Positional Option Sell
              </AccordionHeader>
              <AccordionBody className="p-5 border border-[#E5E5E5] rounded-b-md border-t-0">
                <div className="grid grid-cols-2 gap-5">
                  <div className="col-span-1">
                    <div className="form-group">
                      <label
                        htmlFor="status"
                        className="text-sm font-nunito-medium tracking-tight"
                      >
                        Algorithm status <span className="text-red-500">*</span>
                      </label>
                      <div className="flex items-center gap-2 mt-2">
                        <Switch
                          id="status"
                          ripple={false}
                          className="h-full w-full checked:bg-[#2ec946]"
                          containerProps={{
                            className: "w-11 h-6",
                          }}
                          circleProps={{
                            className: "before:hidden left-0.5 border-none",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="form-group">
                      <label
                        htmlFor="contracts"
                        className="text-sm font-nunito-medium tracking-tight"
                      >
                        Contracts <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="contracts"
                        id="contracts"
                        className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                        placeholder="No. of contracts"
                      />
                    </div>
                  </div>
                </div>
              </AccordionBody>
            </Accordion>
            <Accordion
              open={strategyFour}
              className="mt-5"
              icon={
                strategyFour ? (
                  <IoIosArrowUp className="w-4 h-4 text-black" />
                ) : (
                  <IoIosArrowDown className="w-4 h-4 text-black" />
                )
              }
            >
              <AccordionHeader
                className={`text-sm font-nunito-medium text-black bg-[#F5F5F5] border border-[#E5E5E5] py-3 px-5 ${
                  strategyFour ? "rounded-t-md" : "rounded-md"
                }`}
                onClick={() => setStrategyFour(!strategyFour)}
              >
                Intraday Option Sell
              </AccordionHeader>
              <AccordionBody className="p-5 border border-[#E5E5E5] rounded-b-md border-t-0">
                <div className="grid grid-cols-2 gap-5">
                  <div className="col-span-1">
                    <div className="form-group">
                      <label
                        htmlFor="status"
                        className="text-sm font-nunito-medium tracking-tight"
                      >
                        Algorithm status <span className="text-red-500">*</span>
                      </label>
                      <div className="flex items-center gap-2 mt-2">
                        <Switch
                          id="status"
                          ripple={false}
                          className="h-full w-full checked:bg-[#2ec946]"
                          containerProps={{
                            className: "w-11 h-6",
                          }}
                          circleProps={{
                            className: "before:hidden left-0.5 border-none",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="form-group">
                      <label
                        htmlFor="contracts"
                        className="text-sm font-nunito-medium tracking-tight"
                      >
                        Contracts <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="contracts"
                        id="contracts"
                        className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                        placeholder="No. of contracts"
                      />
                    </div>
                  </div>
                </div>
              </AccordionBody>
            </Accordion>
            <Accordion
              open={strategyFive}
              className="mt-5"
              icon={
                strategyFive ? (
                  <IoIosArrowUp className="w-4 h-4 text-black" />
                ) : (
                  <IoIosArrowDown className="w-4 h-4 text-black" />
                )
              }
            >
              <AccordionHeader
                className={`text-sm font-nunito-medium text-black bg-[#F5F5F5] border border-[#E5E5E5] py-3 px-5 ${
                  strategyFive ? "rounded-t-md" : "rounded-md"
                }`}
                onClick={() => setStrategyFive(!strategyFive)}
              >
                Intraday Index Option Buy
              </AccordionHeader>
              <AccordionBody className="p-5 border border-[#E5E5E5] rounded-b-md border-t-0">
                <div className="grid grid-cols-2 gap-5">
                  <div className="col-span-1">
                    <div className="form-group">
                      <label
                        htmlFor="status"
                        className="text-sm font-nunito-medium tracking-tight"
                      >
                        Algorithm status <span className="text-red-500">*</span>
                      </label>
                      <div className="flex items-center gap-2 mt-2">
                        <Switch
                          id="status"
                          ripple={false}
                          className="h-full w-full checked:bg-[#2ec946]"
                          containerProps={{
                            className: "w-11 h-6",
                          }}
                          circleProps={{
                            className: "before:hidden left-0.5 border-none",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="form-group">
                      <label
                        htmlFor="contracts"
                        className="text-sm font-nunito-medium tracking-tight"
                      >
                        Contracts <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="contracts"
                        id="contracts"
                        className="mt-1 w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                        placeholder="No. of contracts"
                      />
                    </div>
                  </div>
                </div>
              </AccordionBody>
            </Accordion>
          </div>
          <div className="border-t border-cardBorder px-5 py-3 flex items-center justify-end">
            <Button className="bg-header hover:bg-opacity-80 border border-header flex items-center gap-2 shadow-none hover:shadow-none text-white text-sm font-nunito-medium tracking-tight normal-case px-4 py-2 font-medium transition-all duration-300 ease-in-out rounded">
              Save details
            </Button>
          </div>
        </DialogBody>
      </Dialog>
      <Dialog
        open={disable}
        handler={() => setDisable(!disable)}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        size="xs"
      >
        <DialogBody className="p-0">
          <div className="flex items-center justify-between px-5 py-3 border-b border-cardBorder">
            <h5 className="text-textBlack font-nunito-bold text-lg tracking-tight">
              Confirmation
            </h5>
            <Button
              className="p-2 rounded-full shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5 bg-transparent"
              onClick={() => {
                setDisable(!disable);
              }}
            >
              <IoClose className="w-5 h-5 text-greyText" />
            </Button>
          </div>
          <div className="p-5">
            <form>
              {/* INTRADAY OPTION BUY */}
              <div>
                <p className="text-sm font-nunito-medium tracking-tight">
                  Are you sure you want to suspend this user?
                </p>
              </div>
            </form>
          </div>
          <div className="border-t border-cardBorder px-5 py-3 flex items-center justify-end">
            <Button
              onClick={() => {
                handleChangeStatus();
              }}
              disabled={loading}
              className="bg-header hover:bg-opacity-80 border border-header flex items-center gap-2 shadow-none hover:shadow-none text-white text-sm font-nunito-medium tracking-tight normal-case px-4 py-2 font-medium transition-all duration-300 ease-in-out rounded"
            >
              {loading ? (
                <div className="flex center justify-center">
                  <Spinner className="h-full " />
                </div>
              ) : (
                "Suspend user"
              )}
            </Button>
          </div>
        </DialogBody>
      </Dialog>
    </section>
  );
};

export default Dashboard;
