import { useState } from "react";
import { useNavigate } from "react-router-dom";

/* MATERIAL TALWLIND COMPONENTS */
import { Button, Card, CardBody, Spinner } from "@material-tailwind/react";

/* ICONS */
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { login } from "../../service/api";
import actions from "../../redux/user/actions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  /* LOGIN API */
  const handleLogin = () => {
    if (email === "") {
      return toast.warning("Please enter email");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      return toast.warning("Please enter a valid email");
    }
    if (password === "") {
      return toast.warning("Please enter password");
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);

    login(formData)
      .then((res) => {
        console.log("res-->", res);
        if (res?.status) {
          toast.success(res?.message);
          dispatch(actions.setUser(res?.data?.userData));
          dispatch(actions.setToken(res?.data?.token));
          dispatch(actions.setLoggedIn(true));
          setEmail("");
          setPassword("");
          setLoading(false);
          navigate("/dashboard");
        } else {
          toast.error(res?.message);
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log("e==>", e);
        toast.error(e?.response?.data?.message);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <section className="bg-body h-[92vh] xl:h-[95vh] lg:h-[95vh]">
        <div className="container flex items-center justify-center h-full">
          <Card className="bg-white border border-cardBorder rounded-lg shadow-md w-full xl:w-2/5 lg:w-2/5">
            <CardBody className="p-10">
              <h1 className="text-2xl font-nunito-bold text-black tracking-tight">
                Sign in
              </h1>
              <p className="text-base font-nunito-regular text-greyText tracking-tight">
                Manage your algorithms and users via this dashboard.
              </p>
              <form className="mt-5">
                <div className="form-group">
                  <label
                    htmlFor="email"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Email address <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="mt-1 w-full block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                    placeholder="placeholder@algoquant.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="form-group mt-5">
                  <label
                    htmlFor="password"
                    className="text-sm font-nunito-medium tracking-tight"
                  >
                    Password <span className="text-red-500">*</span>
                  </label>
                  <div className="relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      id="password"
                      className="mt-1 w-full block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                      placeholder="Algoquant#2023"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                      className="p-2 rounded-full shadow-none hover:shadow-none hover:bg-black hover:bg-opacity-5 bg-transparent !absolute top-[0.1vh] right-1"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <IoMdEyeOff className="w-5 h-5 text-greyText" />
                      ) : (
                        <IoMdEye className="w-5 h-5 text-greyText" />
                      )}
                    </Button>
                  </div>
                </div>
                {/* <Link to="/dashboard"> */}
                <Button
                  onClick={handleLogin}
                  disabled={loading}
                  className="bg-header shadow-none hover:shadow-none text-sm font-nunito-semibold text-white rounded-md hover:bg-opacity-80 mt-5 py-3 normal-case w-full"
                >
                  {loading ? (
                    <div className="flex center justify-center">
                      <Spinner className="h-full" />
                    </div>
                  ) : (
                    " Login"
                  )}
                </Button>
                {/* </Link> */}
              </form>
            </CardBody>
          </Card>
        </div>
      </section>

      {/* FOOTER */}
      <footer className="bg-white border-t border-greyBorder py-3">
        <div className="container">
          <div className="block xl:flex lg:flex items-center justify-between">
            <h5 className="text-sm font-nunito-medium text-black tracking-tight text-center">
              © 2024 Algoquant. All rights reserved.
            </h5>
            <h5 className="text-sm font-nunito-medium text-black tracking-tight text-center">
              Designed by{" "}
              <a
                href="https://www.roundtechsquare.com"
                target="_blank"
                className="text-[#086AD8]"
                rel="noreferrer"
              >
                RoundTechSquare
              </a>
            </h5>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Login;
