const tradeLogs = [
  {
    srNo: 1,
    ticker: "Maruti 11200 CE 25 JAN",
    entry: "3 Jan 11:30:25 AM",
    exit: "-",
    quantity: "50",
    entryPrice: "35",
    exitPrice: "60",
    currentPrice: "25",
    profitLoss: "-500",
  },
  {
    srNo: 2,
    ticker: "Maruti 10500 CE 25 JAN",
    entry: "3 Jan 11:30:26 AM",
    exit: "-",
    quantity: "50",
    entryPrice: "210",
    exitPrice: "186",
    currentPrice: "185",
    profitLoss: "1250",
  },
  {
    srNo: 3,
    ticker: "Maruti 10200 PR 25 JAN",
    entry: "2 Jan 13:26:47 PM",
    exit: "2 Jan 11:30:24 AM",
    quantity: "50",
    entryPrice: "33",
    exitPrice: "25",
    currentPrice: "27",
    profitLoss: "-400",
  },
  {
    srNo: 4,
    ticker: "Maruti 9800 PR 25 JAN",
    entry: "2 Jan 13:26:48 PM",
    exit: "2 Jan 11:30:23 AM",
    quantity: "-50",
    entryPrice: "203",
    exitPrice: "193",
    currentPrice: "190",
    profitLoss: "500",
  },
];

export default tradeLogs;
