import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

/* PAGES */
import Login from "../pages/login";
import Dashboard from "../pages/dashboard";
import AlgoSettings from "../pages/algo-settings";
import NewAlgoSettings from "../pages/algo-settings/new-index";
import ClientPerformance from "../pages/client-performance";
import AlgoPerformance from "../pages/algo-performance";
import StrategyPerformance from "../pages/strategy-details";

/* LAYOUT */
import Layout from "../layout/index";

/* STYLES */
import "../styles/global.css";

/* TOAST IMPORT */
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/* SCROLL TO TOP */
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  const { loggedIn } = useSelector((state) => state.user);
  return (
    <div className='app'>
      <Router>
        <ScrollToTop />
        <ToastContainer />
        <Routes>
          <Route path='/' element={<Login />} />
          {loggedIn ? (
            <>
              <Route
                path='/dashboard'
                element={
                  <Layout>
                    <Dashboard />
                  </Layout>
                }
              />
              <Route
                path='/algo-settings'
                element={
                  <Layout>
                <NewAlgoSettings />
                  </Layout>
                }
              />
              <Route
                path='/algo-settings-new'
                element={
                  <Layout>
                   
                    <AlgoSettings />
                  </Layout>
                }
              />

              <Route
                path='/client-performance'
                element={
                  <Layout>
                    <ClientPerformance />
                  </Layout>
                }
              />
              <Route
                path='/algo-performance'
                element={
                  <Layout>
                    <AlgoPerformance />
                  </Layout>
                }
              />
              <Route
                path='/strategy-details'
                element={
                  <Layout>
                    <StrategyPerformance />
                  </Layout>
                }
              />
            </>
          ) : (
            ""
          )}
        </Routes>
      </Router>
    </div>
  );
};

export default App;
