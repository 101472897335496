import { useRef, useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import DataTable from "react-data-table-component";
import moment from "moment";

/* MATERIAL TAILWIND COMPONENTS */
import { Button, Card, CardBody } from "@material-tailwind/react";

/* CHARTS */
import {
  NetProfitLossGraphLabels,
  NetDailyBarGraph,
} from "../../components/chart";

/* ICONS */
import { BsDownload } from "react-icons/bs";

/* DATA */
import tradeLogs from "../../data/trade-logs";

/* IMAGES */
import Logo from "../../assets/images/logo.webp";

/* CLICK OUTSIDE HANDLER */
const useClickOutside = (handler) => {
  let domNode = useRef();

  useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode.current?.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);
    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};

const StrategyPerformance = () => {
  const [datepicker, setDatepicker] = useState(false);
  const [startDate, setStartDate] = useState(
    moment(new Date().setDate(new Date().getDay() - 1)).format("DD/MM/YYYY")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("DD/MM/YYYY")
  );

  let domNode = useClickOutside(() => {
    setDatepicker(false);
  });

  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };

  /* USER COLUMNS */
  const columns = [
    {
      id: 1,
      width: "10vh",
      name: "Sr. No",
      selector: (row) => (
        <span className="text-sm tracking-tight text-textBlack text-opacity-60 font-nunito-medium">
          {row.srNo}
        </span>
      ),
    },
    {
      id: 2,
      width: "25vh",
      name: "Ticker",
      selector: (row) => (
        <span className="text-sm tracking-tight text-textBlack font-nunito-bold">
          {row.ticker}
        </span>
      ),
    },
    {
      id: 3,
      name: "Entry",
      width: "20vh",
      selector: (row) => (
        <span className="text-sm tracking-tight text-textBlack text-opacity-60 font-nunito-medium">
          {row.entry}
        </span>
      ),
    },
    {
      id: 4,
      name: "Exit",
      width: "20vh",
      selector: (row) => (
        <span className="text-sm tracking-tight text-textBlack text-opacity-60 font-nunito-medium">
          {row.exit}
        </span>
      ),
    },
    {
      id: 5,
      name: "Quantity",
      width: "12vh",
      selector: (row) => (
        <span className="text-sm tracking-tight text-textBlack text-opacity-60 font-nunito-medium">
          {row.quantity}
        </span>
      ),
    },
    {
      id: 6,
      name: "Entry Price",
      width: "12vh",
      selector: (row) => (
        <span className="text-sm tracking-tight text-textBlack text-opacity-60 font-nunito-medium">
          ₹{row.entryPrice}
        </span>
      ),
    },
    {
      id: 7,
      name: "Exit Price",
      width: "12vh",
      selector: (row) => (
        <span className="text-sm tracking-tight text-textBlack text-opacity-60 font-nunito-medium">
          ₹{row.exitPrice}
        </span>
      ),
    },
    {
      id: 8,
      name: "Current Price",
      width: "15vh",
      selector: (row) => (
        <span className="text-sm tracking-tight text-textBlack text-opacity-60 font-nunito-medium">
          ₹{row.currentPrice}
        </span>
      ),
    },
    {
      id: 9,
      name: "PNL",
      selector: (row) => (
        <span
          className={`text-sm tracking-tight ${
            parseFloat(row.profitLoss) < 0 ? "text-red-500" : "text-green-500"
          }  font-nunito-bold`}
        >
          ₹{row.profitLoss}
        </span>
      ),
    },
  ];

  return (
    <section className="py-12">
      <div className="container">
        {/* GREETING */}
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-3xl font-nunito-bold text-textBlack tracking-tight">
              Strategy Details
            </h1>
            <p className="text-base font-nunito-regular tracking-tight text-greyText">
              Track the performance of your strategy
            </p>
          </div>
        </div>

        {/* REPORTS */}
        <div className="pt-10">
          <Card className="bg-white border border-cardBorder shadow-md rounded-lg">
            <CardBody className="p-0">
              <div className="p-5 border-b border-cardBorder">
                {/* DETAILS */}
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-5">
                    <div className="w-12 h-12 rounded-full bg-header bg-opacity-5 flex items-center justify-center">
                      <img
                        src={Logo}
                        className="w-6 h-6 object-cover"
                        alt="Algosquant"
                      />
                    </div>
                    <h5 className="text-xl font-nunito-bold text-textBlack tracking-tight">
                      Intraday Option Buy
                    </h5>
                  </div>
                  <div className="flex items-center gap-3">
                    <div className="relative leading-none">
                      <div ref={domNode} className="form-group">
                        <input
                          type="text"
                          name="dateRange"
                          value={`${startDate} - ${endDate}`}
                          onClick={() => setDatepicker(!datepicker)}
                          className="w-full capitalize block text-sm font-nunito-medium tracking-tight border border-cardBorder rounded-md px-4 py-2 focus:outline-none text-black"
                          placeholder="Date range"
                        />
                      </div>
                      {datepicker && (
                        <div
                          ref={domNode}
                          className="absolute top-[8vh] bottom-0 transition-all duration-300 ease-linear z-50"
                        >
                          <DateRangePicker
                            ranges={[selectionRange]}
                            dragSelectionEnabled
                            showPreview
                            onChange={(e) => {
                              setStartDate(
                                moment(e.selection.startDate).format(
                                  "DD/MM/YYYY"
                                )
                              );
                              setEndDate(
                                moment(e.selection.endDate).format("DD/MM/YYYY")
                              );
                            }}
                            className="shadow-xl font-nunito-medium rounded-xl border border-cardBorder cursor-pointer"
                          />
                        </div>
                      )}
                    </div>
                    <Button className="bg-header hover:bg-opacity-5 flex items-center gap-2 shadow-none hover:shadow-none text-white text-sm font-nunito-medium tracking-tight normal-case px-4 py-2 font-medium transition-all duration-300 ease-in-out rounded">
                      <BsDownload className="w-4 h-4 text-white" />
                      Generate report
                    </Button>
                  </div>
                </div>

                {/* DEPLOYMENT DETAILS */}
                <div className="bg-[#F5F5F5] border border-[#E5E5E5] rounded-lg px-5 py-3 mt-5">
                  <div className="grid grid-cols-4 gap-5 items-center">
                    <div className="col-span-3">
                      <div className="grid grid-cols-3 gap-5">
                        <div className="col-span-1">
                          <h5 className="text-sm font-nunito-regular text-black tracking-tight">
                            Deployed on
                            <span className="font-nunito-bold block mt-2 text-lg">
                              26 Dec, 2023
                            </span>
                          </h5>
                        </div>
                        <div className="col-span-1">
                          <h5 className="text-sm font-nunito-regular text-black tracking-tight">
                            Capital deployed
                            <span className="font-nunito-bold block mt-2 text-lg">
                              ₹509,850.90
                            </span>
                          </h5>
                        </div>
                        <div className="col-span-1">
                          <h5 className="text-sm font-nunito-regular text-black tracking-tight">
                            No. of contracts
                            <span className="font-nunito-bold block mt-2 text-lg">
                              3
                            </span>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-1">
                      <div className="w-full rounded-lg bg-white border border-cardBorder px-5 py-3">
                        <h5 className="text-sm font-nunito-medium text-black tracking-tight leading-none">
                          Total PNL:{" "}
                          <span className="text-lg font-nunito-bold text-green-500 block mt-2">
                            ₹509 (0.65%)
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-0">
                {/* TRADE LOGS */}
                <DataTable
                  columns={columns}
                  data={tradeLogs}
                  className="datatable rounded-md"
                  pagination
                  paginationTotalRows={12}
                  noDataComponent={
                    <div className="rounded-b-md py-10">
                      <p className="text-sm font-nunito-medium tracking-tight text-textBlack">
                        There are no records to display.
                      </p>
                    </div>
                  }
                />
              </div>
            </CardBody>
          </Card>
        </div>

        {/* CHARTS AND STATS */}
        <div className="pt-5">
          <div className="grid grid-cols-2 gap-5">
            <div className="col-span-1">
              <Card className="bg-white border border-cardBorder shadow-md rounded-lg">
                <CardBody className="p-0">
                  <div className="border-b border-cardBorder dark:border-darkBorder p-5">
                    <h2 className="text-base font-nunito-semibold text-black tracking-tight">
                      Net daily P&L
                    </h2>
                    <p className="text-sm font-nunito-regular text-greyText tracking-tight">
                      Total net P&L for each trading day on the day it is
                      realized
                    </p>
                  </div>
                  <div className="p-0">
                    <NetDailyBarGraph />
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-span-1">
              <Card className="bg-white border border-cardBorder shadow-md rounded-lg">
                <CardBody className="p-0">
                  <div className="border-b border-cardBorder dark:border-darkBorder p-5">
                    <h2 className="text-base font-nunito-semibold text-black tracking-tight">
                      Cumulative P&L
                    </h2>
                    <p className="text-sm font-nunito-regular text-greyText tracking-tight">
                      Your total account P&L accumulated over the course of each
                      trading day
                    </p>
                  </div>
                  <div className="p-0">
                    <NetProfitLossGraphLabels />
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StrategyPerformance;
